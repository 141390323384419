import type { FC } from "react";
import React from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../basic/error_boundary";
import { ActivatePasswordContainer } from "./container";

type Props = {
  code: string;
  login: string;
};

export const ActivatePassword: FC<Props> = ({
  code,
  login,
}: React.PropsWithChildren<Props>) => (
  <ErrorBoundary>
    <React.Suspense fallback={<CircularProgress />}>
      <ActivatePasswordContainer code={code} login={login} />
    </React.Suspense>
  </ErrorBoundary>
);
