import { atom } from "recoil";
import { getJwtToken } from "../core/jwt_token";
import type { Course, Form, Payments, UserData } from "../types/main";

export const signUpFormRequest = atom<Form.Request>({
  key: "signUpFormRequest",
  default: {
    status: "DEFAULT",
  },
});

export const logInFormRequest = atom<Form.Request>({
  key: "logInFormRequest",
  default: {
    status: "DEFAULT",
  },
});

export const recoverFormRequest = atom<Form.Request>({
  key: "recoverFormRequest",
  default: {
    status: "DEFAULT",
  },
});

export const authorizationToken = atom<string | null>({
  key: "authorizationToken",
  default: getJwtToken(),
});

export const paymentsData = atom<Payments.Data | null>({
  key: "paymentsData",
  default: null,
});

export const courseData = atom<Course.Data | null>({
  key: "courseData",
  default: null,
});

export const currentUserData = atom<UserData | null>({
  key: "currentUserData",
  default: null,
});

export const passwordFormRequest = atom<Form.Request>({
  key: "passwordFormRequest",
  default: {
    status: "DEFAULT",
  },
});

export const payFormRequest = atom<Form.Request>({
  key: "payFormRequest",
  default: {
    status: "DEFAULT",
  },
});
