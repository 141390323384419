import type { FC } from "react";
import React from "react";
import { Link } from "react-router-dom";

import { UserPanel } from "../../components/data_blocks/user_panel";
import LogoStandart from "../../images/logo_standart";
import LogoSmall from "../../images/logo_small";

import "./styles.scss";

type Props = {
    isAuthorized: boolean;
    mini?: boolean;
};

export const Header: FC<Props> = ({ isAuthorized, mini }: Props) => (
    <div className={mini ? "main-header main-header--mini" : "main-header"}>
        <div className="main-header__container">
            <div className="main-header__logo main-header__logo--standart">
                <Link to="/">
                    <LogoStandart />
                </Link>
            </div>
            <div className="main-header__logo main-header__logo--small">
                <Link to="/">
                    <LogoSmall />
                </Link>
            </div>
            <div className="main-header__phone">+7 (342) 255 44 99</div>
            <div className="main-header__user-panel">
                <UserPanel isAuthorized={isAuthorized} />
            </div>
        </div>
    </div>
);
