import type { FC } from "react";
import React, { memo, useEffect } from "react";
import { useRecoilState } from "recoil";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { classesDataQuery } from "../../state/classes";
import { ClassItemRow } from "../class_item_row/class_item_row";

import "./classes_container.scss";

const ClassesContainerFC: FC = () => {
    const [classesData, setClassesData] = useRecoilState(classesDataQuery);
    useEffect(() => () => setClassesData(null as any), [setClassesData]);

    if (classesData.response.length === 0) {
        return <>Классов пока нет</>;
    }

    return (
        <>
            {classesData.response.length > 0 && (
                <>
                    <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width={24} className="classes-container__icon-column" />
                                <TableCell>Название</TableCell>
                                <TableCell width={70}>Ученики</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {classesData.response?.map((v) => (
                                <ClassItemRow item={v} key={`class_${v.id}`} setClassesData={setClassesData} />
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </>
    );
};

export const ClassesContainer = memo(ClassesContainerFC);
