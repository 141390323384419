import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { ClassDataContainer } from "../class_data_container/class_data_container";

type Props = {
  classId: number;
};

export const ClassDataSuspense: FC<Props> = ({ classId }: Props) => (
  <ErrorBoundary>
    <Suspense fallback={<CircularProgress />}>
      <ClassDataContainer classId={classId} />
    </Suspense>
  </ErrorBoundary>
);
