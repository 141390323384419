import type { FC } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { currentUserDataQuery } from "../../../../../state";
import type { ProfileFormType } from "../../../../../types/main.d";
import { ProfileForm } from "../../../../common/profile_form/profile_form";
import {
  avatarFormRequest,
  useAvatarDeleteFormSubmit,
  useAvatarFormSubmit,
} from "../../state/avatar";
import { profileFormRequest, useProfileFormSubmit } from "../../state/profile";

export const ProfileContainer: FC = () => {
  const [avatarRequest, setAvatarRequest] = useRecoilState(avatarFormRequest);
  const [request, setRequest] = useRecoilState(profileFormRequest);
  const avatarDeleteFormSubmit = useAvatarDeleteFormSubmit();
  const avatarFormSubmit = useAvatarFormSubmit();
  const profileFormSubmit = useProfileFormSubmit();
  const [{ response: userData }, setCurrentUserData] = useRecoilState(
    currentUserDataQuery
  );
  const showClassField = userData?.status === "PUPIL";
  const [profileData, setProfileData] = useState<ProfileFormType.State>({
    lastName: {
      value: userData?.lastName ?? "",
    },
    firstName: {
      value: userData?.firstName ?? "",
    },
    middleName: {
      value: userData?.middleName ?? "",
    },
    class: {
      value: userData?.class ?? 0,
    },
  });
  const handleChangeDataItem = useCallback(
    (fieldName: ProfileFormType.FieldName, value: any) =>
      setProfileData((oldProfileData) => ({
        ...oldProfileData,
        [fieldName]: {
          ...oldProfileData[fieldName],
          error: oldProfileData[fieldName].errorChecker
            ? (oldProfileData[fieldName].errorChecker as any)(value)
            : oldProfileData[fieldName].error,
          value,
        },
      })),
    []
  );
  const handleSubmit = useCallback(() => {
    const newProfileData: any = {};
    let firstErrorField: any;
    (Object.keys(profileData) as ProfileFormType.FieldName[]).forEach((k) => {
      const error = profileData[k].errorChecker
        ? (profileData[k].errorChecker as any)(profileData[k].value)
        : profileData[k].error;
      if (error && !firstErrorField) {
        firstErrorField = profileData[k] as any;
      }
      newProfileData[k] = {
        ...profileData[k],
        ...{
          error,
        },
      } as any;
    });
    setTimeout(() => {
      if (firstErrorField && firstErrorField.ref) {
        firstErrorField.ref.current.focus();
      }
    }, 0);
    setProfileData(newProfileData);
    if (!firstErrorField && request.status !== "REQUEST") {
      profileFormSubmit({
        first_name: newProfileData.firstName.value,
        last_name: newProfileData.lastName.value,
        middle_name: newProfileData.middleName.value,
        ...(showClassField
          ? { class: parseInt(newProfileData.class.value, 10) }
          : {}),
      }).then((res) =>
        setCurrentUserData((curVal) =>
          curVal.response != null
            ? {
                ...curVal,
                ...{
                  response: {
                    ...curVal.response,
                    ...{
                      firstName: res.first_name,
                      lastName: res.last_name,
                      middleName: res.middle_name,
                    },
                    ...(showClassField ? { class: res.class } : {}),
                  },
                },
              }
            : curVal
        )
      );
    }
  }, [
    profileData,
    profileFormSubmit,
    request.status,
    setCurrentUserData,
    showClassField,
  ]);

  const handleAvatarDelete = useCallback(() => {
    if (avatarRequest.status !== "REQUEST") {
      avatarDeleteFormSubmit({}).then((res) =>
        setCurrentUserData((curVal) =>
          curVal.response != null
            ? {
                ...curVal,
                ...{
                  response: {
                    ...curVal.response,
                    ...res,
                  },
                },
              }
            : curVal
        )
      );
    }
  }, [avatarDeleteFormSubmit, avatarRequest.status, setCurrentUserData]);
  const handleAvatarSubmit = useCallback(
    (file: File) => {
      if (avatarRequest.status !== "REQUEST") {
        avatarFormSubmit(file).then((res) =>
          setCurrentUserData((curVal) =>
            curVal.response != null
              ? {
                  ...curVal,
                  ...{
                    response: {
                      ...curVal.response,
                      ...res,
                    },
                  },
                }
              : curVal
          )
        );
      }
    },
    [avatarFormSubmit, avatarRequest.status, setCurrentUserData]
  );

  // Reset status on componentWillUnmount
  useEffect(
    () => () => {
      setAvatarRequest({
        status: "DEFAULT",
      });
      setRequest({
        status: "DEFAULT",
      });
    },
    [setAvatarRequest, setRequest]
  );

  return (
    <ProfileForm
      avatarUrl={userData?.avatar ?? ""}
      data={profileData}
      onAvatarDelete={handleAvatarDelete}
      onAvatarSubmit={handleAvatarSubmit}
      onChangeDataItem={handleChangeDataItem}
      onSubmit={handleSubmit}
      showAvatarLoader={avatarRequest.status === "REQUEST"}
      showClassField={showClassField}
      showDeleteButton={userData?.avatarFileId != null}
      showError={request.status === "ERROR"}
      showLoader={request.status === "REQUEST"}
      showNewSelectedAvatar={avatarRequest.status === "REQUEST"}
      showSuccess={request.status === "SUCCESS"}
    />
  );
};
