import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { PaymentSuccess } from "../../data_blocks/payment_success";

import "./styles.scss";

type Props = RouteComponentProps<{
  InvId?: string;
  OutSum?: string;
  SignatureValue?: string;
}>;

export const PaymentSuccessPage: FC<Props> = (props: Props) => {
  const invId = new URL(window.location.href).searchParams.get("InvId") ?? "";
  const outSum = new URL(window.location.href).searchParams.get("OutSum") ?? "";
  const signatureValue =
    new URL(window.location.href).searchParams.get("SignatureValue") ?? "";
  return (
    <div className="payment-success-page">
      <PaymentSuccess
        invId={invId}
        outSum={outSum}
        signatureValue={signatureValue}
      />
    </div>
  );
};
