import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { ActivateUserSuspense } from "./components/activate_user_suspense/activate_user_suspense";

type Props = RouteComponentProps<{ code: string; login: string }>;

export const ActivateUserPage: FC<Props> = (props: Props) => {
  const code = props.match.params.code;
  const login = props.match.params.login;
  return (
    <div>
      <ActivateUserSuspense code={code} login={login} />
    </div>
  );
};
