import { useEffect } from "react";
import type { SetterOrUpdater } from "recoil";

import type { Form } from "../types/main.d";

// Reset status on componentWillUnmount
export const useResetFormStatus = (
  setFormRequest: SetterOrUpdater<Form.Request>
) =>
  useEffect(
    () => () =>
      setFormRequest({
        status: "DEFAULT",
      }),
    [setFormRequest]
  );
