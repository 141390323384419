import { useLayoutEffect } from "react";

declare let AmCharts: any;

type Props = {
    averageBalloonText?: string;
    countBalloonText: string;
    data: any;
    id: string;
    isMakeChart: boolean;
    maxBalloonText?: string;
    title: string;
};

export const useSerialChart = ({
    averageBalloonText,
    countBalloonText,
    data,
    id,
    isMakeChart,
    maxBalloonText,
    title,
}: Props) => {
    useLayoutEffect(() => {
        if (isMakeChart) {
            AmCharts.makeChart(id, {
                categoryAxis: {
                    gridPosition: "start",
                    axisAlpha: 0,
                    tickLength: 0,
                },
                categoryField: "label",
                chartCursor: {
                    oneBalloonOnly: true,
                    enabled: true,
                    cursorAlpha: 0.1,
                    cursorColor: "#ff8713",
                    fullWidth: true,
                    valueBalloonsEnabled: false,
                    zoomable: false,
                },
                color: "#212731",
                dataProvider: data,
                fontFamily: "Gilroy, -apple-system, sans-serif",
                fontSize: 14,
                graphs: [
                    {
                        alphaField: "alpha",
                        balloonText: `${countBalloonText} [[category]]<br><span style='font-size:20px;'>[[value]]</span>`,
                        dashLengthField: "dashLengthColumn",
                        fillAlphas: 1,
                        lineColor: "#ff8713",
                        switchable: false,
                        title: "Количество решений",
                        type: "column",
                        valueAxis: "axis_of_count",
                        valueField: "count",
                    },
                    ...(averageBalloonText
                        ? [
                              {
                                  valueAxis: "axis_of_percent",
                                  balloonText: `${averageBalloonText}<br>[[category]]<br><span style='font-size:20px;'>[[value]] %</span>`,
                                  bullet: "round",
                                  dashLengthField: "dashLengthLine",
                                  lineThickness: 3,
                                  bulletSize: 7,
                                  bulletBorderAlpha: 1,
                                  bulletColor: "#FFFFFF",
                                  useLineColorForBulletBorder: true,
                                  bulletBorderThickness: 3,
                                  fillAlphas: 0,
                                  lineAlpha: 1,
                                  title: "Средний результат, %",
                                  valueField: "average",
                                  lineColor: "#a2ce1a",
                              },
                          ]
                        : []),
                    ...(maxBalloonText
                        ? [
                              {
                                  valueAxis: "axis_of_percent",
                                  balloonText: `${maxBalloonText}<br>[[category]]<br><span style='font-size:20px;'>[[value]] %</span>`,
                                  bullet: "round",
                                  dashLengthField: "dashLengthLine",
                                  lineThickness: 3,
                                  bulletSize: 7,
                                  bulletBorderAlpha: 1,
                                  bulletColor: "#FFFFFF",
                                  useLineColorForBulletBorder: true,
                                  bulletBorderThickness: 3,
                                  fillAlphas: 0,
                                  lineAlpha: 1,
                                  title: "Лучший результат, %",
                                  valueField: "max",
                                  lineColor: "#ff3236",
                              },
                          ]
                        : []),
                ],
                legend: {
                    useGraphSettings: true,
                },
                startDuration: 0,
                theme: "none",
                titles: [
                    {
                        size: 20,
                        text: title,
                    },
                ],
                type: "serial",
                valueAxes: [
                    {
                        axisAlpha: 1,
                        axisColor: "#ff8713",
                        axisThickness: 2,
                        id: "axis_of_count",
                        minimum: 0,
                        position: "left",
                    },
                    {
                        axisAlpha: 1,
                        axisColor: "#ff3236",
                        axisThickness: 2,
                        gridAlpha: 0,
                        id: "axis_of_percent",
                        maximum: 100,
                        minimum: 0,
                        position: "right",
                    },
                ],
            });
        }
    }, [averageBalloonText, countBalloonText, data, id, isMakeChart, maxBalloonText, title]);
};
