import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";
import { uploadFileRequest } from "../../../../core/api/request";
import { authorizationToken } from "../../../../state";
import type { Form } from "../../../../types/main.d";

export const activateUpdateUserFormRequest = atom<Form.Request>({
  key: "activateUpdateUserFormRequest",
  default: {
    status: "DEFAULT",
  },
});

export const useActivateUpdateUserFormSubmit = () => {
  const setAuthorizationToken = useSetRecoilState(authorizationToken);
  const setFormRequest = useSetRecoilState(activateUpdateUserFormRequest);
  return useCallback(
    (file: File | undefined, data: Record<string, any> = {}) =>
      uploadFileRequest({
        item: {
          data,
          file,
        },
        setAuthorizationToken,
        setFormRequest,
        url: "/auth/activateuser/",
      }),
    [setAuthorizationToken, setFormRequest]
  );
};
