import type { FC } from "react";
import React, { memo, useCallback } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import type { ButtonProps } from "@material-ui/core";
import { Button } from "@material-ui/core";

type Props = {
    children: string | JSX.Element;
    href: string;
    size?: ButtonProps["size"];
} & RouteComponentProps;

const ButtonLinkFC: FC<Props> = (props: Props) => {
    const handleClick = useCallback(() => {
        props.history.push(props.href);
    }, [props.href, props.history]);

    return (
        <Button color="primary" onClick={handleClick} size={props.size} variant="contained">
            {props.children}
        </Button>
    );
};

export const ButtonLink = memo(withRouter(ButtonLinkFC));
