import React, { useCallback, useMemo } from "react";
import type { FC } from "react";
import { Button, CircularProgress, Radio } from "@material-ui/core";

import { errorColors } from "../../../constants/general";
import contractOfOfferDoc from "../../../documents/contract_of_offer.pdf";
import privacyPolicyDoc from "../../../documents/privacy_policy.pdf";
import type { PayForm } from "../../../types/main.d";
import "./styles.scss";

const errorColorStyle = { color: errorColors.main };

type Props = {
  data: PayForm.State;
  errorMessage?: string;
  onChangeDataItem: (fieldName: PayForm.FieldName, value: any) => void;
  onSubmit: () => void;
  showError: boolean;
  showLoader: boolean;
  subscriptions: PayForm.Subscription[];
  paymentTypes: PayForm.PaymentType[];
};

const PayFormView: FC<Props> = ({
  data,
  errorMessage,
  onChangeDataItem,
  onSubmit,
  showError,
  showLoader,
  subscriptions,
  paymentTypes,
}: Props) => {
  const handleSubmitForm = useCallback((event?: any) => onSubmit(), [onSubmit]);
  const subscriptionsRender = useMemo(
    () =>
      subscriptions.map((s) => (
        <div
          className="pay-form__subscription-item"
          key={`subscription_${s.id}`}
        >
          <div className="pay-form__subscription-button">
            <Button
              color={data.subscriptionId.value === s.id ? "primary" : "default"}
              disabled={showLoader}
              fullWidth={true}
              onClick={() => onChangeDataItem("subscriptionId", s.id)}
              size="medium"
              variant="contained"
            >
              {s.title}
            </Button>
          </div>
          <div dangerouslySetInnerHTML={{ __html: s.text }} />
          <div className="pay-form__price">{s.priceRubleCurrent}&nbsp;₽</div>
          {!!s.priceRubleOld && (
            <div className="pay-form__price pay-form__price--through">
              {s.priceRubleOld}&nbsp;₽
            </div>
          )}
        </div>
      )),
    [onChangeDataItem, subscriptions, data.subscriptionId.value, showLoader]
  );
  const paymentTypesRender = useMemo(
    () => (
      <>
        {paymentTypes.map((p) => (
          <div
            className="pay-form__payment-type-item"
            key={`payment-type__${p.type}`}
            onClick={() =>
              !showLoader && onChangeDataItem("paymentType", p.type)
            }
          >
            <span className="pay-form__radio">
              <Radio
                checked={data.paymentType.value === p.type}
                disabled={showLoader}
                value={p.type}
                name={p.code}
              />
            </span>
            <div className={`pay-form__${p.code}`} />
          </div>
        ))}
        <div
          className="pay-form__payment-type-item pay-form__payment-type-item--width--auto"
          key={`payment-type__`}
          onClick={() => onChangeDataItem("paymentType", "")}
        >
          <span className="pay-form__radio">
            <Radio
              checked={data.paymentType.value === ""}
              value=""
              name="others"
            />
          </span>
          другие способы оплаты
        </div>
      </>
    ),
    [onChangeDataItem, paymentTypes, data.paymentType.value, showLoader]
  );

  return (
    <div className="pay-form">
      <h1>Оплата подписки</h1>
      <div className="pay-form__row">
        <p>
          Ознакомительная часть учебного курса открыта для всех
          зарегистрированных пользователей. Доступ ко всем темам учебного курса
          будет открыт после оформления подписки.
        </p>
      </div>
      <div className="pay-form__row">
        <h3 style={data.subscriptionId.error ? errorColorStyle : {}}>
          Выберите вариант подписки:
        </h3>
        <div className="pay-form__subscriptions">{subscriptionsRender}</div>
      </div>
      <div className="pay-form__row">
        <h3 style={data.paymentType.error ? errorColorStyle : {}}>
          Выберите способ оплаты:
        </h3>
        <div className="pay-form__payment-types">{paymentTypesRender}</div>
      </div>
      <div className="pay-form__row">
        <p>
          Нажимая кнопку «Оплатить», я принимаю условия{" "}
          <a
            href={contractOfOfferDoc}
            rel="noreferrer"
            target="_blank"
            download="Договор оферты.pdf"
          >
            соглашения
          </a>{" "}
          и даю своё согласие на&nbsp;
          <a
            href={privacyPolicyDoc}
            rel="noreferrer"
            target="_blank"
            download="Политика конфиденциальности.pdf"
          >
            передачу и&nbsp;обработку персональных данных и&nbsp;cookies
          </a>
          .
        </p>
        <p>
          Оплата происходит с помощью платёжной системы{" "}
          <a href="http://robokassa.ru/" target="_blank" rel="noreferrer">
            <span className="pay-form__robokassa" />
          </a>
          . Для оплаты вы перейдете на сайт платежной системы, после чего
          автоматически вернётесь обратно.
        </p>
      </div>
      <div className="pay-form__row  pay-form__row--flex">
        <div className="pay-form__submit">
          <Button
            color="primary"
            disabled={showLoader}
            onClick={handleSubmitForm}
            size="large"
            variant="contained"
          >
            Оплатить
          </Button>
          {showLoader && (
            <CircularProgress size={24} className="pay-form__submit-progress" />
          )}
        </div>
      </div>
      {showError && (
        <div className="pay-form__row" style={errorColorStyle}>
          {errorMessage || (
            <>
              Ошибка :( Проверьте введённые данные и&nbsp;попробуйте оплатить
              ещё раз.
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PayFormView;
