import type { FC } from "react";
import React from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../basic/error_boundary";
import { UserPanelContainer } from "./container";
import { LogInSignUpView } from "./log_in_sign_up_view";

import "./styles.scss";

type Props = {
    isAuthorized: boolean;
};

export const UserPanel: FC<Props> = ({ isAuthorized }: Props) => (
    <div className="user-panel">
        {isAuthorized ? (
            <ErrorBoundary>
                <React.Suspense fallback={<CircularProgress size={36} />}>
                    <UserPanelContainer />
                </React.Suspense>
            </ErrorBoundary>
        ) : (
            <LogInSignUpView />
        )}
    </div>
);
