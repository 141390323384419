import type { SetterOrUpdater } from "recoil";

import type { Form } from "../../types/main.d";
import { getDfp } from "../dfp_token";
import { setJwtToken } from "../jwt_token";
import { sendBasicRequest } from "./basic_request";
import type { SendRequestType } from "./types";
import { getHeaders } from "./utils/get_headers";
import { getUrl } from "./utils/get_url";

export function sendRequest<T = any>(
  params: SendRequestType
): Promise<{
  cursor: string | null;
  data: T;
}> {
  return getDfp().then(() => sendBasicRequest(params));
}

type UploadFileRequestType = {
  item: {
    data: Record<string, any>;
    file?: File;
  };
  setAuthorizationToken: SetterOrUpdater<string | null>;
  setFormRequest: SetterOrUpdater<Form.Request>;
  url: string;
};

export const uploadFileRequest = ({
  item,
  setAuthorizationToken,
  setFormRequest,
  url,
}: UploadFileRequestType) => {
  setFormRequest({
    status: "REQUEST",
  });
  return getDfp().then(
    () =>
      new Promise<{
        avatar: string;
        avatarFileId: number;
        avatarMini: string;
      }>((resolve, reject) => {
        const req = new XMLHttpRequest();

        req.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            setFormRequest({
              progress: (event.loaded / event.total) * 100,
              status: "REQUEST",
            });
          }
        });

        req.onreadystatechange = () => {
          if (req.readyState === 4) {
            const response: Record<string, any> = req.response
              ? JSON.parse(req.response)
              : {};
            setJwtToken(response.jwt, setAuthorizationToken);
            if (req.status === 200 && response?.success) {
              setFormRequest({
                response: response.data,
                status: "SUCCESS",
              });
              resolve(response.data);
            } else {
              setFormRequest({
                response: response.error,
                status: "ERROR",
              });
            }
          }
        };

        // req.upload.addEventListener("load", () => {
        //   resolve(req.response);
        // });
        // req.upload.addEventListener("abort", () => {
        //   setFormRequest({
        //     response: null,
        //     status: "ERROR",
        //   });
        //   reject(req.response);
        // });
        req.upload.addEventListener("error", () => {
          setFormRequest({
            response: null,
            status: "ERROR",
          });
          reject(req.response);
        });

        const uploadFilesFieldName = "myfile";
        const formData = new FormData();
        if (item.file) {
          formData.append(uploadFilesFieldName, item.file, item.file.name);
        }
        Object.keys(item.data).forEach((key) => {
          formData.append(key, item.data[key]);
        });

        req.open("POST", getUrl(url));
        const headers = getHeaders();
        if (headers.Authorization) {
          req.setRequestHeader("Authorization", headers.Authorization);
        }
        if (headers["x-app-dfp"]) {
          req.setRequestHeader("x-app-dfp", headers["x-app-dfp"]);
        }
        req.send(formData);
      })
  );
};
