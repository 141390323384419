import { sendBasicRequest } from "./api/basic_request";
import { fingerprint } from "./dfp/fingerprint";

enum RequestStatus {
  DEFAULT = "DEFAULT",
  ERROR = "ERROR",
  REQUEST = "REQUEST",
  SUCCESS = "SUCCESS",
}

let dfpRequestStatus = RequestStatus.DEFAULT;

const EVENT = "updateDfpToken";
const DFP_URL = "/security/dfp/1/";

let dfpToken: string | null = null;
let dfpDateExperation = 0;

const dispatchEvent = () => window.dispatchEvent(new Event(EVENT));

export const getDfpToken = () => dfpToken;

const setDfpToken = (token: string, lifeTimeSeconds?: number) => {
  dfpToken = token;
  dfpDateExperation =
    lifeTimeSeconds !== undefined ? Date.now() + lifeTimeSeconds * 1000 : 0;
  dispatchEvent();
};

export const getDfp = () => {
  return new Promise((resolve) => {
    const token = getDfpToken();
    const isExperation =
      dfpDateExperation !== 0 && dfpDateExperation <= Date.now();
    if (token !== null && token !== "" && !isExperation) {
      resolve(token);
      return;
    }

    const eventHandler = () => {
      window.removeEventListener(EVENT, eventHandler);
      resolve(getDfpToken());
    };
    window.addEventListener(EVENT, eventHandler);

    if (dfpRequestStatus === RequestStatus.REQUEST) {
      return;
    }

    const dfp = fingerprint.getD();
    const params = {
      a: dfp.getUserAgent(),
      c: dfp.isCookiesEnabled(),
      cf: dfp.getCanvasFingerprint(),
      f: dfp.getFonts(),
      fe: dfp.isFlashEnabled(),
      j: dfp.isJavaEnabled(),
      o: dfp.getOsFull(),
      p: dfp.getPlugins(),
      s: dfp.getScreenSettings(),
      sc: dfp.getSuperCookie(),
      t: dfp.getTimeZone(),
      w: dfp.getWebGL(),
    };

    dfpRequestStatus = RequestStatus.REQUEST;
    sendBasicRequest({
      bodyData: params,
      method: "POST",
      url: DFP_URL,
    })
      .then(({ data: successData }: any) => {
        dfpRequestStatus = RequestStatus.SUCCESS;
        setDfpToken(successData.jwt, successData.lifeTimeSeconds);
      })
      .catch(() => {
        dfpRequestStatus = RequestStatus.ERROR;
        eventHandler();
      });
  }) as Promise<string | null>;
};
