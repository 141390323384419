import { selector, selectorFamily } from "recoil";
import { sendRequest } from "../core/api/request";
import type { Course, PayForm, Payments, UserData } from "../types/main";
import { courseData, currentUserData, paymentsData } from "./atoms";

export const currentUserDataQuery = selector<UserData>({
  key: "currentUserDataQuery",
  get: async ({ get }) => {
    const newCurrentUserData = get(currentUserData);
    if (newCurrentUserData != null) {
      return newCurrentUserData;
    }
    const data = await sendRequest({
      method: "GET",
      url: "/profile/generaldata/",
    })
      .then(({ data: successData }) => {
        if (successData?.subscriptionData?.dateExpiration) {
          successData.subscriptionData.dateExpiration = new Date(
            successData.subscriptionData.dateExpiration
          );
        }
        return {
          response: successData,
          status: "SUCCESS",
        };
      })
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    if (data.status === "ERROR") {
      return data;
    }
    return data;
  },
  set: ({ set }, newValue) => set(currentUserData, newValue),
});

export const payDataQuery = selector({
  key: "payDataQuery",
  get: async () => {
    // @TODO Promise
    const typesData = await sendRequest({
      method: "GET",
      url: "/payment/types/",
    })
      .then(({ data: successData }) => ({
        response: successData,
        status: "SUCCESS",
      }))
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    if (typesData.status === "ERROR") {
      throw typesData;
    }

    const subscriptionsData = await sendRequest({
      method: "GET",
      url: "/payment/subscriptions/",
    })
      .then(({ data: successData }) => ({
        response: successData,
        status: "SUCCESS",
      }))
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    if (subscriptionsData.status === "ERROR") {
      throw subscriptionsData;
    }

    return {
      paymentTypes: typesData.response as PayForm.PaymentType[],
      subscriptions: subscriptionsData.response as PayForm.Subscription[],
    };
  },
});

export const paymentsDataQuery = selector<Payments.Data>({
  key: "paymentsDataQuery",
  get: async ({ get }) => {
    const newPaymentsData = get(paymentsData);
    if (newPaymentsData != null) {
      return newPaymentsData;
    }
    const data = await sendRequest({
      method: "GET",
      url: "/payment/list/",
    })
      .then(({ data: successData }: { data: Payments.Item[] }) => {
        successData.forEach((v) => {
          if (v.datePaid) {
            v.datePaid = new Date(v.datePaid);
          }
        });
        return {
          response: successData,
          status: "SUCCESS",
        };
      })
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    if (data.status === "ERROR") {
      throw data;
    }
    return data;
  },
  // set: ({ set }, newValue) => set(paymentsData, newValue),
});

export const requestCourseData = async (
  isAllTopics?: boolean
): Promise<Course.Data> => {
  const data = await sendRequest({
    method: "GET",
    url: `/assignments/course/${isAllTopics ? "alltopics/" : ""}`,
  })
    .then(({ data: successData }) => ({
      response: successData,
      status: "SUCCESS",
    }))
    .catch((errorData) => ({
      response: errorData,
      status: "ERROR",
    }));
  if (data.status === "ERROR") {
    throw data;
  }
  return data;
};

export const courseDataQuery = selector<Course.Data>({
  key: "courseDataQuery",
  get: async ({ get }) => {
    const newCourseData = get(courseData);
    if (newCourseData != null) {
      return newCourseData;
    }
    return await requestCourseData();
  },
});

export const sectionDataQuery = selectorFamily({
  key: "sectionDataQuery",
  get: (props: { sectionId: number }) => async ({ get }) => {
    const data = get(courseDataQuery);
    const section = data.response?.sections.find(
      (s) => s.id === props.sectionId
    );
    return section;
  },
});

export const topicDataQuery = selectorFamily({
  key: "topicDataQuery",
  get: ({ topicId }: { topicId: number }) => async ({ get }) => {
    const data = get(courseDataQuery);
    let sectionId = null;
    let topic: Course.Topic | null = null;
    data.response?.sections.forEach((s) =>
      s.topics.find((t) => {
        if (t.id === topicId) {
          sectionId = s.id;
          topic = t;
          return true;
        }
        return false;
      })
    );
    if (topic == null) {
      const notFoundOut = {
        content: [],
        data: null,
        sectionId,
        status: "ERROR",
        text: null,
      };
      throw notFoundOut;
    }
    const contentData = await sendRequest({
      method: "GET",
      url: `/assignments/topiccmi5/?courseId=${data.response?.id}&topicId=${topicId}`,
    })
      .then(({ data: successData }) => ({
        response: successData,
        status: "SUCCESS",
      }))
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    const out = {
      content: (contentData.response?.content ?? []) as Course.Content[],
      data: topic as Course.Topic | null,
      sectionId,
      status: contentData.status,
      text: (contentData.response?.text ?? null) as string | null,
    };
    out.content = [
      ...out.content.filter((v) => v.title === "Схема"),
      ...out.content.filter((v) => v.title === "Теория"),
      ...out.content.filter((v) => v.title === "Упражнения"),
      ...out.content.filter((v) => v.title === "Тест"),
      ...out.content.filter(
        (v) => ["Схема", "Теория", "Упражнения", "Тест"].indexOf(v.title ?? "") === -1
      ),
    ];
    if (contentData.status === "ERROR") {
      throw out;
    }
    return out;
  },
});

export const activateDeviceQuery = selectorFamily({
  key: "activateDeviceQuery",
  get: (props: { dfpUser: string }) => async () => {
    const data = await sendRequest({
      bodyData: props,
      method: "POST",
      url: "/security/activatedfp/",
    })
      .then(({ data: successData }) => ({
        response: successData,
        status: "SUCCESS",
      }))
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    if (data.status === "ERROR") {
      throw data;
    }
    return data;
  },
});

export const activatePasswordQuery = selectorFamily({
  key: "activatePasswordQuery",
  get: (props: { code: string; login: string }) => async () => {
    const data = await sendRequest({
      bodyData: props,
      method: "POST",
      url: "/auth/activatepass/",
    })
      .then(({ data: successData }) => ({
        response: successData,
        status: "SUCCESS",
      }))
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    if (data.status === "ERROR") {
      throw data;
    }
    return data;
  },
});

export const dfpUserConfirmQuery = selector({
  key: "dfpUserConfirmQuery",
  get: async () => {
    const data = await sendRequest({
      method: "POST",
      url: "/security/confirmdfp/",
    })
      .then(({ data: successData }) => ({
        response: successData,
        status: "SUCCESS",
      }))
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    if (data.status === "ERROR") {
      throw data;
    }
    return data;
  },
});

export const paymentSuccessQuery = selectorFamily({
  key: "paymentSuccessQuery",
  get: (props: {
    InvId: string;
    OutSum: string;
    SignatureValue: string;
  }) => async () => {
    const data = await sendRequest({
      method: "GET",
      params: props,
      url: "/payment/success/",
    })
      .then(({ data: successData }) => ({
        response: successData,
        status: "SUCCESS",
      }))
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    if (data.status === "ERROR") {
      throw data;
    }
    return data;
  },
});
