import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { UserTopicContainer } from "../user_topic_container/user_topic_container";

type Props = {
    mineResults?: boolean;
    topicId: number;
    userId: number;
};

export const UserTopicSuspense: FC<Props> = ({ topicId, userId }: Props) => (
    <ErrorBoundary>
        <Suspense fallback={<CircularProgress />}>
            <UserTopicContainer mineResults topicId={topicId} userId={userId} />
        </Suspense>
    </ErrorBoundary>
);
