import type { FC } from "react";
import React, { memo } from "react";
import { useRecoilValue } from "recoil";

import { dfpUserConfirmQuery } from "../../../state";

const DfpUserConfirmContainerFC: FC = () => {
  useRecoilValue(dfpUserConfirmQuery);

  return (
    <>
      <h2>Готово!</h2>
      <p>Письмо для подтверждения авторизации отправлено.</p>
    </>
  );
};

export const DfpUserConfirmContainer = memo(DfpUserConfirmContainerFC);
