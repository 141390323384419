import type { FC } from "react";
import React, { memo, useMemo } from "react";

import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

import { getNumbersArray } from "../../../../../utils/get_numbers_array";
import { getWordsArray } from "../../../../../utils/get_words_array";
import { symmetricDifferenceOfArrays } from "../../../../../utils/symmetric_difference_of_arrays";
import { ExerciseBody } from "../../../../basic/exercise_body/exercise_body";

import "./user_question_result.scss";

type Props = {
    answers: any;
    number: number;
    question: any;
};

const UserQuestionResultFC: FC<Props> = ({ answers, number, question }: Props) => {
    switch (question.typeId) {
        case 1:
        case 2: {
            const userAnswers = (answers[question.id.toString()] ?? {}) as Record<string, boolean>;

            return (
                <div className="user-question-result">
                    <ExerciseBody
                        key={`question_result_${question.id}`}
                        ordinalNumber={number}
                        text={question.text}
                        topic={null}
                    />
                    <div>
                        <FormGroup>
                            {question.answers.map((a: any) => (
                                <FormControlLabel
                                    className={
                                        a.correct
                                            ? "user-question-result__answer-success"
                                            : "user-question-result__answer-error"
                                    }
                                    key={`question_result_answer_${a.id}`}
                                    control={
                                        <Checkbox
                                            checked={userAnswers[a.id.toString()]}
                                            disabled
                                            name={a.id.toString()}
                                        />
                                    }
                                    label={a.text}
                                />
                            ))}
                        </FormGroup>
                    </div>
                </div>
            );
        }
        case 3: {
            const userAnswer = (answers[question.id.toString()] ?? "") as string;
            const isSuccess =
                question.answers?.find((a: any) => a.text.toLowerCase() === userAnswer.trim().toLowerCase()) !==
                undefined;

            return (
                <div className="user-question-result">
                    <ExerciseBody
                        key={`question_result_${question.id}`}
                        ordinalNumber={number}
                        text={question.text}
                        topic={null}
                    />
                    {isSuccess ? (
                        <div>
                            <span className="user-question-result__success-text">Вы ответили правильно:</span>{" "}
                            <strong>{userAnswer}</strong>
                        </div>
                    ) : (
                        <>
                            <div>
                                {userAnswer === "" ? (
                                    <span className="user-question-result__error-text">Вы не ответили на вопрос.</span>
                                ) : (
                                    <>
                                        <span className="user-question-result__error-text">
                                            Вы ответили неправильно:
                                        </span>{" "}
                                        <strong>{userAnswer}</strong>
                                    </>
                                )}
                            </div>
                            {question.answers?.length ? (
                                <div>
                                    {question.answers.length === 1
                                        ? "Правильный ответ:"
                                        : "Возможные варианты правильного ответа:"}
                                    <ul>
                                        {question.answers?.map((a: any) => (
                                            <li key={`question_result_answer_${a.id}`}>
                                                <span dangerouslySetInnerHTML={{ __html: a.text }} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div>Правильные ответы не настроены. Приносим свои извинения.</div>
                            )}
                        </>
                    )}
                </div>
            );
        }
        case 4: {
            const userAnswer = (answers[question.id.toString()] ?? "") as string;
            const userAnswerNumbers = getNumbersArray(userAnswer);

            const isSuccess =
                question.answers?.find(
                    (a: any) => symmetricDifferenceOfArrays(userAnswerNumbers, getNumbersArray(a.text)).length === 0
                ) !== undefined;

            return (
                <div className="user-question-result">
                    <ExerciseBody
                        key={`question_result_${question.id}`}
                        ordinalNumber={number}
                        text={question.text}
                        topic={null}
                    />
                    {isSuccess ? (
                        <div>
                            <span className="user-question-result__success-text">Вы ответили правильно:</span>{" "}
                            <strong>{userAnswer}</strong>
                        </div>
                    ) : (
                        <>
                            <div>
                                {userAnswer === "" ? (
                                    <span className="user-question-result__error-text">Вы не ответили на вопрос.</span>
                                ) : (
                                    <>
                                        <span className="user-question-result__error-text">
                                            Вы ответили неправильно:
                                        </span>{" "}
                                        <strong>{userAnswer}</strong>
                                    </>
                                )}
                            </div>
                            {question.answers?.length ? (
                                <div>
                                    {question.answers.length === 1
                                        ? "Правильный ответ:"
                                        : "Возможные варианты правильного ответа:"}
                                    <ul>
                                        {question.answers?.map((a: any) => (
                                            <li key={`question_result_answer_${a.id}`}>
                                                <span dangerouslySetInnerHTML={{ __html: a.text }} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div>Правильные ответы не настроены. Приносим свои извинения.</div>
                            )}
                        </>
                    )}
                </div>
            );
        }
        case 5: {
            const userAnswers = (answers[question.id.toString()] ?? {}) as Record<string, string>;

            const errors =
                question.answers?.filter((a: any) => {
                    const userAnswerNumbers = getNumbersArray(userAnswers[a.id.toString()] ?? "");
                    return symmetricDifferenceOfArrays(userAnswerNumbers, getNumbersArray(a.text)).length > 0;
                }) ?? [];

            const questionAnswers = [...(question.answers ?? [])].sort(
                (a: any, b: any) => (a.order ?? 0) - (b.order ?? 0)
            );

            return (
                <div className="user-question-result">
                    <ExerciseBody
                        key={`question_result_${question.id}`}
                        ordinalNumber={number}
                        text={question.text}
                        topic={null}
                    />
                    {Object.keys(userAnswers).length === 0 ? (
                        <div className="user-question-result__error-text">Вы не ответили на вопрос.</div>
                    ) : (
                        <div className="user-question-result__answer">
                            <div className="user-question-result__label">
                                {errors.length ? (
                                    <span className="user-question-result__error-text">Вы ответили неправильно:</span>
                                ) : (
                                    <span className="user-question-result__success-text">Вы ответили правильно:</span>
                                )}
                            </div>
                            <Table size="small" style={{ width: "auto" }}>
                                <TableHead>
                                    <TableRow>
                                        {questionAnswers.map((a) => (
                                            <TableCell key={`question_result__cell-description__${a.id}`}>
                                                <strong>{a.description}</strong>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {questionAnswers.map((a) => (
                                            <TableCell key={`question_result__cell-answer__${a.id}`}>
                                                {userAnswers?.[a.id]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    )}
                    {question.answers?.length && errors.length ? (
                        <div className="user-question-result__answer">
                            <div className="user-question-result__label">
                                Правильный
                                <br />
                                ответ:
                            </div>
                            <Table size="small" style={{ width: "auto" }}>
                                <TableHead>
                                    <TableRow>
                                        {questionAnswers.map((a) => (
                                            <TableCell key={`question_result__cell-description__${a.id}`}>
                                                <strong>{a.description}</strong>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {questionAnswers.map((a) => (
                                            <TableCell key={`question_result__cell-answer__${a.id}`}>
                                                <span dangerouslySetInnerHTML={{ __html: a.text }} />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    ) : (
                        errors.length > 0 && <div>Правильные ответы не настроены. Приносим свои извинения.</div>
                    )}
                </div>
            );
        }
        case 6: {
            const userAnswer = (answers[question.id.toString()] ?? "") as string;
            const userAnswerWords = getWordsArray(userAnswer);

            const isSuccess =
                question.answers?.find(
                    (a: any) => symmetricDifferenceOfArrays(userAnswerWords, getWordsArray(a.text)).length === 0
                ) !== undefined;

            return (
                <div className="user-question-result">
                    <ExerciseBody
                        key={`question_result_${question.id}`}
                        ordinalNumber={number}
                        text={question.text}
                        topic={null}
                    />
                    {isSuccess ? (
                        <div>
                            <span className="user-question-result__success-text">Вы ответили правильно:</span>{" "}
                            <strong>{userAnswer}</strong>
                        </div>
                    ) : (
                        <>
                            <div>
                                {userAnswer === "" ? (
                                    <span className="user-question-result__error-text">Вы не ответили на вопрос.</span>
                                ) : (
                                    <>
                                        <span className="user-question-result__error-text">
                                            Вы ответили неправильно:
                                        </span>{" "}
                                        <strong>{userAnswer}</strong>
                                    </>
                                )}
                            </div>
                            {question.answers?.length ? (
                                <div>
                                    {question.answers.length === 1
                                        ? "Правильный ответ:"
                                        : "Возможные варианты правильного ответа:"}
                                    <ul>
                                        {question.answers?.map((a: any) => (
                                            <li key={`question_result_answer_${a.id}`}>
                                                <span dangerouslySetInnerHTML={{ __html: a.text }} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div>Правильные ответы не настроены. Приносим свои извинения.</div>
                            )}
                        </>
                    )}
                </div>
            );
        }
        default:
            return null;
    }
};

export const UserQuestionResult = memo(UserQuestionResultFC);
