import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { UserResultsContainer } from "../user_results_container/user_results_container";

type Props = {
    mineResults?: boolean;
    userId: number;
};

export const UserResultsSuspense: FC<Props> = ({ mineResults, userId }: Props) => (
    <ErrorBoundary>
        <Suspense fallback={<CircularProgress />}>
            <UserResultsContainer mineResults={mineResults} userId={userId} />
        </Suspense>
    </ErrorBoundary>
);
