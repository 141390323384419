import type { FC } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { usePayFormSubmit } from "../../../actions/data";
import {
  currentUserDataQuery,
  payFormRequest,
  payDataQuery,
} from "../../../state";
import { PayForm } from "../../../types/main.d";
// import FullAccessView from "./full_access_view";
import PayFormView from "./pay_form_view";
import PayRedirectView from "./pay_redirect_view";
import PaySuccessView from "./pay_success_view";

export const PayFormContainer: FC = () => {
  const setCurrentUserData = useSetRecoilState(currentUserDataQuery);
  // const { response: userData } = useRecoilValue(currentUserDataQuery);
  // const isActiveSubscription =
  //   userData?.subscriptionData?.dateExpiration != null &&
  //   userData.subscriptionData.dateExpiration > new Date();
  const [request, setRequest] = useRecoilState(payFormRequest);
  const { paymentTypes, subscriptions } = useRecoilValue(payDataQuery);
  const payFormSubmit = usePayFormSubmit();
  const [payData, setPayData] = useState<PayForm.State>({
    subscriptionId: {
      error: false,
      errorChecker: (value) => value === 0,
      value: 0,
    },
    paymentType: {
      error: false,
      errorChecker: (value) => value === null,
      value: null,
    },
  });
  const handleChangeDataItem = useCallback(
    (fieldName: PayForm.FieldName, value: any) =>
      setPayData((oldPayData) => ({
        ...oldPayData,
        [fieldName]: {
          ...oldPayData[fieldName],
          error: oldPayData[fieldName].errorChecker
            ? (oldPayData[fieldName].errorChecker as any)(value)
            : oldPayData[fieldName].error,
          value,
        },
      })),
    []
  );
  const handleSubmit = useCallback(() => {
    const newPayData: any = {};
    let firstErrorField: any;
    Object.keys(payData).forEach((k) => {
      const error = payData[k as PayForm.FieldName].errorChecker
        ? (payData[k as PayForm.FieldName].errorChecker as any)(
            payData[k as PayForm.FieldName].value
          )
        : payData[k as PayForm.FieldName].error;
      if (error && !firstErrorField) {
        firstErrorField = payData[k as PayForm.FieldName] as any;
      }
      newPayData[k as PayForm.FieldName] = {
        ...payData[k as PayForm.FieldName],
        ...{
          error,
        },
      } as any;
    });
    setTimeout(() => {
      if (firstErrorField && firstErrorField.ref) {
        firstErrorField.ref.current.focus();
      }
    }, 0);
    setPayData(newPayData);
    if (!firstErrorField && request.status !== "REQUEST") {
      const selectedSubscription = subscriptions.find(
        (s) => s.id === newPayData.subscriptionId.value
      );
      if (selectedSubscription) {
        payFormSubmit({
          paymentType: newPayData.paymentType.value,
          subscriptionId: newPayData.subscriptionId.value,
        }).then((res) => {
          if (res.payUrl) {
            document.location = res.payUrl;
          } else if (res.subscriptionData) {
            setCurrentUserData((curVal) =>
              curVal.response != null
                ? {
                    ...curVal,
                    ...{
                      response: {
                        ...curVal.response,
                        ...{
                          subscriptionData: {
                            id: res.subscriptionData.id,
                            dateExpiration: res.subscriptionData.dateExpiration
                              ? new Date(res.subscriptionData.dateExpiration)
                              : null,
                            title: res.subscriptionData.title,
                          },
                        },
                      },
                    },
                  }
                : curVal
            );
          }
        });
      }
    }
  }, [
    payData,
    payFormSubmit,
    request.status,
    setCurrentUserData,
    subscriptions,
  ]);

  // Reset status on componentWillUnmount
  useEffect(
    () => () =>
      setRequest({
        status: "DEFAULT",
      }),
    [setRequest]
  );

  // if (request.status === "DEFAULT" && isActiveSubscription) {
  //   return <FullAccessView />;
  // }

  if (request.response?.payUrl) {
    return <PayRedirectView />;
  }

  if (request.status === "SUCCESS") {
    return <PaySuccessView />;
  }

  return (
    <PayFormView
      data={payData}
      errorMessage={request.response?.message}
      onChangeDataItem={handleChangeDataItem}
      onSubmit={handleSubmit}
      showError={request.status === "ERROR"}
      showLoader={request.status === "REQUEST"}
      paymentTypes={paymentTypes}
      subscriptions={subscriptions}
    />
  );
};
