import type { FC } from "react";
import React from "react";

import { ButtonLink } from "../../basic/button_link";
import { Title } from "../../basic/title/title";
import { ClassesSuspense } from "./components/classes_suspense/classes_suspense";

const rightContent = <ButtonLink href="/classes/add/">Добавить</ButtonLink>;

export const ClassesPage: FC = () => (
  <>
    <Title rightContent={rightContent} title="Классы" />
    <ClassesSuspense />
  </>
);
