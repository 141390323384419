import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { topicDataQuery } from "../../../state";

type Props = {
  isAuthorized: boolean;
} & RouteComponentProps<{
  topicId: string;
}>;

const CourseTopicContainer: FC<any> = (props: Props) => {
  const topicId = parseInt(props.match.params.topicId, 10);
  const { content, data, sectionId, text } = useRecoilValue(
    topicDataQuery({ topicId })
  );

  if (!data) {
    return (
      <div className="course-topic">
        <Link to="/education/" className="course-topic__back">
          &larr; Назад
        </Link>
        <h1>Тема не найдена</h1>
      </div>
    );
  }

  return (
    <div className="course-topic">
      <Link to={`/section/${sectionId}/`} className="course-topic__back">
        &larr; Назад
      </Link>
      <h1>{data.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: text || "" }} />
      <div className="course-topic__content">
        <div className="course-topic__content-container">
          {content.map((v) => (
            <p className="course-topic__content-title" key={`content_${v.id}`}>
              <Link to={`/topic/${topicId}/content/${v.id}/`}>
                {v.title || <i>Контент № {v.id}</i>}
              </Link>
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseTopicContainer;
