import type { FC } from "react";
import React from "react";

export const AboutPage: FC = () => (
  <>
    <h1>Сведения об образовательной организации</h1>
    <p>
      <strong>
        Общество с ограниченной ответственностью «Современное образование».
      </strong>
    </p>
    <ol>
      <li>
        Юридический адрес: 614000, г. Пермь, ул. Николая Островского, д. 30
      </li>
      <li>
        Сайт:{" "}
        <a href="https://edu-modern.ru" rel="noreferrer" target="_blank">
          www.edu-modern.ru
        </a>
      </li>
      <li>
        Электронная почта:{" "}
        <a href="mailto:so.perm@edu-modern.ru">so.perm@edu-modern.ru</a>
      </li>
      <li>
        Адреса офисов:
        <ul>
          <li>
            г. Пермь, ул. Пермская, 50, 2 этаж (каб. с 1 по 15), 3 этаж (каб. с
            1 по 15),
            <br />
            +7 (342) 2-55-44-99, +7 (342) 255-43-15.
          </li>
          <li>
            г. Пермь, ул. Островского, д. 30;
            <br />
            +7 (342) 248-55-30.
          </li>
        </ul>
      </li>
      <li>
        Отдельные телефоны служб:
        <ul>
          <li>
            Отдел консультантов по образовательным программам +7 (342) 255-44-99
          </li>
          <li>Бухгалтерия и отдел персонала +7 (342) 255-44-98</li>
        </ul>
      </li>
      <li>
        Учредитель: Сорокина Марина Геннадьевна, Полыгалова Наталья Валерьевна.
      </li>
      <li>Дата создания: 2020 год</li>
      <li>Режим и график работы: пн–сб, с 10 до 19 часов</li>
    </ol>
  </>
);
