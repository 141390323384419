import type { FC } from "react";
import React, { memo, useCallback } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Button } from "@material-ui/core";

import { ButtonLink } from "../../../components/basic/button_link";
import { removeJwtToken } from "../../../core/jwt_token";
import { activateDeviceQuery, authorizationToken } from "../../../state";

type Props = {
  dfpUser: string;
  isAuthorized: boolean;
};

const ActivateDeviceContainerFC: FC<Props> = ({
  dfpUser,
  isAuthorized,
}: Props) => {
  const setAuthorizationToken = useSetRecoilState(authorizationToken);
  const data = useRecoilValue(activateDeviceQuery({ dfpUser }));

  const logOut = useCallback(() => removeJwtToken(setAuthorizationToken), [
    setAuthorizationToken,
  ]);

  if (data.response === "success") {
    return (
      <>
        <h2>Авторизация подтверждена!</h2>
        <p>Теперь Вы можете пользоваться сайтом без ограничений.</p>
        <ButtonLink href="/education/" size="large">
          Начать обучение
        </ButtonLink>
      </>
    );
  }

  if (data.response === "check_user") {
    return (
      <>
        <h2>Авторизация подтверждена!</h2>
        <p>
          Но войти нужно в аккаунт, для которого Вы запросили подтверждение
          авторизации.
        </p>
        {isAuthorized ? (
          <Button
            color="primary"
            onClick={logOut}
            size="large"
            variant="contained"
          >
            Выйти
          </Button>
        ) : (
          <ButtonLink href="/login/" size="large">
            Войти
          </ButtonLink>
        )}
      </>
    );
  }

  return (
    <>
      <h2>Авторизация подтверждена!</h2>
      <p>Но войти нужно оттуда, где Вы запросили подтверждение авторизации.</p>
      <ButtonLink href="/" size="large">
        Вернутся на главную страницу
      </ButtonLink>
    </>
  );
};

export const ActivateDeviceContainer = memo(ActivateDeviceContainerFC);
