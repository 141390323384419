import type { FC } from "react";
import React from "react";

import { TeachersSuspense } from "./components/teachers_suspense/teachers_suspense";

export const TeachersPage: FC = () => (
  <>
    <h1>Преподаватели</h1>
    <TeachersSuspense />
  </>
);
