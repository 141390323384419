import { removeJwtToken, setJwtToken } from "../jwt_token";
import type { SendRequestType } from "./types";
import { getHeaders } from "./utils/get_headers";
import { getUrl } from "./utils/get_url";

export const sendBasicRequest = ({
  bodyData,
  method,
  params,
  setAuthorizationToken,
  url,
}: SendRequestType) =>
  fetch(getUrl(url, params), {
    body: JSON.stringify(bodyData),
    headers: getHeaders(),
    method,
  })
    .then((res) => {
      if (res.status === 401) {
        if (setAuthorizationToken) {
          removeJwtToken(setAuthorizationToken);
        }
        return Promise.reject({
          code: 0,
          details: null,
          message: "",
        });
      }
      return res
        .json()
        .catch(() =>
          Promise.reject({
            code: 0,
            details: null,
            message: "",
          })
        )
        .then((responseData) => {
          setJwtToken(responseData.jwt, setAuthorizationToken);
          return responseData.success
            ? {
                cursor: responseData.cursor as string | null,
                data: responseData.data,
              }
            : Promise.reject(responseData.error);
        });
    })
    .catch(() =>
      Promise.reject({
        code: 0,
        details: null,
        message: "",
      })
    );
