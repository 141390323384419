import type { FC } from "react";
import React, { memo, useMemo } from "react";

import { useSerialChart } from "../../../../../hooks/amcharts/use_serial_chart";
import type { Course } from "../../../../../types/main";
import type { ClassResultsDetailedType } from "../../types";
import { ClassResultsTopics } from "../class_results_topics/class_results_topics";

import "./class_results_charts.scss";

type Props = {
    course?: Course.Data["response"];
    results: ClassResultsDetailedType;
};

const ClassResultsChartsFC: FC<Props> = ({ course, results }: Props) => {
    const chartData1 = useMemo(
        () =>
            results.analytics.resultByPercents.map((v) => ({
                count: v.count,
                label: `${v.from}–${v.to} %`,
            })),
        [results]
    );

    useSerialChart({
        countBalloonText: "Решения с результатом",
        data: chartData1,
        id: "chartdiv1",
        isMakeChart: chartData1.length > 0,
        title: "Распределение решений по результатам",
    });

    const chartData2 = useMemo(
        () =>
            results.analytics.resultByDuration.map((v) => ({
                average: Math.floor(v.average),
                count: v.count,
                label: `${v.from}–${v.to} мин.`,
                max: Math.floor(v.max),
            })),
        [results]
    );

    useSerialChart({
        averageBalloonText: "Средний результат среди<br>решений со скоростью",
        countBalloonText: "Решения со скоростью",
        data: chartData2,
        id: "chartdiv2",
        isMakeChart: chartData2.length > 0,
        maxBalloonText: "Лучший результат среди<br>решений со скоростью",
        title: "Распределение решений по затраченному времени",
    });

    return (
        <>
            <h3>Графики</h3>
            {chartData1.length > 0 ? (
                <div className="chartdiv" id="chartdiv1" />
            ) : (
                <div className="class-results-charts__message">
                    <p className="class-results-charts__title">Распределение решений по результатам</p>
                    <p>Данных для графика пока нет.</p>
                </div>
            )}
            {chartData2.length > 0 ? (
                <div className="chartdiv" id="chartdiv2" />
            ) : (
                <div className="class-results-charts__message">
                    <p className="class-results-charts__title">Распределение решений по затраченному времени</p>
                    <p>Данных для графика пока нет.</p>
                </div>
            )}
            {course?.sections && <h3>Результаты по темам</h3>}
            {course?.sections?.map((s) => (
                <ClassResultsTopics
                    key={`section_id_${s.id}`}
                    resultByTopicIds={results.analytics.resultByTopicIds}
                    section={s}
                />
            ))}
        </>
    );
};

export const ClassResultsCharts = memo(ClassResultsChartsFC);
