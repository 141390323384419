import type { FC } from "react";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import plural from "plural-ru";

import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

import { currentUserDataQuery } from "../../../../../state";
import { weeklyRatingDataQuery } from "../../state/weekly_rating";

import "./weekly_rating.scss";

const getClassName = (index: number, isMine: boolean) => {
    const classNames: string[] = [];
    if (index === 0) {
        classNames.push("weekly-rating__cell-first-place");
    }
    if (index === 1) {
        classNames.push("weekly-rating__cell-second-place");
    }
    if (index === 2) {
        classNames.push("weekly-rating__cell-third-place");
    }
    if (isMine) {
        classNames.push("weekly-rating__cell-mine");
    }
    return classNames.join(" ");
};

const WeeklyRatingFC: FC = () => {
    const [{ response: weeklyRatingData, status }] = useRecoilState(weeklyRatingDataQuery);
    const [{ response: userData, status: currentUserStatus }] = useRecoilState(currentUserDataQuery);

    if (status === "ERROR" || currentUserStatus === "ERROR" || !userData) {
        return <>Доступ закрыт</>;
    }

    if (weeklyRatingData.length === 0) {
        return <>Рейтинг пока несформирован</>;
    }

    return (
        <div className="weekly-rating__table">
            <Table aria-label="simple table" size="small" stickyHeader>
                <TableBody>
                    {weeklyRatingData.map((person, index) => (
                        <TableRow
                            key={`weekly_rating_person_${person.id}`}
                            className={getClassName(index, userData?.id === person.id)}
                            hover
                        >
                            <TableCell padding="none">
                                <div className="weekly-rating__cell-number">{index + 1}.</div>
                            </TableCell>
                            <TableCell padding="none" width="100%">
                                <div className="weekly-rating__cell-data">
                                    <div className="weekly-rating__cell-avatar">
                                        <Link to={`/users/matching/${person.id}/`}>
                                            <img alt="" src={person.avatarMini} />
                                        </Link>
                                    </div>
                                    <div className="weekly-rating__cell-name">
                                        <Link to={`/users/matching/${person.id}/`}>
                                            {`${person.lastName ?? ""} ${person.firstName ?? ""}`.trim()}
                                        </Link>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell padding="none">
                                <div className="weekly-rating__cell-score">
                                    <span>{person.totalScore}</span>{" "}
                                    {plural(person.totalScore, "балл", "балла", "баллов")}
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export const WeeklyRating = memo(WeeklyRatingFC);
