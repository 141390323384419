const isRelease = process.env.REACT_APP_STAGE === "release";

export const BaseAPIUrl = isRelease ? "//api2.rus-na5.ru" : "http://self-test";
export const emailRegExp = /([a-zA-Z0-9_.\-+]+)@([a-zA-Z0-9-]+)\.([a-zA-Z0-9\-.]+)/i;
export const errorColors = {
  dark: "#d32f2f",
  light: "#e57373",
  main: "#f44336",
};
export const localSettingsAPI = isRelease
  ? ""
  : "&api=1&subdomain=api2&domain=21";
const isHttps = window.location.href.indexOf("https") === 0;
export const LRS = {
  endpoint: isRelease
    ? `${isHttps ? "https" : "http"}://lrs.rus-na5.ru/`
    : "http://self-test/customsubdomain/lrs/21/",
  fetchURL: isRelease
    ? `${
        isHttps ? "https" : "http"
      }://lrs.rus-na5.ru/accesstoken2basictoken/?accesstoken=`
    : "http://self-test/customsubdomain/lrs/21/accesstoken2basictoken/?accesstoken=",
};
export const successColors = {
  dark: "green",
  light: "green",
  main: "green",
};
// $color_Text_price: #ff8713;
// $color_Text_link_hover: #b4d05d;
export const MuiTheme = {
  overrides: {
    // Style sheet name
    MuiButton: {
      // Name of the rule
      containedPrimary: {
        textTransform: "none",
      },
      containedSecondary: {
        textTransform: "none",
      },
      outlined: {
        borderRadius: "18px",
        textTransform: "none",
      },
      root: {
        borderRadius: "18px",
        textTransform: "none",
      },
    },
  },
  palette: {
    primary: {
      contrastText: "#ffffff",
      main: "#ff8713",
    },
    secondary: {
      contrastText: "#ffffff",
      main: "#b4d05d",
    },
    error: errorColors,
  },
  typography: {
    fontFamily:
      // '"open_sans", "Segoe UI", "Helvetica Neue", Helvetica, Arial, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif',
      "Gilroy,-apple-system,sans-serif",
    // fontSize: 14,
    // fontWeightLight: 300,
    // fontWeightMedium: 500,
    // fontWeightRegular: 400
  },
} as any;
