import type { FC } from "react";
import React from "react";

export const RecoverSuccess: FC = () => {
  return (
    <>
      <h1>Новый пароль отправлен на почту!</h1>
      <p>
        Если на указанную электронную почту не пришло письмо, то&nbsp;попробуйте
        поискать его в спаме.
      </p>
    </>
  );
};
