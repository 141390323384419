import { useCallback } from "react";

import type { BodyData, Form, FormFieldValue } from "../types/main.d";

export const useOnSubmitClick = (
  formData: Record<string, FormFieldValue<any>>,
  requestStatus: Form.RequestStatus,
  setFormData: any | undefined,
  submit: (bodyData: BodyData) => void
) =>
  useCallback(() => {
    const dataForSubmit: BodyData = {};
    const newFormData: {
      [index: string]: FormFieldValue<any>;
    } = {};
    let firstErrorField: FormFieldValue<any> | null = null;
    Object.keys(formData).forEach((k) => {
      const error = formData[k].errorChecker
        ? (formData[k] as any).errorChecker(formData[k].value)
        : formData[k].error;
      if (error && !firstErrorField) {
        firstErrorField = formData[k];
      }
      newFormData[k] = {
        ...formData[k],
        ...{
          error,
        },
      };
      dataForSubmit[k] = newFormData[k].value;
    });
    setFormData?.(newFormData);
    if (!firstErrorField && requestStatus !== "REQUEST") {
      submit(dataForSubmit);
    }
    setTimeout(() => firstErrorField?.ref?.current.focus(), 0);
  }, [formData, requestStatus, setFormData, submit]);
