import "amcharts3/amcharts/amcharts";
import "amcharts3/amcharts/radar";
import "amcharts3/amcharts/serial";

// IE9, IE10 and IE11 requires all of the following polyfills.
// import "core-js/es6/array";
// import "core-js/es6/date";
// import "core-js/es6/function";
// import "core-js/es6/map";
// import "core-js/es6/math";
// import "core-js/es6/number";
// import "core-js/es6/object";
// import "core-js/es6/parse-float";
// import "core-js/es6/parse-int";
// import "core-js/es6/regexp";
// import "core-js/es6/set";
// import "core-js/es6/string";
// import "core-js/es6/symbol";
// import "core-js/es6/weak-map";

import type { FC } from "react";
import React, { memo } from "react";
import { RecoilRoot } from "recoil";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import "./styles/fonts.scss";
import "./styles/general.scss";
import { MuiTheme } from "./constants/general";
import { Routes } from "./routes";

const theme = createMuiTheme(MuiTheme);

const AppFC: FC = () => (
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  </RecoilRoot>
);

export const App = memo(AppFC);
