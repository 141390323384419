import React, { Component } from "react";

import { ButtonLink } from "../button_link";

import "./styles.scss";

type ErrorBoundaryState = {
    errorType: "confirmDevice" | "general" | "rejectDevice" | null;
};
const initialState: ErrorBoundaryState = { errorType: null };

class ErrorBoundary extends Component<any> {
    public state = initialState;

    static getDerivedStateFromError(error: any) {
        if (error.response?.details?.deviceLimitAction === "reject") {
            return { errorType: "rejectDevice" };
        } else if (error.response?.details?.deviceLimitAction === "confirm") {
            return { errorType: "confirmDevice" };
        }
        return { errorType: "general" };
    }

    render() {
        const { errorType } = this.state;
        if (errorType === "confirmDevice") {
            return (
                <>
                    <h2>Превышено количество устройств.</h2>
                    <p>
                        Подтвердите авторизацию с помощью письма,
                        которое&nbsp;будет&nbsp;отправлено&nbsp;на&nbsp;Ваш&nbsp;email.
                    </p>
                    <div className="error-boundary-button">
                        <ButtonLink href="/confirmaccess/" size="large">
                            Подтвердить авторизацию
                        </ButtonLink>
                    </div>
                </>
            );
        } else if (errorType === "general") {
            return <h2>Ошибка.</h2>;
        } else if (errorType === "rejectDevice") {
            return (
                <>
                    <h2>Превышено количество устройств.</h2>
                    <p>Доступ временно ограничен. Попробуйте зайти позже.</p>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
