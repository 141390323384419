import React, { FC } from "react";

import { CourseTopic } from "../../data_blocks/course_topic";

import "./styles.scss";

type Props = {
  isAuthorized: boolean;
};

const TopicPageView: FC<Props> = (props: Props) => (
  <div className="topic-page">
    <CourseTopic {...props} />
  </div>
);

export { TopicPageView };
