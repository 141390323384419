import type { FC } from "react";
import React, { memo, useCallback } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button } from "@material-ui/core";

import { activatePasswordQuery } from "../../../state";

type Props = {
  code: string;
  login: string;
} & RouteComponentProps;

const ActivatePasswordContainerFC: FC<Props> = ({
  code,
  history,
  login,
}: Props) => {
  useRecoilValue(activatePasswordQuery({ code, login }));

  const openLoginUrl = useCallback(() => {
    history.push("/login/");
  }, [history]);

  return (
    <>
      <h2>Ваш новый пароль активирован!</h2>
      <p>
        Пароль успешно изменён. Используйте новый пароль из&nbsp;письма
        и&nbsp;войдите в&nbsp;свой аккаунт.
      </p>
      <Button
        color="primary"
        onClick={openLoginUrl}
        size="large"
        variant="contained"
      >
        Войти
      </Button>
    </>
  );
};

export const ActivatePasswordContainer = memo(
  withRouter(ActivatePasswordContainerFC)
);
