import type { SetterOrUpdater } from "recoil";

const KEY = "jwt";

export const getJwtToken = () => {
  if (localStorage) {
    try {
      return localStorage.getItem(KEY);
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const removeJwtToken = (setToken: SetterOrUpdater<string | null>) => {
  if (localStorage) {
    try {
      localStorage.removeItem(KEY);
      setToken(null);
      window.location.reload();
    } catch (e) {
      return;
    }
  }
};

export const setJwtToken = (
  token: any,
  setToken?: SetterOrUpdater<string | null>
) => {
  if (localStorage && typeof token === "string") {
    try {
      localStorage.setItem(KEY, token);
      setToken?.(token);
    } catch (e) {
      return;
    }
  }
};
