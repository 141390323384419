import type { FC, MouseEventHandler } from "react";
import React, { useCallback, useState } from "react";
import {
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Select,
    TextField,
} from "@material-ui/core";
import { Delete as DeleteIcon, CloudUpload as UploadIcon } from "@material-ui/icons";

import { errorColors, successColors } from "../../../constants/general";
import type { ProfileFormType } from "../../../types/main";

import "./profile_form.scss";

const availableFileExtension = ["jpeg", "jpg", "png"];
const maxFileSize = 6000000;

type Props = {
    avatarUrl: string;
    data: ProfileFormType.State;
    onAvatarDelete?: () => void;
    onAvatarSubmit: (file: File) => void;
    onChangeDataItem: (fieldName: ProfileFormType.FieldName, value: any) => void;
    onSubmit: () => void;
    showAvatarLoader: boolean;
    showClassField: boolean;
    showDeleteButton: boolean;
    showError: boolean;
    showLoader: boolean;
    showNewSelectedAvatar: boolean;
    showSuccess: boolean;
    submitButtonTitle?: string;
};

export const ProfileForm: FC<Props> = ({
    avatarUrl,
    data,
    onAvatarDelete,
    onAvatarSubmit,
    onChangeDataItem,
    onSubmit,
    showAvatarLoader,
    showClassField,
    showDeleteButton,
    showError,
    showLoader,
    showNewSelectedAvatar,
    showSuccess,
    submitButtonTitle = "Сохранить",
}: Props) => {
    const handleChangeValue = useCallback(
        (fieldName: ProfileFormType.FieldName, eventFieldName = "value") => (event: any) =>
            onChangeDataItem(fieldName, event.target[eventFieldName]),
        [onChangeDataItem]
    );

    const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback((event) => onSubmit(), [onSubmit]);

    const handleKeyPress = useCallback(
        (event: any) => {
            if (event.key === "Enter") {
                onSubmit();
            }
        },
        [onSubmit]
    );

    const [avatar, setAvatar] = useState<string | ArrayBuffer | null | undefined>(null);
    const handleUploadAvatar = useCallback(() => {
        const input = document.createElement("input");
        input.accept = ".jpg, .jpeg, .png";
        input.type = "file";
        input.onchange = (e: any) => {
            const file = e?.target?.files && e?.target?.files[0];
            const [, extension] = file.type.split("/");
            if (availableFileExtension.indexOf(extension) === -1) {
                alert("Фотография должна быть в формате JPG, JPEG или PNG.");
                return;
            }
            if (file.size > maxFileSize) {
                alert("Размер фотографии должен быть меньше 5 Мбайт.");
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (readerEvent) => {
                const content = readerEvent?.target?.result;
                setAvatar(content);
            };
            onAvatarSubmit(file);
        };
        input.click();
    }, [onAvatarSubmit, setAvatar]);
    const handleDeleteAvatar = useCallback(() => {
        setAvatar(null);
        onAvatarDelete?.();
    }, [onAvatarDelete, setAvatar]);

    return (
        <div className="profile-form">
            <div className="profile-form__columns">
                <div className="profile-form__column">
                    <div className="profile-form__row">
                        <div className="profile-form__avatar-container">
                            <img
                                alt=""
                                className="profile-form__avatar"
                                src={showNewSelectedAvatar && avatar != null ? avatar.toString() : avatarUrl}
                            />
                            {showAvatarLoader ? (
                                <div>
                                    <CircularProgress size={24} className="profile-form__submit-progress" />
                                </div>
                            ) : (
                                <div className="profile-form__avatar-buttons">
                                    <List component="div" disablePadding={true}>
                                        <ListItem button={true} onClick={handleUploadAvatar}>
                                            <ListItemIcon>
                                                <UploadIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Загрузить" />
                                        </ListItem>
                                        {showDeleteButton && (
                                            <ListItem button={true} onClick={handleDeleteAvatar}>
                                                <ListItemIcon>
                                                    <DeleteIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Удалить" />
                                            </ListItem>
                                        )}
                                    </List>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile-form__column">
                    <div className="profile-form__row">
                        <TextField
                            className="profile-form__field"
                            color="secondary"
                            disabled={showLoader}
                            id="lastName"
                            label="Фамилия"
                            onChange={handleChangeValue("lastName")}
                            onKeyPress={handleKeyPress}
                            variant="outlined"
                            value={data.lastName.value}
                        />
                    </div>
                    <div className="profile-form__row">
                        <TextField
                            className="profile-form__field"
                            color="secondary"
                            disabled={showLoader}
                            id="firstName"
                            label="Имя"
                            onChange={handleChangeValue("firstName")}
                            onKeyPress={handleKeyPress}
                            variant="outlined"
                            value={data.firstName.value}
                        />
                    </div>
                    <div className="profile-form__row">
                        <TextField
                            className="profile-form__field"
                            color="secondary"
                            disabled={showLoader}
                            id="middleName"
                            label="Отчество"
                            onChange={handleChangeValue("middleName")}
                            onKeyPress={handleKeyPress}
                            variant="outlined"
                            value={data.middleName.value}
                        />
                    </div>
                    {showClassField && (
                        <div className="profile-form__row">
                            <FormControl className="profile-form__field" disabled={showLoader} variant="outlined">
                                <InputLabel id="class-label">Параллель</InputLabel>
                                <Select
                                    id="class"
                                    label="Параллель"
                                    labelId="class-label"
                                    native={true}
                                    onChange={handleChangeValue("class")}
                                    value={data.class.value}
                                >
                                    <option value={0}>— не указана —</option>
                                    <option value={1}>1 класс</option>
                                    <option value={2}>2 класс</option>
                                    <option value={3}>3 класс</option>
                                    <option value={4}>4 класс</option>
                                    <option value={5}>5 класс</option>
                                    <option value={6}>6 класс</option>
                                    <option value={7}>7 класс</option>
                                    <option value={8}>8 класс</option>
                                    <option value={9}>9 класс</option>
                                    <option value={10}>10 класс</option>
                                    <option value={11}>11 класс</option>
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    <div className="profile-form__row  profile-form__row--flex">
                        <div className="profile-form__submit">
                            <Button
                                color="primary"
                                disabled={showLoader}
                                onClick={handleSubmit}
                                size="large"
                                variant="contained"
                            >
                                {submitButtonTitle}
                            </Button>
                            {showLoader && <CircularProgress size={24} className="profile-form__submit-progress" />}
                        </div>
                    </div>
                    {showError && (
                        <div className="profile-form__row" style={{ color: errorColors.main }}>
                            Ошибка :( Проверьте введённые данные и&nbsp;попробуйте сохранить их ещё раз.
                        </div>
                    )}
                    {showSuccess && (
                        <div className="profile-form__row" style={{ color: successColors.main }}>
                            Ваши данные сохранены :)
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
