import type { FC } from "react";
import React, { useMemo } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";

type Props = {
  payUrl: string;
  showPayButton: boolean;
  text: string;
  title: string;
  url: string;
} & RouteComponentProps;

const TopicRowView: FC<any> = (props: Props) => {
  const payButton = useMemo(
    () =>
      props.showPayButton ? (
        <div className="course-section__pay">
          доступно по <Link to={props.payUrl}>подписке</Link>
        </div>
      ) : null,
    [props.showPayButton, props.payUrl]
  );

  return (
    <div className="course-section__topic">
      <div className="course-section__topic-container">
        {payButton}
        <p className="course-section__topic-title">
          {props.showPayButton ? (
            props.title
          ) : (
            <Link to={props.url}>{props.title}</Link>
          )}
        </p>
      </div>
    </div>
  );
};

export default withRouter(TopicRowView);
