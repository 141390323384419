import React, { FC } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../basic/error_boundary";
import PaymentSuccessContainer from "./container";

type Props = {
  invId: string;
  outSum: string;
  signatureValue: string;
};

const PaymentSuccess: FC<Props> = ({
  invId,
  outSum,
  signatureValue,
}: React.PropsWithChildren<Props>) => (
  <ErrorBoundary>
    <React.Suspense fallback={<CircularProgress />}>
      <PaymentSuccessContainer
        invId={invId}
        outSum={outSum}
        signatureValue={signatureValue}
      />
    </React.Suspense>
  </ErrorBoundary>
);

export default PaymentSuccess;
