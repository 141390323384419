import type { FC } from "react";
import React from "react";

import { ContactsContacted } from "./components/contacts_contacted/contacts_contacted";
import { ContactsPending } from "./components/contacts_pending/contacts_pending";
import { ContactsRequested } from "./components/contacts_requested/contacts_requested";

export const ContactsPage: FC = () => (
    <>
        <div>
            <ContactsRequested />
        </div>
        <div>
            <ContactsPending />
        </div>
        <h1>Контакты</h1>
        <ContactsContacted />
    </>
);
