import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";

import { ClassResultsSuspense } from "./components/class_results_suspense/class_results_suspense";

type Props = RouteComponentProps<{
  classId: string;
}>;

export const ClassesShowPage: FC<Props> = (props: Props) => {
  const classId = parseInt(props.match.params.classId, 10);

  return <ClassResultsSuspense classId={classId} />;
};
