import React, { FC } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../basic/error_boundary";
import CourseTopicContainer from "./container";

import "./styles.scss";

type Props = {
  isAuthorized: boolean;
};

const CourseTopic: FC<Props> = (props: Props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<CircularProgress />}>
      <CourseTopicContainer {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
export default CourseTopic;
