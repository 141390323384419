import type { FC } from "react";
import React from "react";
import { Button } from "@material-ui/core";

import "./invite_success.scss";

type Props = {
    onClick: () => void;
};

export const InviteSuccess: FC<Props> = ({ onClick }: Props) => (
    <div className="invite-success">
        <h1>Приглашение отправлено!</h1>
        <p>
            <Button color="primary" onClick={onClick} size="large" variant="contained">
                Пригласить ещё
            </Button>
        </p>
    </div>
);
