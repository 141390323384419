import type { FC } from "react";
import React, { memo, useMemo } from "react";

import { UserQuestionResult } from "../user_question_result/user_question_result";

import "./user_topic_test.scss";

type Props = {
    answers: any;
    questions: any;
    resultScoreScaled: number | null;
};

const UserTopicTestFC: FC<Props> = ({ answers, questions, resultScoreScaled }: Props) => {
    const sortedQuestions = useMemo(
        () => [...(Array.isArray(questions) ? questions : [])].sort((a: any, b: any) => a.order - b.order),
        [questions]
    );

    return (
        <div className="user-topic-test">
            {sortedQuestions.length > 0 && (
                <div className="user-topic-test__result">
                    <h3>Решение:</h3>
                    {sortedQuestions
                        .filter((v) => Boolean(v.answers?.length))
                        .map((v, i) => (
                            <UserQuestionResult
                                answers={answers}
                                key={`question_result_${v.id}`}
                                number={i + 1}
                                question={v}
                            />
                        ))}
                </div>
            )}
            <div className="user-topic-test__score">
                <h3>
                    Результат: {resultScoreScaled !== null ? `${Math.round(resultScoreScaled * 100)} %` : <>&mdash;</>}
                </h3>
            </div>
        </div>
    );
};

export const UserTopicTest = memo(UserTopicTestFC);
