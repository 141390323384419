import React, { useCallback } from "react";
import type { FC } from "react";
import { Link } from "react-router-dom";
import { Button, CircularProgress, TextField } from "@material-ui/core";

import { errorColors } from "../../../constants/general";
import contractOfOfferDoc from "../../../documents/contract_of_offer.pdf";
import privacyPolicyDoc from "../../../documents/privacy_policy.pdf";
import type { LogInForm } from "../../../types/main.d";
import "./styles.scss";

const errorColorStyle = { color: errorColors.main };

type Props = {
  data: LogInForm.State;
  emailRef: any;
  errorMessage?: string;
  onChangeDataItem: (fieldName: LogInForm.FieldName, value: any) => void;
  onSubmit: () => void;
  passwordRef: any;
  showError: boolean;
  showLoader: boolean;
};

const LogInFormView: FC<Props> = ({
  data,
  emailRef,
  errorMessage,
  onChangeDataItem,
  onSubmit,
  passwordRef,
  showError,
  showLoader,
}: Props) => {
  const handleChangeValue = useCallback(
    (fieldName: LogInForm.FieldName, eventFieldName = "value") => (
      event: any
    ) => onChangeDataItem(fieldName, event.target[eventFieldName]),
    [onChangeDataItem]
  );
  const handleSubmitForm = useCallback((event?: any) => onSubmit(), [onSubmit]);
  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        handleSubmitForm();
      }
    },
    [handleSubmitForm]
  );

  return (
    <div className="log-in-form">
      <h1>Вход</h1>
      <div className="log-in-form__row">
        <TextField
          autoFocus={true}
          className="log-in-form__field"
          color="secondary"
          disabled={showLoader}
          error={data.email.error}
          id="outlined-basic"
          inputRef={emailRef}
          label="Email"
          onChange={handleChangeValue("email")}
          onKeyPress={handleKeyPress}
          variant="outlined"
          value={data.email.value}
        />
      </div>
      <div className="log-in-form__row">
        <TextField
          className="log-in-form__field"
          color="secondary"
          disabled={showLoader}
          error={data.password.error}
          helperText={data.password.helperText}
          id="password"
          inputRef={passwordRef}
          label="Пароль"
          onChange={handleChangeValue("password")}
          onKeyPress={handleKeyPress}
          type="password"
          variant="outlined"
          value={data.password.value}
        />
      </div>
      <div className="log-in-form__row">
        Нажимая кнопку «Войти», я принимаю условия{" "}
        <a
          href={contractOfOfferDoc}
          rel="noreferrer"
          target="_blank"
          download="Договор оферты.pdf"
        >
          соглашения
        </a>{" "}
        и даю своё согласие на&nbsp;
        <a
          href={privacyPolicyDoc}
          rel="noreferrer"
          target="_blank"
          download="Политика конфиденциальности.pdf"
        >
          передачу и&nbsp;обработку персональных данных и&nbsp;cookies
        </a>
        .
      </div>
      <div className="log-in-form__row log-in-form__row--flex">
        <div className="log-in-form__submit">
          <Button
            color="primary"
            disabled={showLoader}
            onClick={handleSubmitForm}
            size="large"
            variant="contained"
          >
            Войти
          </Button>
          {showLoader && (
            <CircularProgress
              size={24}
              className="log-in-form__submit-progress"
            />
          )}
        </div>
        <div>
          <Link to="/recover/" className="log-in-form__recover">
            Забыли пароль?
          </Link>
        </div>
      </div>
      {showError && (
        <div className="log-in-form__row" style={errorColorStyle}>
          {errorMessage || (
            <>
              Ошибка :( Проверьте введённые данные и&nbsp;попробуйте ввести свои
              данные ещё раз.
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default LogInFormView;
