import { useLayoutEffect } from "react";

declare let AmCharts: any;

type Props = {
  balloonText: string;
  data: any;
  id: string;
  isMakeChart: boolean;
  title?: string;
};

export const useRadarChart = ({
  balloonText,
  data,
  id,
  isMakeChart,
  title,
}: Props) => {
  useLayoutEffect(() => {
    if (isMakeChart) {
      AmCharts.makeChart(id, {
        categoryAxis: {
          axisAlpha: 0,
          gridPosition: "start",
          tickLength: 0,
        },
        categoryField: "label",
        chartCursor: {
          cursorAlpha: 0.1,
          cursorColor: "#ff8713",
          enabled: true,
          fullWidth: true,
          oneBalloonOnly: true,
          valueBalloonsEnabled: false,
          zoomable: false,
        },
        color: "#212731",
        dataProvider: data,
        fontFamily: "Gilroy, -apple-system, sans-serif",
        fontSize: 14,
        graphs: [
          {
            balloonText: `${balloonText} «[[category]]»<br><span style='font-size:20px;'>[[value]] %</span>`,
            bullet: "round",
            bulletBorderAlpha: 1,
            bulletBorderThickness: 3,
            bulletColor: "#FFFFFF",
            bulletSize: 7,
            dashLengthField: "dashLengthLine",
            fillAlphas: 0,
            lineAlpha: 1,
            lineColor: "#a2ce1a",
            lineThickness: 3,
            title: "Средний результат, %",
            useLineColorForBulletBorder: true,
            valueAxis: "axis_of_percent",
            valueField: "percent",
          },
        ],
        startDuration: 0,
        theme: "none",
        titles: title
          ? [
              {
                size: 20,
                text: title,
              },
            ]
          : [],
        type: "radar",
        valueAxes: [
          {
            axisAlpha: 1,
            axisColor: "#ff8713",
            axisThickness: 2,
            gridAlpha: 0.15,
            gridColor: "#212731",
            id: "axis_of_percent",
            maximum: 100,
            minimum: 0,
            position: "left",
          },
        ],
      });
    }
  }, [balloonText, data, id, isMakeChart, title]);
};
