import type { FC } from "react";
import React, { useCallback } from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";

import { errorColors } from "../../../../../constants/general";
import type { InviteForm } from "../../types";
import "./invite_form_view.scss";

type Props = {
    data: InviteForm.State;
    emailRef: any;
    errorMessage?: string;
    nameRef: any;
    onChangeDataItem: (fieldName: InviteForm.FieldName, value: any) => void;
    onSubmit: () => void;
    showError: boolean;
    showLoader: boolean;
};

export const InviteFormView: FC<Props> = ({
    data,
    emailRef,
    errorMessage,
    nameRef,
    onChangeDataItem,
    onSubmit,
    showError,
    showLoader,
}: Props) => {
    const handleChangeValue = useCallback(
        (fieldName: InviteForm.FieldName, eventFieldName = "value") => (event: any) =>
            onChangeDataItem(fieldName, event.target[eventFieldName]),
        [onChangeDataItem]
    );
    const handleSubmitForm = useCallback((event?: any) => onSubmit(), [onSubmit]);
    const handleKeyPress = useCallback(
        (event: any) => {
            if (event.key === "Enter") {
                handleSubmitForm();
            }
        },
        [handleSubmitForm]
    );

    return (
        <div className="invite-form">
            <h1>Приглашение учеников</h1>
            <div className="invite-form__row">
                <TextField
                    autoFocus={true}
                    className="invite-form__field"
                    color="secondary"
                    disabled={showLoader}
                    error={data.name.error}
                    id="outlined-basic"
                    inputRef={nameRef}
                    label="ФИО"
                    onChange={handleChangeValue("name")}
                    onKeyPress={handleKeyPress}
                    variant="outlined"
                    value={data.name.value}
                />
                <TextField
                    className="invite-form__field"
                    color="secondary"
                    disabled={showLoader}
                    error={data.email.error}
                    id="outlined-basic"
                    inputRef={emailRef}
                    label="Email"
                    onChange={handleChangeValue("email")}
                    onKeyPress={handleKeyPress}
                    variant="outlined"
                    value={data.email.value}
                />
                <div className="invite-form__submit">
                    <Button
                        color="primary"
                        disabled={showLoader}
                        onClick={handleSubmitForm}
                        size="large"
                        variant="contained"
                    >
                        Пригласить
                    </Button>
                    {showLoader && <CircularProgress size={24} className="invite-form__submit-progress" />}
                </div>
            </div>
            {showError && (
                <div className="invite-form__row" style={{ color: errorColors.main }}>
                    {errorMessage || (
                        <>
                            Ошибка :( Приглашение не отправлено. Проверьте email и&nbsp;попробуйте пригласить ещё раз.
                            Если ошибка повторяется, возможно, ученик уже зарегистрирован.
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
