import type { FC } from "react";
import React, { memo, useMemo } from "react";

import { useRadarChart } from "../../../../../hooks/amcharts/use_radar_chart";
import type { Course } from "../../../../../types/main";
import type { ClassResultsDetailedType } from "../../types";

import "./class_results_topics.scss";

type Props = {
  resultByTopicIds: ClassResultsDetailedType["analytics"]["resultByTopicIds"];
  section: Course.Section;
};

const ClassResultsTopicsFC: FC<Props> = ({
  resultByTopicIds,
  section,
}: Props) => {
  const id = `chartdiv_section_${section.id}`;

  const chartData = useMemo(
    () =>
      section.topics.map((v) => ({
        label: v.title,
        percent: Math.floor(resultByTopicIds?.[v.id.toString()] ?? 0),
      })),
    [resultByTopicIds, section]
  );

  useRadarChart({
    balloonText: "Средний результат по теме",
    data: chartData,
    id,
    isMakeChart: true,
  });

  return (
    <>
      <p className="class-results-topics__title">{section.title}</p>
      <div className="chartdiv" id={id} />
    </>
  );
};

export const ClassResultsTopics = memo(ClassResultsTopicsFC);
