import React from "react";
import type { FC } from "react";

import { CourseSection } from "../../data_blocks/course_section";

import "./styles.scss";

type Props = {
  isAuthorized: boolean;
};

const SectionPageView: FC<Props> = (props: Props) => (
  <div className="section-page">
    <CourseSection {...props} />
  </div>
);

export { SectionPageView };
