import type { FC } from "react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { emailRegExp } from "../../../../../constants/general";
import { useOnChangeDataItem } from "../../../../../hooks/on_change_data_item";
import { useOnSubmitClick } from "../../../../../hooks/on_submit_click";
import { useResetFormStatus } from "../../../../../hooks/reset_form_status";
import { inviteFormRequest, useInviteFormSubmit } from "../../state/invite_user";
import type { InviteForm } from "../../types";
import { InviteFormView } from "../invite_form_view/invite_form_view";
import { InviteSuccess } from "../invite_success/invite_success";

type Props = {
    onSendInvite: () => void;
};

export const InviteFormContainer: FC<Props> = ({ onSendInvite }: Props) => {
    const [request, setRequest] = useRecoilState(inviteFormRequest);
    const inputEmail = useRef(null);
    const inputName = useRef(null);
    const defaultFormData = useMemo(
        () =>
            ({
                email: {
                    error: false,
                    errorChecker: (value) => !emailRegExp.test(value),
                    ref: inputEmail,
                    value: "",
                },
                name: {
                    error: false,
                    errorChecker: (value) => value.trim() === "",
                    ref: inputName,
                    value: "",
                },
            } as InviteForm.State),
        []
    );
    const [inviteData, setInviteData] = useState<InviteForm.State>(defaultFormData);
    const handleSend = useCallback(() => {
        onSendInvite();
        setInviteData(defaultFormData);
    }, [defaultFormData, onSendInvite]);
    const inviteFormSubmit = useInviteFormSubmit(handleSend);
    const handleChangeDataItem = useOnChangeDataItem(setInviteData);
    const handleSubmit = useOnSubmitClick(inviteData, request.status, setInviteData, inviteFormSubmit);
    useResetFormStatus(setRequest);

    const handleClick = () =>
        setRequest({
            status: "DEFAULT",
        });

    if (request.status === "SUCCESS") {
        return <InviteSuccess onClick={handleClick} />;
    }

    return (
        <InviteFormView
            data={inviteData}
            emailRef={inputEmail}
            errorMessage={request.response?.message}
            nameRef={inputName}
            onChangeDataItem={handleChangeDataItem}
            onSubmit={handleSubmit}
            showError={request.status === "ERROR"}
            showLoader={request.status === "REQUEST"}
        />
    );
};
