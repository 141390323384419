import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { ActivatePassword } from "../../data_blocks/activate_password";

import "./styles.scss";

type Props = RouteComponentProps<{ code: string; login: string }>;

export const ActivatePasswordPage: FC<Props> = (props: Props) => {
  const code = props.match.params.code;
  const login = props.match.params.login;
  return (
    <div className="activate-password-page">
      <ActivatePassword code={code} login={login} />
    </div>
  );
};
