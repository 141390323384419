import type { FC } from "react";
import React from "react";

import "./exercise_body.scss";

type Props = {
    ordinalNumber: number;
    text: string;
    topic: string | null;
};

export const ExerciseBody: FC<Props> = ({ ordinalNumber, text, topic }: Props) => (
    <>
        {topic && <p className="exercise-body__topic">{topic}</p>}
        <div className="exercise-body__text">
            <div className="exercise-body__number">{ordinalNumber}.</div>
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    </>
);
