import type { FC } from "react";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import type { SetterOrUpdater } from "recoil";
import { useRecoilState } from "recoil";

import { useOnSubmitClick } from "../../../../../hooks/on_submit_click";
import { useResetFormStatus } from "../../../../../hooks/reset_form_status";
import { LoadingButton } from "../../../../basic/loading_button/loading_button";
import {
  addContactFormRequest,
  useAddContactFormSubmit,
} from "../../state/add_contact";
import type { TeacherItemType, TeachersListType } from "../../types";

import "./teacher_item.scss";

type Props = {
  setTeachersData: SetterOrUpdater<TeachersListType>;
  teacher: TeacherItemType;
};

const TeacherItemFC: FC<Props> = ({ setTeachersData, teacher }: Props) => {
  const [request, setRequest] = useRecoilState(
    addContactFormRequest(teacher.id)
  );
  const addContactFormSubmit = useAddContactFormSubmit(
    teacher.id,
    setTeachersData
  );
  const handleSubmit = useOnSubmitClick(
    {},
    request.status,
    undefined,
    addContactFormSubmit
  );
  useResetFormStatus(setRequest);

  return (
    <div className="teacher">
      <img alt="" className="teacher__avatar" src={teacher.avatarMini} />
      <div className="teacher__info">
        <p className="teacher__title">
          {teacher.lastName} {teacher.firstName} {teacher.middleName}
        </p>
        {["DECLINED", "NO"].indexOf(teacher.relationStatus) !== -1 && (
          <LoadingButton
            loading={request.status === "REQUEST"}
            onClick={handleSubmit}
            text="Подключиться к преподавателю"
            variant="outlined"
          />
        )}
        {teacher.relationStatus === "PENDING" && (
          <LoadingButton
            disabled={true}
            text="Запрос отправлен"
            variant="outlined"
          />
        )}
        {teacher.relationStatus === "CONTACTED" && (
          <LoadingButton
            disabled={true}
            text="Вы подключены"
            variant="contained"
          />
        )}
        {teacher.relationStatus === "REQUESTED" && (
          <div>
            Принять заявку можно на{" "}
            <Link to="/contacts/">странице контактов</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export const TeacherItem = memo(TeacherItemFC);
