import type { FC } from "react";
import React from "react";
import { useRecoilValue } from "recoil";

import { currentUserDataQuery } from "../../../../../state";
import { Contacts } from "../../../../common/contacts/contacts";
import type { ChildrenProps, ContactsParams } from "../../../../common/contacts/types";
import { InviteFormContainer } from "../invite_form_container/invite_form_container";

const ContactsContent: FC<ChildrenProps> = ({ contacts, refreshContactsData }: ChildrenProps) => {
    const { response: userData } = useRecoilValue(currentUserDataQuery);
    return (
        <>
            {userData?.status === "TEACHER" && (
                <div>
                    <InviteFormContainer onSendInvite={refreshContactsData} />
                </div>
            )}
            {contacts.length > 0 && (
                <>
                    <h1>Ожидание</h1>
                    {contacts}
                </>
            )}
        </>
    );
};

const params: ContactsParams = { type: "PENDING" };

export const ContactsPending: FC = () => (
    <Contacts params={params}>
        {({ contacts, refreshContactsData }: ChildrenProps) => (
            <ContactsContent contacts={contacts} refreshContactsData={refreshContactsData} />
        )}
    </Contacts>
);
