import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { UserMatchingContainer } from "../user_matching_container/user_matching_container";

type Props = {
    userId: number;
};

export const UserMatchingSuspense: FC<Props> = ({ userId }: Props) => (
    <ErrorBoundary>
        <Suspense fallback={<CircularProgress />}>
            <UserMatchingContainer userId={userId} />
        </Suspense>
    </ErrorBoundary>
);
