import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../basic/error_boundary";
import { CourseContainer } from "./container";

import "./styles.scss";

type Props = {
  isAuthorized: boolean;
};

export const Course: FC<Props> = (props: Props) => (
  <ErrorBoundary>
    <Suspense fallback={<CircularProgress />}>
      <CourseContainer {...props} />
    </Suspense>
  </ErrorBoundary>
);
