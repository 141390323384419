import type { FC } from "react";
import React, { memo, useCallback } from "react";

import { Checkbox } from "@material-ui/core";

import type { ContactItemType } from "../../types";

import "./contact_checkbox.scss";

type Props = {
    checked?: boolean;
    contact: ContactItemType;
    name: string;
    onChange: (id: number, isChecked: boolean) => void;
};

const ContactCheckboxFC: FC<Props> = ({ checked, contact, name, onChange }: Props) => {
    const handleClick = useCallback(() => onChange(contact.id, !checked), [checked, contact.id, onChange]);

    return (
        <div className="contact-checkbox" onClick={handleClick}>
            <div className="contact-checkbox__control">
                <Checkbox checked={checked} name={name} />
                <img alt="" className="contact-checkbox__avatar" src={contact.avatarMini} />
            </div>
            <div className="contact-checkbox__title">
                {contact.lastName} {contact.firstName} {contact.middleName}
            </div>
        </div>
    );
};

export const ContactCheckbox = memo(ContactCheckboxFC);
