import React, { FC } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../basic/error_boundary";
import CourseSectionContainer from "./container";

import "./styles.scss";

type Props = {
  isAuthorized: boolean;
};

const CourseSection: FC<Props> = (props: Props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<CircularProgress />}>
      <CourseSectionContainer {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
export default CourseSection;
