import type { FC } from "react";
import React from "react";
import { ActivateDevice } from "../../data_blocks/activate_device";

type Props = {
  isAuthorized: boolean;
};

export const ActivateDevicePage: FC<Props> = (props: Props) => {
  const dfpUser = new URL(window.location.href).searchParams.get("d");

  return (
    <ActivateDevice dfpUser={dfpUser || ""} isAuthorized={props.isAuthorized} />
  );
};
