import React from "react";
import type { FC } from "react";

import { IndicatorsSuspense } from "./components/indicators_suspense/indicators_suspense";
import { MineResultsSuspense } from "./components/mine_results_suspense/mine_results_suspense";

import "./results_page.scss";

// На странице «Результаты обучения» отображаются уровень прохождения
// учебного курса, результаты выполнения тестов и упражнений (с ошибками и
// временем решения), полученные стикеры (награды за успешные выполнения
// упражнений).

// За выполнение заданий даются баллы (10, 20 или 40 в зависимости от типа задания),
// причем это гарантированные баллы, к которым можно получить надбавку от 1 до 5
// в зависимости от количества ошибок (процента выполнения). Процент тоже всегда показывается.

// Пройдено 80 %
// Выучено тем: 64
// Средний результат: 90 %
// Получено достижений: 40

export const ResultsPage: FC = () => (
    <div className="results-page">
        <h1 className="results-page__title">Результаты обучения</h1>
        <div className="results-page__block">
            <IndicatorsSuspense />
        </div>
        <div className="results-page__block">
            <MineResultsSuspense />
        </div>
    </div>
);
