import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../basic/error_boundary";
import { ContactsContainer } from "./components/contacts_container/contacts_container";
import type { ContactsContainerProps } from "./types";

export const Contacts: FC<ContactsContainerProps> = ({
    children,
    onChange,
    params,
    values,
}: ContactsContainerProps) => (
    <ErrorBoundary>
        <Suspense fallback={<CircularProgress />}>
            <ContactsContainer onChange={onChange} params={params} values={values}>
                {children}
            </ContactsContainer>
        </Suspense>
    </ErrorBoundary>
);
