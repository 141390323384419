import type { FC } from "react";
import React, { memo, useEffect } from "react";
import { useRecoilState } from "recoil";

import { currentUserDataQuery } from "../../../../../state";
import { classItemDataQuery } from "../../state/class_item";
import { ClassEditorContainer } from "../class_editor_container/class_editor_container";

type Props = {
  classId: number;
};

const ClassDataContainerFC: FC<Props> = ({ classId }: Props) => {
  const recoilState = classItemDataQuery(classId);
  const [classItemData, setClassItemData] = useRecoilState(recoilState);
  useEffect(() => () => setClassItemData(null as any), [setClassItemData]);

  const [{ response: userData }] = useRecoilState(currentUserDataQuery);

  if (userData?.status !== "TEACHER") {
    return <>Доступ закрыт</>;
  }

  return <ClassEditorContainer item={classItemData.response} />;
};

export const ClassDataContainer = memo(ClassDataContainerFC);
