import type { FC } from "react";
import React, { memo, useCallback } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button } from "@material-ui/core";

import { authorizationToken, paymentSuccessQuery } from "../../../state";

type Props = {
  invId: string;
  outSum: string;
  signatureValue: string;
} & RouteComponentProps;

const PaymentSuccessContainer: FC<Props> = ({
  history,
  invId,
  outSum,
  signatureValue,
}: Props) => {
  const paymentSuccess = useRecoilValue(
    paymentSuccessQuery({
      InvId: invId,
      OutSum: outSum,
      SignatureValue: signatureValue,
    })
  );
  const isAuthorized = !!useRecoilValue(authorizationToken);

  const handleEducationClick = useCallback(() => {
    history.push(isAuthorized ? "/education/" : "/login/");
  }, [history, isAuthorized]);

  return (
    <>
      <h2>
        {paymentSuccess?.response?.text || <>Заказ № {invId} оплачен</>} :)
      </h2>
      <Button
        color="primary"
        onClick={handleEducationClick}
        size="large"
        variant="contained"
      >
        Перейти к обучению
      </Button>
    </>
  );
};

export default memo(withRouter(PaymentSuccessContainer));
