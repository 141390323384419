import { BaseAPIUrl, localSettingsAPI } from "../../../constants/general";

export const getUrl = (
  url: string,
  params?: Record<string, string | number>
): string => {
  const paramsForUrl =
    (params &&
      "&" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")) ||
    "";
  const questionOrAnd = url.indexOf("?") === -1;
  const isBaseAPIUrl = url.indexOf("http") !== 0;
  return (
    (isBaseAPIUrl ? BaseAPIUrl : "") +
    url +
    (questionOrAnd ? "?" : "&") +
    "time=" +
    new Date().getTime() +
    paramsForUrl +
    (isBaseAPIUrl ? localSettingsAPI : "")
  );
};
