import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";

import { Title } from "../../basic/title/title";
import { UserMatchingSuspense } from "./components/user_matching_suspense/user_matching_suspense";

type Props = RouteComponentProps<{
    userId: string;
}>;

export const UsersMatchingPage: FC<Props> = (props: Props) => {
    const userId = parseInt(props.match.params.userId, 10);

    return (
        <>
            <Title backUrl="/weekly-rating/" title="Сравнение" />
            <div>
                <UserMatchingSuspense userId={userId} />
            </div>
        </>
    );
};
