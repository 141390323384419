import { atomFamily, selectorFamily } from "recoil";

import { sendRequest } from "../../../../core/api/request";
import type { ClassItemDataType, ClassItemDetailedType } from "../types";

export const requestClassItemData = async (
  classId: number
): Promise<ClassItemDataType> => {
  const data = await sendRequest({
    method: "GET",
    url: `/classes/item/${classId}/`,
  })
    .then(({ cursor, data: successData }) => {
      return {
        cursor,
        response: {
          ...successData,
          pupils: (successData as ClassItemDetailedType<string>).pupils.map(
            (v) => ({
              ...v,
              dateVisit: v.dateVisit ? new Date(v.dateVisit) : v.dateVisit,
            })
          ),
        },
        status: "SUCCESS",
      };
    })
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (data.status === "ERROR") {
    throw data;
  }
  return data;
};

const classItemDataRequestId = atomFamily({
  key: "classItemDataRequestId",
  default: 0,
});

const classItemData = atomFamily<ClassItemDataType | null, number>({
  key: "classItemData",
  default: null,
});

export const classItemDataQuery = selectorFamily<ClassItemDataType, number>({
  key: "classItemDataQuery",
  get: (classId) => async ({ get }) => {
    get(classItemDataRequestId(classId)); // Add request ID as a dependency
    const newClassItemData = get(classItemData(classId));
    if (newClassItemData !== null) {
      return newClassItemData;
    }
    return await requestClassItemData(classId);
  },
  set: (classId) => ({ set }, newValue) => {
    if (newValue === null) {
      set(classItemDataRequestId(classId), (requestId) => requestId + 1);
    }
    set(classItemData(classId), newValue);
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
