import type { FC } from "react";
import React, { useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { useRecoverFormSubmit } from "../../../../../actions/data";
import { emailRegExp } from "../../../../../constants/general";
import { useOnChangeDataItem } from "../../../../../hooks/on_change_data_item";
import { useOnSubmitClick } from "../../../../../hooks/on_submit_click";
import { useResetFormStatus } from "../../../../../hooks/reset_form_status";
import { recoverFormRequest } from "../../../../../state";
import type { RecoverForm } from "../../../../../types/main";
import { RecoverFormView } from "../recover_form_view/recover_form_view";
import { RecoverSuccess } from "../recover_success/recover_success";

export const RecoverFormContainer: FC = () => {
  const [request, setRequest] = useRecoilState(recoverFormRequest);
  const recoverFormSubmit = useRecoverFormSubmit();
  const inputEmail = useRef(null);
  const [recoverData, setRecoverData] = useState<RecoverForm.State>({
    email: {
      error: false,
      errorChecker: (value) => !emailRegExp.test(value),
      ref: inputEmail,
      value: "",
    },
  });
  const handleChangeDataItem = useOnChangeDataItem(setRecoverData);
  const handleSubmit = useOnSubmitClick(
    recoverData,
    request.status,
    setRecoverData,
    recoverFormSubmit
  );
  useResetFormStatus(setRequest);

  if (request.status === "SUCCESS") {
    return <RecoverSuccess />;
  }

  return (
    <RecoverFormView
      data={recoverData}
      emailRef={inputEmail}
      errorMessage={request.response?.message}
      onChangeDataItem={handleChangeDataItem}
      onSubmit={handleSubmit}
      showError={request.status === "ERROR"}
      showLoader={request.status === "REQUEST"}
    />
  );
};
