import type { FC } from "react";
import React, { useCallback } from "react";
import { Button, TextField } from "@material-ui/core";

import type { SearchForm } from "../../types";

import "./teacher_search.scss";

type Props = {
  data: SearchForm.State;
  disabled: boolean;
  onChangeDataItem: (fieldName: SearchForm.FieldName, value: any) => void;
  onSubmit: () => void;
  searchRef: any;
};

export const TeacherSearch: FC<Props> = ({
  data,
  disabled,
  onChangeDataItem,
  onSubmit,
  searchRef,
}: Props) => {
  const handleChangeValue = useCallback(
    (fieldName: SearchForm.FieldName, eventFieldName = "value") => (
      event: any
    ) => onChangeDataItem(fieldName, event.target[eventFieldName]),
    [onChangeDataItem]
  );
  const handleSubmitForm = useCallback((event?: any) => onSubmit(), [onSubmit]);
  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        handleSubmitForm();
      }
    },
    [handleSubmitForm]
  );

  return (
    <div className="teacher-search">
      <TextField
        autoFocus={true}
        className="teacher-search__field"
        color="secondary"
        // disabled={disabled}
        id="outlined-basic"
        inputRef={searchRef}
        label="Поиск"
        onChange={handleChangeValue("search")}
        onKeyPress={handleKeyPress}
        variant="outlined"
        value={data.search.value}
      />
      <div className="teacher-search__button">
        <Button
          color="primary"
          disabled={disabled}
          onClick={handleSubmitForm}
          size="large"
          variant="contained"
        >
          Найти
        </Button>
      </div>
    </div>
  );
};
