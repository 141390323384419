import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";

import { MineResultsSuspense } from "./components/mine_results_topic_suspense/mine_results_topic_suspense";

type Props = RouteComponentProps<{
    topicId: string;
}>;

export const ResultsTopicPage: FC<Props> = (props: Props) => {
    const topicId = parseInt(props.match.params.topicId, 10);

    return <MineResultsSuspense topicId={topicId} />;
};
