import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { ClassTopicContainer } from "../class_topic_container/class_topic_container";

type Props = {
  classId: number;
  topicId: number;
};

export const ClassTopicSuspense: FC<Props> = ({ classId, topicId }: Props) => (
  <ErrorBoundary>
    <Suspense fallback={<CircularProgress />}>
      <ClassTopicContainer classId={classId} topicId={topicId} />
    </Suspense>
  </ErrorBoundary>
);
