import * as React from "react";

const LogoSmall = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 42" {...props}>
      <g
        id="Слой_2"
        data-name="Слой 2"
        transform="translate(187 -0.001) rotate(90)"
      >
        <g id="Слой_1" data-name="Слой 1" transform="translate(0.001)">
          <path
            id="Path_683"
            data-name="Path 683"
            d="M23.931,142.35h0l.984.144.964.205a20.668,20.668,0,0,1,4.8,1.705h.083a20.2,20.2,0,0,1,8.8,27.13v.082h0a20.19,20.19,0,0,1-5.741,6.77,20.5,20.5,0,0,1-21.6,1.952h-.093a20.081,20.081,0,0,1-8.85-27.048h0c.145-.288.28-.544.4-.76h0c.187-.339.487-.822.57-.955,1.1,2.055,5.751,4.695,9.607,5.537l-.508.832h0c-.093.144-.155.277-.207.36h0a9.135,9.135,0,0,0,4.031,12.327h.093a9.226,9.226,0,0,0,11.192-2.3,8.9,8.9,0,0,0,1.15-1.736s0,0,0-.082h0a9.1,9.1,0,0,0-.912-9.646,8.735,8.735,0,0,0-1.4-1.438,9.334,9.334,0,0,0-1.751-1.14h-.083a8.882,8.882,0,0,0-2.073-.729l-.446-.082-.456-.072h0l1.368-10.951"
            transform="translate(0.038 3.88)"
            fill="#2b2a29"
          />
          <path
            id="Path_684"
            data-name="Path 684"
            d="M37.263,146.081a34.847,34.847,0,0,0-9.461-5.537c.383-.329,1.648-1.88,1.689-1.931a26.594,26.594,0,0,0,1.171-2.537h0A9.419,9.419,0,0,0,29.7,129a9.251,9.251,0,0,0-5.534-4.345h0a10.018,10.018,0,0,0-2.073-.329,8.967,8.967,0,0,0-2.073.134A9.3,9.3,0,0,0,12.672,131h0a9.225,9.225,0,0,0-.207,4.058,9.274,9.274,0,0,0,6.581,7.283H19.1a1.637,1.637,0,0,0,.238.072h0c.145,0-.176-.051.2,0a9.017,9.017,0,0,0,1.14.185l-1.1,10.971a18.475,18.475,0,0,1-2.487-.4l-.487-.123h0l-.456-.113h0A20.272,20.272,0,0,1,1.5,136.918,19.771,19.771,0,0,1,1.967,128h0a20.372,20.372,0,0,1,20.6-14.71,20.79,20.79,0,0,1,4.529.729h0a20.184,20.184,0,0,1,14.094,24.921h0a20.448,20.448,0,0,1-1.979,4.643l-.508.853h0c-.3.462-.487.729-.539.8-.187.318-.746.514-.933.832"
            transform="translate(0.042 3.087)"
            fill="#2b2a29"
          />
          <path
            id="Path_685"
            data-name="Path 685"
            d="M22.861,142.91h0l.984.144.964.205a20.669,20.669,0,0,1,4.8,1.705h.083a20.2,20.2,0,0,1,8.8,27.13.366.366,0,0,0,0,.092h0a20.19,20.19,0,0,1-5.772,6.77,20.5,20.5,0,0,1-21.6,1.952h-.093a20.081,20.081,0,0,1-8.819-27.048h0c.145-.288.29-.544.4-.76h0c.187-.339.487-.822.57-.955,1.1,2.055,5.751,4.695,9.607,5.547l-.508.822h0l-.207.36h0A9.135,9.135,0,0,0,16.1,171.2H16.2a9.332,9.332,0,0,0,9.741-.914,9.569,9.569,0,0,0,1.451-1.376,9.309,9.309,0,0,0,1.15-1.746v-.082h0a9.1,9.1,0,0,0-.912-9.646,8.734,8.734,0,0,0-1.4-1.438,9.335,9.335,0,0,0-1.751-1.14h-.083a8.88,8.88,0,0,0-2.073-.729l-.446-.082-.456-.062h0l1.368-10.951"
            transform="translate(-0.001 3.895)"
            fill="#ff8611"
          />
          <path
            id="Path_686"
            data-name="Path 686"
            d="M36.183,146.641a35.116,35.116,0,0,0-9.461-5.537c.383-.329,1.648-1.88,1.689-1.931a26.593,26.593,0,0,0,1.171-2.568h0a9.44,9.44,0,0,0-.964-7.088,9.251,9.251,0,0,0-5.534-4.345h0a10.019,10.019,0,0,0-2.073-.329,9.606,9.606,0,0,0-2.073.134,9.3,9.3,0,0,0-7.337,6.492h0a9.171,9.171,0,0,0-.332,2.055,9.39,9.39,0,0,0,.124,2.055,9.274,9.274,0,0,0,6.581,7.273h.052a1.817,1.817,0,0,1,.238.072h.2q.565.126,1.14.2l-1.1,10.961a18.47,18.47,0,0,1-2.487-.4l-.487-.123h0l-.456-.113h0A20.273,20.273,0,0,1,.431,137.478,19.771,19.771,0,0,1,.9,128.561h0a20.351,20.351,0,0,1,16.135-14.443,20.693,20.693,0,0,1,9,.462h0a20.174,20.174,0,0,1,14.094,24.911h0a20.448,20.448,0,0,1-1.979,4.643l-.508.853h0l-.539.812c-.187.308-.746.514-.933.822"
            transform="translate(0.003 3.103)"
            fill="#aadb16"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoSmall;
