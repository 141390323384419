import type { FC } from "react";
import React from "react";
import type { RecoilState } from "recoil";
import { useRecoilState } from "recoil";
import type { ButtonProps } from "@material-ui/core";

import { LoadingButton } from "../../../../basic/loading_button/loading_button";
import { useOnSubmitClick } from "../../../../../hooks/on_submit_click";
import { useResetFormStatus } from "../../../../../hooks/reset_form_status";
import type { BodyData, Form } from "../../../../../types/main";

type Props = {
    onSubmit: (bodyData: BodyData) => Promise<void>;
    recoilState: RecoilState<Form.Request>;
    text: string;
    variant: ButtonProps["variant"];
};

export const ContactButton: FC<Props> = ({ onSubmit, recoilState, text, variant }: Props) => {
    const [request, setRequest] = useRecoilState(recoilState);
    const handleSubmit = useOnSubmitClick({}, request.status, undefined, onSubmit);
    useResetFormStatus(setRequest);

    return (
        <LoadingButton loading={request.status === "REQUEST"} onClick={handleSubmit} text={text} variant={variant} />
    );
};
