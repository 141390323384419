import type { FC } from "react";
import React from "react";
import { ProfileSuspense } from "./components/profile_suspense/profile_suspense";

export const ProfilePage: FC = () => (
  <div>
    <h1>Настройки профиля</h1>
    <ProfileSuspense />
  </div>
);
