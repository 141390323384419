import type { FC, PropsWithChildren } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { ActivateUserContainer } from "../activate_user_container/activate_user_container";

type Props = {
  code: string;
  login: string;
};

export const ActivateUserSuspense: FC<Props> = (
  props: PropsWithChildren<Props>
) => (
  <ErrorBoundary>
    <Suspense fallback={<CircularProgress />}>
      <ActivateUserContainer code={props.code} login={props.login} />
    </Suspense>
  </ErrorBoundary>
);
