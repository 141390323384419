import type { FC } from "react";
import React, { useCallback } from "react";
import { FormControl, InputLabel, Select, TextField } from "@material-ui/core";

import { errorColors, successColors } from "../../../../../constants/general";
import { LoadingButton } from "../../../../basic/loading_button/loading_button";
import type { ClassEditor } from "../../types";
import { Pupils } from "../pupils/pupils";

import "./class_editor_view.scss";

type Props = {
  buttonTitle: string;
  classRef: any;
  data: ClassEditor.State;
  errorMessage?: string | JSX.Element;
  onChangeDataItem: (fieldName: ClassEditor.FieldName, value: any) => void;
  onSubmit: () => void;
  showError: boolean;
  showLoader: boolean;
  showSuccess: boolean;
  successMessage?: string | JSX.Element;
  titleRef: any;
};

export const ClassEditorView: FC<Props> = ({
  buttonTitle,
  classRef,
  data,
  errorMessage,
  onChangeDataItem,
  onSubmit,
  showError,
  showLoader,
  showSuccess,
  successMessage,
  titleRef,
}: Props) => {
  const handleChangeValue = useCallback(
    (fieldName: ClassEditor.FieldName, eventFieldName = "value") => (
      event: any
    ) => onChangeDataItem(fieldName, event.target[eventFieldName]),
    [onChangeDataItem]
  );
  const handleSubmitForm = useCallback((event?: any) => onSubmit(), [onSubmit]);
  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        handleSubmitForm();
      }
    },
    [handleSubmitForm]
  );

  const handleChange = useCallback(
    (values: number[]) => {
      onChangeDataItem("pupilsIds", values);
    },
    [onChangeDataItem]
  );

  return (
    <div className="class-editor">
      <div className="class-editor__row">
        <FormControl
          className="profile-form__field"
          disabled={showLoader}
          variant="outlined"
        >
          <InputLabel id="class-label">Параллель</InputLabel>
          <Select
            id="class"
            inputRef={classRef}
            label="Параллель"
            labelId="class-label"
            native={true}
            onChange={handleChangeValue("classId")}
            value={data.classId.value}
          >
            <option value={0}>— не указана —</option>
            <option value={1}>1 класс</option>
            <option value={2}>2 класс</option>
            <option value={3}>3 класс</option>
            <option value={4}>4 класс</option>
            <option value={5}>5 класс</option>
            <option value={6}>6 класс</option>
            <option value={7}>7 класс</option>
            <option value={8}>8 класс</option>
            <option value={9}>9 класс</option>
            <option value={10}>10 класс</option>
            <option value={11}>11 класс</option>
          </Select>
        </FormControl>
      </div>
      <div className="class-editor__row">
        <TextField
          autoFocus={true}
          className="class-editor__field"
          color="secondary"
          disabled={showLoader}
          error={data.title.error}
          id="outlined-basic"
          inputRef={titleRef}
          label="Название класса"
          onChange={handleChangeValue("title")}
          onKeyPress={handleKeyPress}
          value={data.title.value}
          variant="outlined"
        />
      </div>
      <div className="class-editor__row">
        <Pupils onChange={handleChange} values={data.pupilsIds.value} />
      </div>
      <div className="class-editor__row">
        <LoadingButton
          loading={showLoader}
          onClick={handleSubmitForm}
          size="large"
          text={buttonTitle}
          variant="contained"
        />
      </div>
      {showError && (
        <div className="class-editor__row" style={{ color: errorColors.main }}>
          {errorMessage}
        </div>
      )}
      {showSuccess && (
        <div
          className="class-editor__row"
          style={{ color: successColors.main }}
        >
          {successMessage}
        </div>
      )}
    </div>
  );
};
