import React, { FC } from "react";

import { CourseContent } from "../../data_blocks/course_content";

import "./styles.scss";

type Props = {
  isAuthorized: boolean;
};

const ContentPageView: FC<any> = (props: Props) => (
  <div className="content-page">
    <CourseContent {...props} />
  </div>
);

export { ContentPageView };
