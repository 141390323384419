import type { FC } from "react";
import React, { memo } from "react";
import { useRecoilValue } from "recoil";

import { courseDataQuery } from "../../../state";
import SectionRowView from "./section_row_view";

type Props = {
  isAuthorized: boolean;
};

const CourseContainerFC: FC<Props> = (props: Props) => {
  const { response: courseData } = useRecoilValue(courseDataQuery);

  return (
    <div className="course">
      {courseData?.sections.map((s, i) => (
        <SectionRowView
          key={`section_${s.id}`}
          payUrl={props.isAuthorized ? "/pay/" : "/signup/"}
          showPayButton={!s.isAccessed}
          text={s.text}
          title={`${i + 1}. ${s.title}`}
          url={props.isAuthorized ? `/section/${s.id}/` : "/signup/"}
        />
      ))}
    </div>
  );
};

export const CourseContainer = memo(CourseContainerFC);
