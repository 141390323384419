import type { FC } from "react";
import React, { Suspense, useCallback, useRef, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { CircularProgress } from "@material-ui/core";

import { useOnChangeDataItem } from "../../../../../hooks/on_change_data_item";
import { useOnSubmitClick } from "../../../../../hooks/on_submit_click";
import type { BodyData } from "../../../../../types/main.d";
import ErrorBoundary from "../../../../basic/error_boundary";
import { PAGE_SIZE } from "../../constants";
import { teachersDataQuery } from "../../state/teachers";
import { TeachersContainer } from "../teachers_container/teachers_container";
import { TeacherSearch } from "../teacher_search/teacher_search";
import type { SearchForm } from "../../types";

export const TeachersSuspense: FC = () => {
  const inputSearch = useRef(null);
  const [search, setSearch] = useState<string>("");
  const [recoverData, setRecoverData] = useState<SearchForm.State>({
    search: {
      error: false,
      ref: inputSearch,
      value: "",
    },
  });

  const teachersDataLoadable = useRecoilValueLoadable(
    teachersDataQuery({ limit: PAGE_SIZE, offset: 0, search })
  );

  const searchFormSubmit = useCallback((dataForSubmit: BodyData) => {
    setSearch(dataForSubmit.search as string);
  }, []);
  const handleChangeDataItem = useOnChangeDataItem(setRecoverData);
  const handleSubmit = useOnSubmitClick(
    recoverData,
    teachersDataLoadable.state === "loading" ? "REQUEST" : "DEFAULT",
    setRecoverData,
    searchFormSubmit
  );

  return (
    <div>
      <TeacherSearch
        data={recoverData}
        disabled={teachersDataLoadable.state === "loading"}
        onChangeDataItem={handleChangeDataItem}
        onSubmit={handleSubmit}
        searchRef={inputSearch}
      />
      <ErrorBoundary>
        <Suspense fallback={<CircularProgress />}>
          <TeachersContainer search={search} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
