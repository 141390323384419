import type { FC } from "react";
import React from "react";

export const RequisitesPage: FC = () => (
  <>
    <h1>Реквизиты</h1>
    <p>
      Полное официальное наименование предприятия: Общество с ограниченной
      ответственностью «Современное образование»
    </p>
    <p>Сокращенное наименование предприятия: ООО «Современное образование»</p>
    <p>Директор: Вахрин Андрей Вадимович</p>
    <p>
      ИНН: 5906168428
      <br />
      КПП: 590201001
      <br />
      ОГРН: 1205900027046
      <br />
      Номер расчетного счета: 40702810444420000844
      <br />
      Наименование учреждения банка: Филиал «Центральный» Банка ВТБ (ПАО)
      <br />
      Местонахождение учреждения банка: г. Москва
      <br />
      Корреспондентский счет банка: 30101810145250000411
      <br />
      БИК: 044525411
    </p>
    <p>
      Юридический адрес: 614000, г. Пермь, ул. Николая Островского, дом 30, этаж
      2
      <br />
      Телефон (факс): 8 (952) 319 34 00
      <br />
      <a href="mailto:so.perm@edu-modern.ru">so.perm@edu-modern.ru</a>
      <br />
      <a href="mailto:help@rus-na5.ru">help@rus-na5.ru</a>
    </p>
  </>
);
