import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";

import { useFormRequest } from "../../../../actions/data";
import type { BodyData, Form } from "../../../../types/main.d";

export const classEditorRequest = atom<Form.Request>({
  key: "classEditorRequest",
  default: {
    status: "DEFAULT",
  },
});

export const useClassEditorSubmit = ({
  id,
  onSuccess,
}: {
  id?: number;
  onSuccess: (successData: any) => void;
}) => {
  const setFormRequest = useSetRecoilState(classEditorRequest);
  const classEditorSubmit = useFormRequest({
    method: id ? "PUT" : "POST",
    setFormRequest,
    url: id ? `/classes/item/${id}/` : "/classes/item/",
  });
  return useCallback(
    (bodyData: BodyData) => classEditorSubmit(bodyData).then(onSuccess),
    [classEditorSubmit, onSuccess]
  );
};
