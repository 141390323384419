import { format, intervalToDuration } from "date-fns";
import plural from "plural-ru";
import { atomFamily, selectorFamily } from "recoil";

import { LRS } from "../../../../constants/general";
import { sendRequest } from "../../../../core/api/request";
import type { ResultsUsersAttemptsDataType, UserTopicDataType } from "../types";
import { requestUserData } from "./user";

const getDurationText = (milliseconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: milliseconds,
  });

  if (duration.days || duration.weeks || duration.months || duration.years) {
    return `за дольше, чем 24 часа`;
  }

  const label: string[] = [];

  if (duration.hours) {
    label.push(plural(duration.hours, "%d час", "%d часа", "%d часов"));
  }
  if (duration.minutes) {
    label.push(plural(duration.minutes, "%d минуту", "%d минуты", "%d минут"));
  }
  if (duration.seconds) {
    label.push(
      plural(duration.seconds, "%d секунду", "%d секунды", "%d секунд")
    );
  }

  if (label.length === 0) {
    return "";
  }

  if (label.length === 1) {
    return "за " + label[0];
  }

  const end = label.pop();
  return "за " + label.join(" ") + " и " + end;
};

type Params = {
  topicId: number;
  userId: number;
};

const requestUserTopicData = async ({
  topicId,
  userId,
}: Params): Promise<UserTopicDataType> => {
  const userData = await requestUserData(userId);
  const resultsData = await sendRequest({
    bodyData: {
      topicsIds: [topicId],
      usersIds: [userId],
    },
    method: "POST",
    url: `${LRS.endpoint}results/users/attempts/`,
  })
    .then(
      ({
        cursor,
        data: successData,
      }: {
        cursor: string | null;
        data: ResultsUsersAttemptsDataType;
      }) => ({
        cursor,
        response: {
          user: userData.response,
          results: {
            ...successData,
            attemptsByActivityIdUserId: Object.keys(
              successData.attemptsByActivityIdUserId
            ).reduce(
              (acc, cur) => ({
                ...acc,
                [cur]: successData.attemptsByActivityIdUserId[cur].map((v) => {
                  const timestamp = new Date(v.timestamp);
                  const resultScoreScaledLabel = v.resultScoreScaled
                    ? `${Math.round(v.resultScoreScaled * 100)} %`
                    : "";
                  const labelSecondary = (
                    resultScoreScaledLabel +
                    " " +
                    getDurationText(v.resultDurationMilliseconds)
                  ).trim();
                  return {
                    ...v,
                    label:
                      v.label ??
                      `${format(timestamp, "dd.MM.yyyy в HH:mm")} (${
                        labelSecondary === "" ? "—" : labelSecondary
                      })`,
                    timestamp,
                  };
                }),
              }),
              {} as UserTopicDataType["response"]["results"]["attemptsByActivityIdUserId"]
            ),
          },
        },
        status: "SUCCESS",
      })
    )
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (resultsData.status === "ERROR") {
    throw resultsData;
  }
  return resultsData;
};

const userTopicDataRequestId = atomFamily({
  key: "userTopicDataRequestId",
  default: 0,
});

const userTopicData = atomFamily<UserTopicDataType | null, Params>({
  key: "userTopicData",
  default: null,
});

export const userTopicDataQuery = selectorFamily<UserTopicDataType, Params>({
  key: "userTopicDataQuery",
  get: (params) => async ({ get }) => {
    get(userTopicDataRequestId(params)); // Add request ID as a dependency
    const newUserTopicData = get(userTopicData(params));
    if (newUserTopicData !== null) {
      return newUserTopicData;
    }
    return await requestUserTopicData(params);
  },
  set: (params) => ({ set }, newValue) => {
    if (newValue === null) {
      set(userTopicDataRequestId(params), (requestId) => requestId + 1);
    }
    set(userTopicData(params), newValue);
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
