import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { MineResults } from "../mine_results/mine_results";

export const MineResultsSuspense: FC = () => (
    <ErrorBoundary>
        <Suspense fallback={<CircularProgress />}>
            <MineResults />
        </Suspense>
    </ErrorBoundary>
);
