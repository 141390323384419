import type { FC } from "react";
import React, { memo, useEffect } from "react";
import { useRecoilState } from "recoil";

import { currentUserDataQuery } from "../../../../../state";
import { IndicatorsSuspense } from "../../../results/components/indicators_suspense/indicators_suspense";
import { userMatchingDataQuery } from "../../state/user_matching";
import { UserMatchingCharts } from "../user_matching_charts/user_matching_charts";

import "./user_matching_container.scss";

type Props = {
    userId: number;
};

const UserMatchingContainerFC: FC<Props> = ({ userId }: Props) => {
    const [mineResultsData, setMineResultsData] = useRecoilState(userMatchingDataQuery(0));
    const [userResultsData, setUserResultsData] = useRecoilState(userMatchingDataQuery(userId));
    useEffect(
        () => () => {
            setMineResultsData(null as any);
            setUserResultsData(null as any);
        },
        [setMineResultsData, setUserResultsData]
    );

    const { analytics: mineAnalytics } = mineResultsData.response;
    const { analytics, user } = userResultsData.response;

    const [{ response: userData }] = useRecoilState(currentUserDataQuery);

    if (!userData || !user) {
        return <>Доступ закрыт</>;
    }

    const userTitle1 = `${user.lastName ?? ""} ${user.firstName ?? ""} ${user.middleName ?? ""}`;
    const userTitle2 = `${userData.lastName ?? ""} ${userData.firstName ?? ""} ${userData.middleName ?? ""}`;

    return (
        <>
            <div className="user-matching__info">
                <img alt="" className="user-matching__avatar" src={user.avatar} />
                <h3>{userTitle1}</h3>
            </div>
            <h3>Опыт за неделю</h3>
            <UserMatchingCharts
                userAnalytics1={analytics}
                userAnalytics2={mineAnalytics}
                userTitle1={userTitle1}
                userTitle2={userTitle2}
            />
            <h3>Статистика</h3>
            <IndicatorsSuspense userId={userId} />
        </>
    );
};

export const UserMatchingContainer = memo(UserMatchingContainerFC);
