import { useCallback } from "react";
import type { SetterOrUpdater } from "recoil";
import { atomFamily, useSetRecoilState } from "recoil";

import { useFormRequest } from "../../../../actions/data";
import type { BodyData, Form } from "../../../../types/main.d";
import type { TeachersListType } from "../types";

export const addContactFormRequest = atomFamily<Form.Request, number>({
  key: "addContactFormRequest",
  default: {
    status: "DEFAULT",
  },
});

export const useAddContactFormSubmit = (
  userId: number,
  setTeachersData: SetterOrUpdater<TeachersListType>
) => {
  const setFormRequest = useSetRecoilState(addContactFormRequest(userId));
  const addContactFormSubmit = useFormRequest({
    setFormRequest,
    url: `/users/addcontact/${userId}/`,
  });
  return useCallback(
    (bodyData: BodyData) =>
      addContactFormSubmit(bodyData).then((successData) =>
        setTeachersData((currVal) => ({
          ...currVal,
          response: currVal.response.map((v) =>
            v.id === userId
              ? {
                  ...v,
                  ...successData,
                }
              : v
          ),
        }))
      ),
    [addContactFormSubmit, setTeachersData, userId]
  );
};
