import type { FC } from "react";
import React from "react";
import { Button } from "@material-ui/core";

import "./class_editor_success.scss";

type Props = {
  onClick: () => void;
};

export const ClassEditorSuccess: FC<Props> = ({ onClick }: Props) => (
  <div className="class-editor-success">
    <h1>Класс добавлен!</h1>
    <p>
      <Button
        color="primary"
        onClick={onClick}
        size="large"
        variant="contained"
      >
        Добавить ещё
      </Button>
    </p>
  </div>
);
