import type { FC } from "react";
import React, { memo } from "react";
import { useRecoilValue } from "recoil";

import { ButtonLink } from "../../../../basic/button_link";
import { activateUserQuery } from "../../state/activate_user";
import { UserProfile } from "../user_profile/user_profile";

type Props = {
  code: string;
  login: string;
};

const ActivateUserContainerFC: FC<Props> = ({ code, login }: Props) => {
  const { response: data } = useRecoilValue(activateUserQuery({ code, login }));

  if (data?.userData !== undefined) {
    return (
      <>
        <h1>Активация аккаунта</h1>
        <UserProfile
          affiliateUserData={data?.affiliateUserData}
          code={code}
          login={login}
          userData={data?.userData}
        />
      </>
    );
  }

  return (
    <>
      <h2>Спасибо за регистрацию!</h2>
      <p>Ваш аккаунт активирован.</p>
      <ButtonLink href="/login/" size="large">
        Войти
      </ButtonLink>
    </>
  );
};

export const ActivateUserContainer = memo(ActivateUserContainerFC);
