import type { FC } from "react";
import React, { memo } from "react";
import type { SetterOrUpdater } from "recoil";

import { LoadingButton } from "../../../../basic/loading_button/loading_button";
import { confirmContactFormRequest, useConfirmContactFormSubmit } from "../../state/confirm_contact";
import { declineContactFormRequest, useDeclineContactFormSubmit } from "../../state/decline_contact";
import type { ContactItemType, ContactsListType } from "../../types";
import { ContactButton } from "../contact_button/contact_button";

import "./contact_item.scss";

type Props = {
    contact: ContactItemType;
    setContactsData: SetterOrUpdater<ContactsListType>;
    showContactedStatus?: boolean;
};

const ContactItemFC: FC<Props> = ({ contact, setContactsData, showContactedStatus }: Props) => {
    const confirmContactFormSubmit = useConfirmContactFormSubmit(contact.id, setContactsData);
    const declineContactFormSubmit = useDeclineContactFormSubmit(contact.id, setContactsData);

    return (
        <div className="contact">
            <img alt="" className="contact__avatar" src={contact.avatarMini} />
            <div className="contact__info">
                <p className="contact__title">
                    {contact.lastName} {contact.firstName} {contact.middleName}
                </p>
                {contact.relationStatus === "REQUESTED" && (
                    <div className="contact__buttons">
                        <ContactButton
                            onSubmit={confirmContactFormSubmit}
                            recoilState={confirmContactFormRequest(contact.id)}
                            text="Принять"
                            variant="contained"
                        />
                        <ContactButton
                            onSubmit={declineContactFormSubmit}
                            recoilState={declineContactFormRequest(contact.id)}
                            text="Отклонить"
                            variant="outlined"
                        />
                    </div>
                )}
                {contact.relationStatus === "PENDING" && (
                    <LoadingButton disabled={true} text="Запрос отправлен" variant="outlined" />
                )}
                {contact.relationStatus === "DECLINED" && (
                    <LoadingButton disabled={true} text="Заявка отклонена" variant="outlined" />
                )}
                {showContactedStatus && contact.relationStatus === "CONTACTED" && (
                    <LoadingButton disabled={true} text="Заявка принята" variant="outlined" />
                )}
            </div>
        </div>
    );
};

export const ContactItem = memo(ContactItemFC);
