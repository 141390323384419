import React from "react";
import type { FC } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

export const Footer: FC = () => (
    <div className="main-footer">
        <div className="main-footer__container">
            <div className="main-footer__social">
                <a href="https://vk.com/so.perm" rel="noreferrer" target="_blank">
                    ВКонтакте
                </a>
                <a href="https://www.youtube.com/channel/UCfdc98vuxBg1Lk-H-z_-xxA" rel="noreferrer" target="_blank">
                    Youtube
                </a>
                <a href="https://t.me/so_ege_perm" rel="noreferrer" target="_blank">
                    Telegram
                </a>
            </div>
            <div className="main-footer__contacts">
                <span>+7 (342) 255 44 99</span>
                <br />
                <a href="mailto:help@rus-na5.ru">help@rus-na5.ru</a>
                <br />
                <span>614000, Пермь, ул. Николая Островского, 30</span>
                <br />
                ООО «Современное образование»
                <br />
                <Link to="/about/">Сведения об образовательной организации</Link>
                <br />
                <Link to="/requisites/">Реквизиты</Link>
            </div>
        </div>
    </div>
);
