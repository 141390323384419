import type { FC } from "react";
import React, { Fragment, memo, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { currentUserDataQuery } from "../../../../../state";
import { Title } from "../../../../basic/title/title";
import { ClassResultsCharts } from "../../../classes/components/class_results_charts/class_results_charts";
import { userResultsDataQuery } from "../../state/user_results";

import "./user_results_container.scss";

type Props = {
    mineResults?: boolean;
    userId: number;
};

const UserResultsContainerFC: FC<Props> = ({ mineResults, userId }: Props) => {
    const recoilState = userResultsDataQuery(userId);
    const [userResultsData, setUserResultsData] = useRecoilState(recoilState);
    useEffect(() => () => setUserResultsData(null as any), [setUserResultsData]);

    const [view, setView] = useState<"table" | "charts">("table");
    const handleView = useCallback((_, value) => setView(value), []);

    const { course, results, user } = userResultsData.response;

    const [{ response: userData }] = useRecoilState(currentUserDataQuery);

    const isMine = userData?.id === userId;
    if (userData?.status !== "TEACHER" && !isMine) {
        return <>Доступ закрыт</>;
    }

    const title = `${user.lastName ?? ""} ${user.firstName ?? ""} ${user.middleName ?? ""}`;

    return (
        <>
            {!mineResults && <Title backUrl="/classes/" title={title.trim() === "" ? "Результаты ученика" : title} />}
            <div className="user-results__tabs">
                <ToggleButtonGroup exclusive onChange={handleView} size="small" value={view}>
                    <ToggleButton value="table">Таблица</ToggleButton>
                    <ToggleButton value="charts">Графики</ToggleButton>
                </ToggleButtonGroup>
            </div>
            {view === "table" && (
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Результат,&nbsp;%</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {course?.sections?.map((s) => (
                            <Fragment key={`section_id_${s.id}`}>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <div style={{ height: "30px" }} />
                                        <strong>{s.title}</strong>
                                    </TableCell>
                                </TableRow>
                                {s.topics.map((t: any, index: number) => {
                                    const averageByTopic = results.averagesByTopicIdsUserIds[`${t.id}_${userId}`];
                                    return (
                                        <Fragment key={`topic_id_${t.id}`}>
                                            <TableRow style={{ background: "#eee" }}>
                                                <TableCell>
                                                    {index + 1}.{" "}
                                                    {mineResults ? (
                                                        <Link to={`/results/topic/${t.id}/`}>{t.title}</Link>
                                                    ) : (
                                                        <Link to={`/users/topic/${userId}/${t.id}/`}>{t.title}</Link>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <strong>
                                                        {averageByTopic != null ? (
                                                            Math.floor(averageByTopic * 100)
                                                        ) : (
                                                            <>&ndash;</>
                                                        )}
                                                    </strong>
                                                </TableCell>
                                            </TableRow>
                                            {Object.keys(results.resultsByActivityIdUserId)
                                                .filter(
                                                    (k) =>
                                                        results.topics[t.id.toString()]?.activityIds.indexOf(k) !== -1
                                                )
                                                .map((k) => {
                                                    const averageByActivity =
                                                        results.resultsByActivityIdUserId[k].averages[userId];
                                                    return (
                                                        <TableRow key={`topic_id_${t.id}_content_id_${k}`}>
                                                            <TableCell>
                                                                <span style={{ paddingLeft: "40px" }}>
                                                                    {results.resultsByActivityIdUserId[k].title}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <strong>
                                                                    {averageByActivity != null ? (
                                                                        Math.floor(averageByActivity * 100)
                                                                    ) : (
                                                                        <>&ndash;</>
                                                                    )}
                                                                </strong>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </Fragment>
                                    );
                                })}
                            </Fragment>
                        ))}
                    </TableBody>
                </Table>
            )}
            {view === "charts" && <ClassResultsCharts course={course} results={results} />}
        </>
    );
};

export const UserResultsContainer = memo(UserResultsContainerFC);
