import type { FC } from "react";
import React, { memo } from "react";
import { useRecoilState } from "recoil";
import type { RouteComponentProps } from "react-router-dom";

import { currentUserDataQuery } from "../../../../../state";
import { UserTopicSuspense } from "../../../users/components/user_topic_suspense/user_topic_suspense";

type Props = {
    topicId: number;
};

const MineResultsTopicFC: FC<Props> = ({ topicId }: Props) => {
    const [{ response: userData }] = useRecoilState(currentUserDataQuery);

    return userData ? <UserTopicSuspense mineResults topicId={topicId} userId={userData.id} /> : null;
};

export const MineResultsTopic = memo(MineResultsTopicFC);
