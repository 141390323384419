import type { FC } from "react";
import React, { memo, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, CircularProgress } from "@material-ui/core";

import type { Form } from "../../../../../types/main.d";
import { teachersDataQuery, requestTeachersData } from "../../state/teachers";
import { TeacherItem } from "../teacher_item/teacher_item";
import { PAGE_SIZE } from "../../constants";

import "./teachers_container.scss";

type Props = {
    search: string;
};

const TeachersContainerFC: FC<Props> = ({ search }: Props) => {
    const [offset, setOffset] = useState(0);
    useEffect(() => setOffset(0), [search]);

    const [teachersData, setTeachersData] = useRecoilState(teachersDataQuery({ limit: PAGE_SIZE, offset: 0, search }));
    useEffect(() => () => setTeachersData(null as any), [setTeachersData]);

    const [request, setRequest] = useState<Form.RequestStatus>("DEFAULT");
    const handleLoadMore = () => {
        if (request === "REQUEST") {
            return;
        }
        const nextOffset = offset + PAGE_SIZE;
        setRequest("REQUEST");
        requestTeachersData({
            limit: PAGE_SIZE,
            offset: nextOffset,
            search,
        })
            .then((data) => {
                setRequest("SUCCESS");
                setOffset(nextOffset);
                setTeachersData({
                    ...teachersData,
                    cursor: data.cursor,
                    response: [...teachersData.response, ...data.response],
                });
            })
            .catch(() => {
                setRequest("ERROR");
            });
    };

    if (teachersData.response.length === 0) {
        return <>Преподаватели не найдены</>;
    }

    return (
        <>
            {teachersData.response?.map((teacher) => (
                <TeacherItem key={`teacher_${teacher.id}`} setTeachersData={setTeachersData} teacher={teacher} />
            ))}
            {teachersData.cursor && (
                <div className="teachers-load-more">
                    <Button
                        color="primary"
                        disabled={request === "REQUEST"}
                        onClick={handleLoadMore}
                        size="large"
                        variant="contained"
                    >
                        Загрузить ещё
                    </Button>
                    {request === "REQUEST" && <CircularProgress size={24} className="teachers-load-more__progress" />}
                </div>
            )}
        </>
    );
};

export const TeachersContainer = memo(TeachersContainerFC);
