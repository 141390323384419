import React from "react";
import type { FC } from "react";

import { WeeklyRatingSuspense } from "./components/weekly_rating_suspense/weekly_rating_suspense";

export const WeeklyRatingPage: FC = () => (
    <div>
        <h1>Недельный рейтинг</h1>
        <div>
            <WeeklyRatingSuspense />
        </div>
    </div>
);
