import React from "react";
import type { FC } from "react";
import { SignUpForm } from "../../data_blocks/sign_up_form";

import "./styles.scss";

export const SignUpPageView: FC = () => (
  <div className="sign-up-page">
    <SignUpForm />
  </div>
);
