import type { FC } from "react";
import React from "react";
import { PasswordForm } from "../../data_blocks/password_form";

import "./styles.scss";

const PasswordPageView: FC = () => (
  <div className="password-page">
    <PasswordForm />
  </div>
);

export { PasswordPageView };
