import type { FC } from "react";
import React from "react";

import { LoadingButton } from "../../../../basic/loading_button/loading_button";

import "./affiliate_contact.scss";

type Props = {
    avatar: string;
    firstName: string | null;
    lastName: string | null;
    loading?: boolean;
    middleName: string | null;
    onConfirmClick: () => void;
    onDeclineClick: () => void;
};

export const AffiliateContact: FC<Props> = ({
    avatar,
    firstName,
    lastName,
    loading,
    middleName,
    onConfirmClick,
    onDeclineClick,
}: Props) => (
    <div className="affiliate-contact">
        <img alt="" className="affiliate-contact__avatar" src={avatar} />
        <div className="affiliate-contact__info">
            <p className="affiliate-contact__title">
                {lastName} {firstName} {middleName}
            </p>
            <div className="affiliate-contact__buttons">
                <LoadingButton loading={loading} onClick={onConfirmClick} text="Принять*" variant="contained" />
                <LoadingButton loading={loading} onClick={onDeclineClick} text="Отклонить*" variant="outlined" />
            </div>
        </div>
    </div>
);
