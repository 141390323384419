import type { FC } from "react";
import React from "react";
import { Link } from "react-router-dom";

import { ButtonLink } from "../../../components/basic/button_link";

export const LogInSignUpView: FC = () => (
  <>
    <div className="user-panel__log-in">
      <Link to="/login/">Войти</Link>
    </div>
    <div className="user-panel__sign-up">
      <ButtonLink href="/signup/">Зарегистрироваться</ButtonLink>
    </div>
  </>
);
