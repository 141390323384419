import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRecoilState } from "recoil";

import { usePasswordFormSubmit } from "../../../actions/data";
import { passwordFormRequest } from "../../../state";
import { PasswordForm } from "../../../types/main.d";
import PasswordFormView from "./password_form_view";

const PasswordFormContainer: FunctionComponent = () => {
  const [request, setRequest] = useRecoilState(passwordFormRequest);
  const passwordFormSubmit = usePasswordFormSubmit();
  const inputNewPassword = useRef(null);
  const inputNewPasswordRepeat = useRef(null);
  const inputPassword = useRef(null);
  const [passwordData, setPasswordData] = useState<PasswordForm.State>({
    password: {
      error: false,
      errorChecker: (value) => value === "",
      ref: inputPassword,
      value: "",
    },
    newPassword: {
      error: false,
      errorChecker: (value) => value === "",
      ref: inputNewPassword,
      value: "",
    },
    newPasswordRepeat: {
      error: false,
      errorChecker: (value, data) => value !== data.newPassword.value,
      ref: inputNewPasswordRepeat,
      value: "",
    },
  });
  const handleChangeDataItem = useCallback(
    (fieldName: PasswordForm.FieldName, value: any) =>
      setPasswordData((oldPasswordData) => ({
        ...oldPasswordData,
        [fieldName]: {
          ...oldPasswordData[fieldName],
          error: oldPasswordData[fieldName].errorChecker
            ? (oldPasswordData[fieldName].errorChecker as any)(
                value,
                oldPasswordData
              )
            : oldPasswordData[fieldName].error,
          value,
        },
      })),
    []
  );
  const handleSubmit = useCallback(() => {
    const newPasswordData: any = {};
    let firstErrorField: any;
    Object.keys(passwordData).forEach((k) => {
      const error = passwordData[k as PasswordForm.FieldName].errorChecker
        ? (passwordData[k as PasswordForm.FieldName].errorChecker as any)(
            passwordData[k as PasswordForm.FieldName].value,
            passwordData
          )
        : passwordData[k as PasswordForm.FieldName].error;
      if (error && !firstErrorField) {
        firstErrorField = passwordData[k as PasswordForm.FieldName] as any;
      }
      newPasswordData[k as PasswordForm.FieldName] = {
        ...passwordData[k as PasswordForm.FieldName],
        ...{
          error,
        },
      } as any;
    });
    setTimeout(() => {
      if (firstErrorField && firstErrorField.ref) {
        firstErrorField.ref.current.focus();
      }
    }, 0);
    setPasswordData(newPasswordData);
    if (!firstErrorField && request.status !== "REQUEST") {
      passwordFormSubmit({
        newPassword: newPasswordData.newPassword.value,
        password: newPasswordData.password.value,
      });
    }
  }, [passwordData, passwordFormSubmit, request.status]);

  // Reset status on componentWillUnmount
  useEffect(() => {
    return () => {
      setRequest({
        status: "DEFAULT",
      });
    };
  }, [setRequest]);

  useEffect(() => {
    setTimeout(() => {
      if (request.status === "SUCCESS") {
        setPasswordData((oldPasswordData) => ({
          password: {
            ...oldPasswordData.password,
            error: false,
            value: "",
          },
          newPassword: {
            ...oldPasswordData.newPassword,
            error: false,
            value: "",
          },
          newPasswordRepeat: {
            ...oldPasswordData.newPasswordRepeat,
            error: false,
            value: "",
          },
        }));
      }
    });
  }, [request.status, setPasswordData]);

  return (
    <PasswordFormView
      data={passwordData}
      errorMessage={request.response?.message}
      newPasswordRef={inputNewPassword}
      newPasswordRepeatRef={inputNewPasswordRepeat}
      onChangeDataItem={handleChangeDataItem}
      onSubmit={handleSubmit}
      passwordRef={inputPassword}
      showError={request.status === "ERROR"}
      showLoader={request.status === "REQUEST"}
      showSuccess={request.status === "SUCCESS"}
    />
  );
};

export default PasswordFormContainer;
