import type { FC } from "react";
import React, { memo, useCallback, useMemo } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button } from "@material-ui/core";

import { currentUserDataQuery, sectionDataQuery } from "../../../state";
import TopicRowView from "./topic_row_view";

type Props = {
  isAuthorized: boolean;
} & RouteComponentProps<{
  sectionId: string;
}>;

const CourseSectionContainer: FC<any> = (props: Props) => {
  const sectionId = parseInt(props.match.params.sectionId, 10);
  const section = useRecoilValue(sectionDataQuery({ sectionId }));
  const { response: userData } = useRecoilValue(currentUserDataQuery);
  const isActiveSubscription =
    userData?.subscriptionData?.dateExpiration != null &&
    userData.subscriptionData.dateExpiration > new Date();

  const payUrl = props.isAuthorized ? "/pay/" : "/signup/";

  const handlePayClick = useCallback(() => {
    props.history.push(payUrl);
  }, [payUrl, props.history]);

  const showPayButton =
    section?.topics?.find(
      (t) =>
        !t.isAccessed || (t.isAccessBySubscription && !isActiveSubscription)
    ) !== undefined;

  const payButton = useMemo(
    () =>
      showPayButton ? (
        <Button
          className="course-section__pay"
          color="primary"
          onClick={handlePayClick}
          size="large"
          variant="contained"
        >
          Оформить подписку
        </Button>
      ) : null,
    [handlePayClick, showPayButton]
  );

  if (!section) {
    return (
      <div className="course-section">
        <Link to="/education/" className="course-section__back">
          &larr; Назад
        </Link>
        <h1>Раздел не найден</h1>
      </div>
    );
  }

  return (
    <div className="course-section">
      <Link to="/education/" className="course-section__back">
        &larr; Назад
      </Link>
      {payButton}
      <h1>{section.title}</h1>
      {section.topics?.map((t, i) => (
        <TopicRowView
          key={`topic_${t.id}`}
          payUrl={payUrl}
          showPayButton={
            !t.isAccessed || (t.isAccessBySubscription && !isActiveSubscription)
          }
          text={t.text}
          title={`${i + 1}. ${t.title}`}
          url={props.isAuthorized ? `/topic/${t.id}/` : "/signup/"}
        />
      ))}
    </div>
  );
};

export default memo(CourseSectionContainer);
