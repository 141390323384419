import type { FC, PropsWithChildren, ReactElement } from "react";
import React from "react";

import { Footer } from "../footer";
import { Header } from "../header";

import "./styles.scss";

type Props = {
    isAuthorized: boolean;
};

export const GeneralLayout: FC<Props> = (props: PropsWithChildren<Props>) => {
    const element = props.children as ReactElement;

    return (
        <div className="general-layout">
            <Header isAuthorized={props.isAuthorized} mini />
            <div className="general-layout__content">
                <div className="general-layout__content-container">
                    <element.type {...element.props} />
                </div>
            </div>
            <Footer />
        </div>
    );
};
