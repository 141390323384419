import type { FC } from "react";
import React from "react";

import { Contacts } from "../../../../common/contacts/contacts";
import type { ChildrenProps, ContactsParams } from "../../../../common/contacts/types";

const params: ContactsParams = { type: "CONTACTED_PUPILS" };

type Props = {
    onChange: (values: number[]) => void;
    values: number[];
};

export const Pupils: FC<Props> = ({ onChange, values }: Props) => (
    <Contacts onChange={onChange} params={params} values={values}>
        {({ contacts }: ChildrenProps) => (contacts.length ? <>{contacts}</> : <>Учеников нет</>)}
    </Contacts>
);
