import type { FC } from "react";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";

import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { currentUserDataQuery } from "../../../../../state";
import type { Course } from "../../../../../types/main";
import { ButtonLink } from "../../../../basic/button_link";
import { Title } from "../../../../basic/title/title";
import { classResultsDataQuery } from "../../state/class_results";
import { ClassResultsCharts } from "../class_results_charts/class_results_charts";

import "./class_results_container.scss";

type ColumnId = number | "name" | "result" | "date";
type Cell = {
    href?: string | undefined;
    key: string;
    label: string | number;
    orderId: ColumnId;
    orderValue?: string | number;
};
type Row = {
    cells: Cell[];
    key: string;
};

type Props = {
    classId: number;
};

const ClassResultsContainerFC: FC<Props> = ({ classId }: Props) => {
    const recoilState = classResultsDataQuery(classId);
    const [classResultsData, setClassResultsData] = useRecoilState(recoilState);
    useEffect(() => () => setClassResultsData(null as any), [setClassResultsData]);

    const [view, setView] = useState<"table" | "charts">("table");
    const handleView = useCallback((_, value) => setView(value), []);

    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const [orderBy, setOrderBy] = useState<ColumnId>("name");
    const handleSortClick = (column: ColumnId) => () => {
        const isAsc = orderBy === column && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(column);
    };

    const rightContent = useMemo(() => <ButtonLink href={`/classes/edit/${classId}`}>Редактировать</ButtonLink>, [
        classId,
    ]);

    const { classItem, course, results } = classResultsData.response;

    const topics = useMemo(
        () => course?.sections?.reduce((acc, v) => [...acc, ...v.topics.map((t) => t)], [] as Course.Topic[]) ?? [],
        [course?.sections]
    );

    const [{ response: userData }] = useRecoilState(currentUserDataQuery);

    const rows: Row[] = useMemo(() => {
        if (classItem.pupils.length === 0) {
            return [];
        }
        return classItem.pupils
            .map((p) => ({
                cells: [
                    {
                        href: `/users/show/${p.id}/`,
                        key: `user_name_${p.id}`,
                        label: `${p.lastName ?? ""} ${p.firstName ?? ""}`,
                        orderId: "name",
                        orderValue: `${p.lastName ?? ""} ${p.firstName ?? ""}`.trim().toLowerCase(),
                    } as Cell,
                    ...topics.map((t) => {
                        const k = `${t.id}_${p.id}`;
                        const average = results.averagesByTopicIdsUserIds[k];
                        return {
                            href: average != null ? `/users/topic/${p.id}/${t.id}/` : undefined,
                            key: `user_id_${p.id}_topic_id_${t.id}`,
                            label: average != null ? Math.floor(average * 100) : "–", // &ndash;
                            orderId: t.id,
                            orderValue: average != null ? Math.floor(average * 100) : -1,
                        } as Cell;
                    }),
                    {
                        key: `user_result_${p.id}`,
                        label: Math.floor(
                            ((topics.map((t) => results.averagesByTopicIdsUserIds[`${t.id}_${p.id}`]) as (
                                | number
                                | null
                                | undefined
                            )[]).filter((v) => v != null) as number[]).reduce(
                                (acc, cur, i) => (acc * i + cur) / (i + 1),
                                0
                            ) * 100
                        ),
                        orderId: "result",
                    } as Cell,
                    {
                        key: `user_date_${p.id}`,
                        label: p.dateVisit ? format(p.dateVisit, "dd.MM.yyyy") : "",
                        orderId: "date",
                    } as Cell,
                ],
                key: `user_id_${p.id}`,
            }))
            .sort((a, b) => {
                const aCell = a.cells.find((v) => v.orderId === orderBy);
                const aValue = aCell?.orderValue !== undefined ? aCell?.orderValue : aCell?.label;
                const bCell = b.cells.find((v) => v.orderId === orderBy);
                const bValue = bCell?.orderValue !== undefined ? bCell?.orderValue : bCell?.label;
                if (aValue === bValue) {
                    return 0;
                }
                if (aValue === undefined) {
                    return order === "desc" ? 1 : -1;
                }
                if (bValue === undefined) {
                    return order === "desc" ? -1 : 1;
                }
                if (order === "desc") {
                    return aValue > bValue ? -1 : 1;
                }
                return aValue < bValue ? -1 : 1;
            });
    }, [classItem, order, orderBy, results, topics]);

    if (userData?.status !== "TEACHER") {
        return <h2>Доступ закрыт</h2>;
    }

    if (rows.length === 0) {
        return (
            <>
                <Title
                    backUrl="/classes/"
                    rightContent={rightContent}
                    title={classItem.totalTitle ?? "Результаты класса"}
                />
                <p>В классе нет учеников. Добавьте их, чтобы увидеть результаты прохождения курса.</p>
            </>
        );
    }

    return (
        <>
            <Title
                backUrl="/classes/"
                rightContent={rightContent}
                title={classItem.totalTitle ?? "Результаты класса"}
            />
            <div className="class-results__tabs">
                <ToggleButtonGroup exclusive onChange={handleView} size="small" value={view}>
                    <ToggleButton value="table">Таблица</ToggleButton>
                    <ToggleButton value="charts">Графики</ToggleButton>
                </ToggleButtonGroup>
            </div>
            {view === "table" && (
                <div className="class-results__table">
                    <Table aria-label="simple table" size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="none">
                                    <div className="class-results__cell-number" />
                                </TableCell>
                                <TableCell padding="none">
                                    <div className="class-results__cell-name">
                                        <TableSortLabel
                                            active={orderBy === "name"}
                                            direction={orderBy === "name" ? order : "asc"}
                                            onClick={handleSortClick("name")}
                                        >
                                            ФИО
                                        </TableSortLabel>
                                    </div>
                                </TableCell>
                                {topics.map((t) => (
                                    <TableCell
                                        key={`topic_id_${t.id}`}
                                        padding="none"
                                        sortDirection={orderBy === t.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === t.id}
                                            direction={orderBy === t.id ? order : "asc"}
                                            onClick={handleSortClick(t.id)}
                                        >
                                            <div className="class-results__cell-topic">
                                                <Link to={`/classes/topic/${classId}/${t.id}/`}>{t.title}</Link>
                                            </div>
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell padding="none">
                                    <TableSortLabel
                                        active={orderBy === "result"}
                                        direction={orderBy === "result" ? order : "asc"}
                                        onClick={handleSortClick("result")}
                                    >
                                        Общий рез.,&nbsp;%
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell padding="none">
                                    <div className="class-results__cell-date">
                                        <TableSortLabel
                                            active={orderBy === "date"}
                                            direction={orderBy === "date" ? order : "asc"}
                                            onClick={handleSortClick("date")}
                                        >
                                            Последнее посещение
                                        </TableSortLabel>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((r, index) => (
                                <TableRow key={r.key} hover>
                                    <TableCell padding="none">
                                        <div className="class-results__cell-number">{index + 1}.</div>
                                    </TableCell>
                                    {r.cells.map((c) => (
                                        <TableCell key={c.key} padding="none">
                                            {c.href ? <Link to={c.href}>{c.label}</Link> : c.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
            {view === "charts" && <ClassResultsCharts course={course} results={results} />}
        </>
    );
};

export const ClassResultsContainer = memo(ClassResultsContainerFC);
