import { getDfpToken } from "../../dfp_token";
import { getJwtToken } from "../../jwt_token";

export const getHeaders = () => {
  const headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const jwtToken = getJwtToken();
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }
  const dfpToken = getDfpToken();
  if (dfpToken) {
    headers["x-app-dfp"] = dfpToken;
  }
  return headers;
};
