import type { FC } from "react";
import React from "react";

import { Course } from "../../data_blocks/course";

import "./styles.scss";

type Props = {
  isAuthorized: boolean;
};

export const EducationPageView: FC<any> = (props: Props) => (
  <div className="education-page">
    <Course {...props} />
  </div>
);
