import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { MineResultsTopic } from "../mine_results_topic/mine_results_topic";

type Props = {
    topicId: number;
};

export const MineResultsSuspense: FC<Props> = ({ topicId }: Props) => (
    <ErrorBoundary>
        <Suspense fallback={<CircularProgress />}>
            <MineResultsTopic topicId={topicId} />
        </Suspense>
    </ErrorBoundary>
);
