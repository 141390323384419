import React, { FC } from "react";
import { PayForm } from "../../data_blocks/pay_form";

import "./styles.scss";

const PayPageView: FC = () => {
  return (
    <div className="pay-page">
      <PayForm />
    </div>
  );
};

export { PayPageView };
