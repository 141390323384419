import { atomFamily, selectorFamily } from "recoil";

import { sendRequest } from "../../../../core/api/request";
import type { TeachersListType } from "../types";

type TeachersListProps = {
  limit: number;
  offset: number;
  search: string;
};

export const requestTeachersData = async ({
  limit,
  offset,
  search,
}: TeachersListProps) => {
  const data = await sendRequest({
    bodyData: {
      apiVersion: 2,
      skipRelations: true,
      status: [5],
    },
    method: "POST",
    url: `/users/list/?offset=${offset}&limit=${limit}&search=${search}`,
  })
    .then(({ cursor, data: successData }) => ({
      cursor,
      response: successData,
      status: "SUCCESS",
    }))
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (data.status === "ERROR") {
    throw data;
  }
  return data;
};

const teachersDataRequestId = atomFamily({
  key: "teachersDataRequestId",
  default: 0,
});

const teachersData = atomFamily<TeachersListType | null, TeachersListProps>({
  key: "teachersData",
  default: null,
});

export const teachersDataQuery = selectorFamily<
  TeachersListType,
  TeachersListProps
>({
  key: "teachersDataQuery",
  get: (params) => async ({ get }) => {
    get(teachersDataRequestId(params)); // Add request ID as a dependency
    const newTeachersData = get(teachersData(params));
    if (newTeachersData !== null) {
      return newTeachersData;
    }
    return await requestTeachersData(params);
  },
  set: (params) => ({ set }, newValue) => {
    if (newValue === null) {
      set(teachersDataRequestId(params), (requestId) => requestId + 1);
    }
    set(teachersData(params), newValue);
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
