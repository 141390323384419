import { atomFamily, selectorFamily } from "recoil";

import {
  EXTENSION_ANSWERS,
  EXTENSION_QUESTIONS,
} from "../../../../constants/cmi5";
import { LRS } from "../../../../constants/general";
import { sendRequest } from "../../../../core/api/request";
import type {
  ClassQuestionsDataType,
  QuestionsStatistics,
  ResultsExtensionsDataType,
} from "../types";

const getActivityIdQuestionIdKey = (activityId: string, questionId: number) =>
  `${activityId}_${questionId}`;

type Params = {
  topicId: number;
  usersIds: number[];
};

const requestClassQuestionsData = async ({
  topicId,
  usersIds,
}: Params): Promise<ClassQuestionsDataType> => {
  if (usersIds.length === 0) {
    return {
      cursor: null,
      response: [],
      status: "SUCCESS",
    };
  }

  const resultsData = await sendRequest<ResultsExtensionsDataType>({
    bodyData: {
      topicsIds: [topicId],
      usersIds,
    },
    method: "POST",
    url: `${LRS.endpoint}results/extensions/`,
  })
    .then(({ cursor, data: successData }) => {
      const questionsStatistics = Object.keys(successData).reduce(
        (acc, activityId) =>
          successData[activityId]!.reduce((sAcc, statement) => {
            const answers = statement[EXTENSION_ANSWERS] ?? {};
            const questions = statement[EXTENSION_QUESTIONS] ?? [];
            return questions.reduce((qAcc, question) => {
              const key = getActivityIdQuestionIdKey(activityId, question.id);
              return {
                ...qAcc,
                [key]: qAcc[key]
                  ? {
                      ...qAcc[key],
                      attempts: qAcc[key].attempts + 1,
                      numberOfSelectsByAnswerIds: Object.keys(
                        answers[question.id] ?? {}
                      ).reduce(
                        (aAcc, answerId) => ({
                          ...aAcc,
                          [answerId]:
                            (aAcc[answerId] ?? 0) +
                            (answers[question.id][answerId] ? 1 : 0),
                        }),
                        qAcc[key].numberOfSelectsByAnswerIds
                      ),
                    }
                  : {
                      ...question,
                      attempts: 1,
                      numberOfSelectsByAnswerIds: Object.keys(
                        answers[question.id] ?? {}
                      ).reduce(
                        (aAcc, answerId) => ({
                          ...aAcc,
                          [answerId]: answers[question.id][answerId] ? 1 : 0,
                        }),
                        {}
                      ),
                    },
              };
            }, sAcc);
          }, acc),
        {} as Record<string, QuestionsStatistics>
      );

      return {
        cursor,
        response: Object.keys(questionsStatistics).map(
          (k) => questionsStatistics[k]
        ),
        status: "SUCCESS",
      };
    })
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (resultsData.status === "ERROR") {
    throw resultsData;
  }
  return resultsData;
};

const classQuestionsDataRequestId = atomFamily({
  key: "classQuestionsDataRequestId",
  default: 0,
});

const classQuestionsData = atomFamily<ClassQuestionsDataType | null, Params>({
  key: "classQuestionsData",
  default: null,
});

export const classQuestionsDataQuery = selectorFamily<
  ClassQuestionsDataType,
  Params
>({
  key: "classQuestionsDataQuery",
  get: (params) => async ({ get }) => {
    get(classQuestionsDataRequestId(params)); // Add request ID as a dependency
    const newClassQuestionsData = get(classQuestionsData(params));
    if (newClassQuestionsData !== null) {
      return newClassQuestionsData;
    }
    return await requestClassQuestionsData(params);
  },
  set: (params) => ({ set }, newValue) => {
    if (newValue === null) {
      set(classQuestionsDataRequestId(params), (requestId) => requestId + 1);
    }
    set(classQuestionsData(params), newValue);
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
