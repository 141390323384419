import { atomFamily, selectorFamily } from "recoil";

import { LRS } from "../../../../constants/general";
import { sendRequest } from "../../../../core/api/request";
import { requestCourseData } from "../../../../state/selectors";
import type { ClassResultsDataType } from "../types";
import { requestClassItemData } from "./class_item";

const requestClassResultsData = async (
  classId: number
): Promise<ClassResultsDataType> => {
  const classItemData = await requestClassItemData(classId);
  const courseData = await requestCourseData(true);

  const topicsIds =
    courseData.response?.sections.reduce(
      (acc, v) => [...acc, ...v.topics.map((t) => t.id)],
      [] as number[]
    ) ?? [];
  const usersIds =
    classItemData.response.pupils.map((v) => v.id as number) ?? [];
  if (topicsIds.length === 0 || usersIds.length === 0) {
    return {
      cursor: null,
      response: {
        classItem: classItemData.response,
        course: courseData.response,
        results: {
          analytics: {
            resultByDuration: [],
            resultByPercents: [],
            resultByTopicIds: null,
          },
          averagesByTopicIdsUserIds: {},
        },
      },
      status: "SUCCESS",
    };
  }

  const resultsData = await sendRequest({
    bodyData: {
      topicsIds,
      usersIds,
    },
    method: "POST",
    url: `${LRS.endpoint}results/users/`,
  })
    .then(({ cursor, data: successData }) => ({
      cursor,
      response: {
        classItem: classItemData.response,
        course: courseData.response,
        results: successData,
      },
      status: "SUCCESS",
    }))
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (resultsData.status === "ERROR") {
    throw resultsData;
  }
  return resultsData;
};

const classResultsDataRequestId = atomFamily({
  key: "classResultsDataRequestId",
  default: 0,
});

const classResultsData = atomFamily<ClassResultsDataType | null, number>({
  key: "classResultsData",
  default: null,
});

export const classResultsDataQuery = selectorFamily<
  ClassResultsDataType,
  number
>({
  key: "classResultsDataQuery",
  get: (classId) => async ({ get }) => {
    get(classResultsDataRequestId(classId)); // Add request ID as a dependency
    const newClassResultsData = get(classResultsData(classId));
    if (newClassResultsData !== null) {
      return newClassResultsData;
    }
    return await requestClassResultsData(classId);
  },
  set: (classId) => ({ set }, newValue) => {
    if (newValue === null) {
      set(classResultsDataRequestId(classId), (requestId) => requestId + 1);
    }
    set(classResultsData(classId), newValue);
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
