import type { FC } from "react";
import React, { memo } from "react";
import { useRecoilState } from "recoil";

import { currentUserDataQuery } from "../../../../../state";
import { UserResultsSuspense } from "../../../users/components/user_results_suspense/user_results_suspense";

const MineResultsFC: FC = () => {
    const [{ response: userData }] = useRecoilState(currentUserDataQuery);

    return userData ? <UserResultsSuspense mineResults userId={userData.id} /> : null;
};

export const MineResults = memo(MineResultsFC);
