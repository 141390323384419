import type { ComponentClass, FC, ReactNode } from "react";
import React, { memo, MemoExoticComponent } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { ContentPageView } from "./components/pages/content/view";
import { EducationPageView } from "./components/pages/education/view";
import { LogInPageView } from "./components/pages/log_in/view";
import { MainPageView } from "./components/pages/main/view";
import { PasswordPageView } from "./components/pages/password/view";
import { PayPageView } from "./components/pages/pay/view";
import { PaymentsPage } from "./components/pages/payments/payments_page";
import { ProfilePage } from "./components/pages/profile/profile_page";
import { RecoverPage } from "./components/pages/recover/recover_page";
import { ResultsPage } from "./components/pages/results/results_page";
import { ResultsTopicPage } from "./components/pages/results/results_topic_page";
import { SectionPageView } from "./components/pages/section/view";
import { SignUpPageView } from "./components/pages/sign_up/view";
import { WeeklyRatingPage } from "./components/pages/weekly_rating/weekly_rating_page";
import { AboutPage } from "./components/pages/special/about_page";
import { ActivateDevicePage } from "./components/pages/special/activate_device_page";
import { ActivatePasswordPage } from "./components/pages/special/activate_password_page";
import { ActivateUserPage } from "./components/pages/activate_user/activate_user_page";
import { ClassesAddPage } from "./components/pages/classes/classes_add_page";
import { ClassesTopicPage } from "./components/pages/classes/classes_topic_page";
import { ClassesEditPage } from "./components/pages/classes/classes_edit_page";
import { ClassesPage } from "./components/pages/classes/classes_page";
import { ClassesShowPage } from "./components/pages/classes/classes_show_page";
import { ContactsPage } from "./components/pages/contacts/contacts_page";
import { DfpUserConfirmPage } from "./components/pages/special/dfp_user_confirm_page";
import { NotFoundPage } from "./components/pages/special/not_found_page";
import { PaymentFailPage } from "./components/pages/special/payment_fail_page";
import { PaymentSuccessPage } from "./components/pages/special/payment_success_page";
import { RequisitesPage } from "./components/pages/special/requisites_page";
import { TeachersPage } from "./components/pages/teachers/teachers_page";
import { TopicPageView } from "./components/pages/topic/view";
import { UsersMatchingPage } from "./components/pages/users/users_matching_page";
import { UsersShowPage } from "./components/pages/users/users_show_page";
import { UsersTopicPage } from "./components/pages/users/users_topic_page";
import { GeneralLayout } from "./layouts/general/layout";
import { MainLayout } from "./layouts/main/layout";
import { WideLayout } from "./layouts/wide/layout";
import { authorizationToken } from "./state";

const documentTitle = "«Стань грамотным!» — образовательный сайт по русскому языку";

const renderWithLayout = (
    Layout: ComponentClass | FC<{ isAuthorized: boolean }>,
    Component: ComponentClass | FC<{ isAuthorized: boolean }> | FC<RouteComponentProps<any>> | MemoExoticComponent<FC>,
    generalProps?: any,
    logInRedirectUrl?: string
) =>
    function ComponentWithLayout(props: any): ReactNode | any {
        // if (SystemStore.getDataRequestStatus() === RequestStatus.DEFAULT) {
        //   DataActions.requestSystemData();
        // }
        return logInRedirectUrl ? (
            <Redirect to={logInRedirectUrl} />
        ) : (
            <Layout {...generalProps}>
                <Component {...generalProps} {...props} />
            </Layout>
        );
    };

const RoutesFC: FC = () => {
    document.title = documentTitle;
    const isAuthorized = !!useRecoilValue(authorizationToken);
    const generalProps = { isAuthorized };
    // Особенности ссылок в письмах LMS
    const isFromEmailNotification = new URL(window.location.href).searchParams.get("from") === "email_notification";
    return (
        <Router>
            <Switch>
                <Route exact={true} path="/" render={renderWithLayout(MainLayout, MainPageView, generalProps)} />
                <Route exact={true} path="/about/" render={renderWithLayout(GeneralLayout, AboutPage, generalProps)} />
                <Route
                    exact={true}
                    path="/requisites/"
                    render={renderWithLayout(GeneralLayout, RequisitesPage, generalProps)}
                />
                <Route
                    exact={true}
                    path="/payment/fail/"
                    render={renderWithLayout(GeneralLayout, PaymentFailPage, generalProps)}
                />
                <Route
                    exact={true}
                    path="/payment/success/"
                    render={renderWithLayout(GeneralLayout, PaymentSuccessPage, generalProps)}
                />
                <Route
                    exact={true}
                    path="/education/"
                    render={renderWithLayout(
                        GeneralLayout,
                        EducationPageView,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/payments/"
                    render={renderWithLayout(
                        GeneralLayout,
                        PaymentsPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/results/"
                    render={renderWithLayout(
                        GeneralLayout,
                        ResultsPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/results/topic/:topicId(\d+)/"
                    render={renderWithLayout(
                        GeneralLayout,
                        ResultsTopicPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/weekly-rating/"
                    render={renderWithLayout(
                        GeneralLayout,
                        WeeklyRatingPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/teachers/"
                    render={renderWithLayout(
                        GeneralLayout,
                        TeachersPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/contacts/"
                    render={renderWithLayout(
                        GeneralLayout,
                        ContactsPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/classes/"
                    render={renderWithLayout(
                        GeneralLayout,
                        ClassesPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/classes/add/"
                    render={renderWithLayout(
                        GeneralLayout,
                        ClassesAddPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/classes/edit/:classId(\d+)/"
                    render={renderWithLayout(
                        GeneralLayout,
                        ClassesEditPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/classes/show/:classId(\d+)/"
                    render={renderWithLayout(
                        GeneralLayout,
                        ClassesShowPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/classes/topic/:classId(\d+)/:topicId(\d+)/"
                    render={renderWithLayout(
                        GeneralLayout,
                        ClassesTopicPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/users/matching/:userId(\d+)/"
                    render={renderWithLayout(
                        GeneralLayout,
                        UsersMatchingPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/users/show/:userId(\d+)/"
                    render={renderWithLayout(
                        GeneralLayout,
                        UsersShowPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/users/topic/:userId(\d+)/:topicId(\d+)/"
                    render={renderWithLayout(
                        GeneralLayout,
                        UsersTopicPage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/login/"
                    render={renderWithLayout(
                        GeneralLayout,
                        LogInPageView,
                        generalProps,
                        isAuthorized ? "/" : undefined
                    )}
                />
                <Route
                    exact={true}
                    path="/recover/"
                    render={renderWithLayout(GeneralLayout, RecoverPage, generalProps, isAuthorized ? "/" : undefined)}
                />
                <Route
                    exact={true}
                    path="/signup/"
                    render={renderWithLayout(
                        GeneralLayout,
                        SignUpPageView,
                        generalProps,
                        isAuthorized ? "/" : undefined
                    )}
                />
                <Route
                    exact={true}
                    path="/profile/"
                    render={renderWithLayout(
                        GeneralLayout,
                        ProfilePage,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/pay/"
                    render={renderWithLayout(
                        GeneralLayout,
                        PayPageView,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/password/"
                    render={renderWithLayout(
                        GeneralLayout,
                        PasswordPageView,
                        generalProps,
                        isAuthorized ? undefined : isFromEmailNotification ? "/recover/" : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/activate/device/"
                    render={renderWithLayout(GeneralLayout, ActivateDevicePage, generalProps)}
                />
                <Route
                    exact={true}
                    path="/activate/user/:login(\w*)/:code(\w*)/"
                    render={renderWithLayout(GeneralLayout, ActivateUserPage, generalProps)}
                />
                <Route
                    exact={true}
                    path="/activate/pass/:login(\w*)/:code(\w*)/"
                    render={renderWithLayout(GeneralLayout, ActivatePasswordPage, generalProps)}
                />
                <Route
                    exact={true}
                    path="/confirmaccess/"
                    render={renderWithLayout(GeneralLayout, DfpUserConfirmPage, generalProps)}
                />
                <Route
                    exact={true}
                    path="/section/:sectionId(\d+)/"
                    render={renderWithLayout(
                        GeneralLayout,
                        SectionPageView,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/topic/:topicId(\d+)/"
                    render={renderWithLayout(
                        GeneralLayout,
                        TopicPageView,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                <Route
                    exact={true}
                    path="/topic/:topicId(\d+)/content/:contentId(\d+)/"
                    render={renderWithLayout(
                        WideLayout,
                        ContentPageView,
                        generalProps,
                        isAuthorized ? undefined : "/login/"
                    )}
                />
                {/* Особенности ссылок в письмах LMS */}
                <Route exact={true} path="/profile/finance/" render={() => <Redirect to="/payments/" />} />
                <Route exact={true} path="/enter/" render={() => <Redirect to="/login/" />} />
                <Route render={renderWithLayout(GeneralLayout, NotFoundPage, generalProps)} />
            </Switch>
        </Router>
    );
};

export const Routes = memo(RoutesFC);
