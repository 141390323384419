import type { FC } from "react";
import React, { useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { SignUpFormView } from "./sign_up_form_view";
import { SignUpSuccessView } from "./sign_up_success_view";
import { useSignUpFormSubmit } from "../../../actions/data";
import { emailRegExp } from "../../../constants/general";
import { useOnChangeDataItem } from "../../../hooks/on_change_data_item";
import { useOnSubmitClick } from "../../../hooks/on_submit_click";
import { useResetFormStatus } from "../../../hooks/reset_form_status";
import { signUpFormRequest } from "../../../state";
import type { SignUpForm } from "../../../types/main.d";

import "./styles.scss";

const passwordMinLength = 8;

export const SignUpFormContainer: FC = () => {
  const [request, setRequest] = useRecoilState(signUpFormRequest);
  const signUpFormSubmit = useSignUpFormSubmit();
  const inputEmail = useRef(null);
  const inputPassword = useRef(null);
  const [signUpData, setSignUpData] = useState<SignUpForm.State>({
    lastName: {
      value: "",
    },
    firstName: {
      value: "",
    },
    middleName: {
      value: "",
    },
    email: {
      error: false,
      errorChecker: (value) => !emailRegExp.test(value),
      ref: inputEmail,
      value: "",
    },
    password: {
      error: false,
      errorChecker: (value) => value.length < passwordMinLength,
      helperText: `Количество символов ${passwordMinLength} или больше.`,
      ref: inputPassword,
      value: "",
    },
    status: {
      error: false,
      errorChecker: (value) => value == null,
      value: null,
    },
    class: {
      value: 0,
    },
    isAgreedTerms: {
      error: false,
      errorChecker: (value) => !value,
      value: false,
    },
    isAgreedSubscription: {
      value: false,
    },
  });
  const handleChangeDataItem = useOnChangeDataItem(setSignUpData);
  const handleSubmit = useOnSubmitClick(
    signUpData,
    request.status,
    setSignUpData,
    signUpFormSubmit
  );
  useResetFormStatus(setRequest);

  return request.status === "SUCCESS" ? (
    <SignUpSuccessView />
  ) : (
    <SignUpFormView
      data={signUpData}
      emailRef={inputEmail}
      errorMessage={request.response?.message}
      onChangeDataItem={handleChangeDataItem}
      onSubmit={handleSubmit}
      passwordRef={inputPassword}
      showError={request.status === "ERROR"}
      showLoader={request.status === "REQUEST"}
    />
  );
};
