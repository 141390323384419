declare let window: any;

/* PluginDetect v0.6.3 [ onWindowLoaded getVersion Java(OTF&getInfo) QT DevalVR Shockwave Flash WMP Silverlight VLC ] by Eric Gerds www.pinlady.net/PluginDetect */

const PluginDetect: any = {
  getNum: function (b: any, c: any) {
    if (!this.num(b)) {
      return null;
    }
    let a;
    if (typeof c == "undefined") {
      a = /[\d][\d\.\_,-]*/.exec(b);
    } else {
      a = new RegExp(c).exec(b);
    }
    return a ? a[0].replace(/[\.\_-]/g, ",") : null;
  },
  hasMimeType: function (c: any) {
    if (PluginDetect.isIE) {
      return null;
    }
    let b, a, d;
    const e = c.constructor == String ? [c] : c;
    for (d = 0; d < e.length; d++) {
      b = navigator.mimeTypes[e[d]];
      if (b && b.enabledPlugin) {
        a = b.enabledPlugin;
        if (a.name || a.description) {
          return b;
        }
      }
    }
    return null;
  },
  findNavPlugin: function (g: any, d: any) {
    const a = g.constructor == String ? g : g.join(".*");
    const e = d === false ? "" : "\\d";
    let b;
    const c = new RegExp(a + ".*" + e + "|" + e + ".*" + a, "i");
    const f = navigator.plugins;
    for (b = 0; b < f.length; b++) {
      if (c.test(f[b].description) || c.test(f[b].name)) {
        return f[b];
      }
    }
    return null;
  },
  AXO: window.ActiveXObject,
  getAXO: function (b: any, a: any) {
    let f = null,
      c = false;
    try {
      f = new this.AXO(b);
      c = true;
    } catch (d) {}
    if (typeof a != "undefined") {
      // delete f;
      return c;
    }
    return f;
  },
  num: function (a: any) {
    return typeof a != "string" ? false : /\d/.test(a);
  },
  compareNums: function (g: any, e: any) {
    let a;
    const f = window.parseInt;
    if (!this.num(g) || !this.num(e)) {
      return 0;
    }
    if (this.plugin && this.plugin.compareNums) {
      return this.plugin.compareNums(g, e);
    }
    const c = g.split(",");
    const b = e.split(",");
    for (a = 0; a < Math.min(c.length, b.length); a++) {
      if (f(c[a], 10) > f(b[a], 10)) {
        return 1;
      }
      if (f(c[a], 10) < f(b[a], 10)) {
        return -1;
      }
    }
    return 0;
  },
  formatNum: function (b: any) {
    if (!this.num(b)) {
      return null;
    }
    let a;
    const c = b
      .replace(/\s/g, "")
      .replace(/[\.\_]/g, ",")
      .split(",")
      .concat(["0", "0", "0", "0"]);
    for (a = 0; a < 4; a++) {
      if (/^(0+)(.+)$/.test(c[a])) {
        c[a] = RegExp.$2;
      }
    }
    if (!/\d/.test(c[0])) {
      c[0] = "0";
    }
    return c[0] + "," + c[1] + "," + c[2] + "," + c[3];
  },
  initScript: function () {
    const userAgent = navigator.userAgent;

    /*@cc_on!@*/
    this.isIE = false;
    this.IEver = -1;
    // this.IEver =
    //   this.isIE && /MSIE\s*(\d\.?\d*)/i.exec(userAgent)
    //     ? parseFloat(RegExp.$1)
    //     : -1;

    this.ActiveXEnabled = false;
    if (this.isIE) {
      let x;
      const progid = [
        "Msxml2.XMLHTTP",
        "Msxml2.DOMDocument",
        "Microsoft.XMLDOM",
        "ShockwaveFlash.ShockwaveFlash",
        "TDCCtl.TDCCtl",
        "Shell.UIHelper",
        "Scripting.Dictionary",
        "wmplayer.ocx",
      ];
      for (x = 0; x < progid.length; x++) {
        if (this.getAXO(progid[x], 1)) {
          this.ActiveXEnabled = true;
          break;
        }
      }
      this.head =
        typeof document.getElementsByTagName != "undefined"
          ? document.getElementsByTagName("head")[0]
          : null;
    }
    this.isGecko =
      !this.isIE &&
      typeof navigator.product == "string" &&
      /Gecko/i.test(navigator.product) &&
      /Gecko\s*\/\s*\d/i.test(userAgent)
        ? true
        : false;
    this.GeckoRV = this.isGecko
      ? this.formatNum(
          /rv\s*\:\s*([\.\,\d]+)/i.test(userAgent) ? RegExp.$1 : "0.9"
        )
      : null;
    this.isSafari =
      !this.isIE && /Safari\s*\/\s*\d/i.test(userAgent) ? true : false;
    this.isChrome = /Chrome\s*\/\s*\d/i.test(userAgent) ? true : false;
    this.onWindowLoaded(0);
  },
  init: function (c: any, a: any) {
    if (typeof c != "string") {
      return -3;
    }
    c = c.toLowerCase().replace(/\s/g, "");
    if (typeof this[c] == "undefined") {
      return -3;
    }
    const d = this[c];
    this.plugin = d;
    if (typeof d.installed == "undefined" || a == true) {
      d.installed = null;
      d.version = null;
      d.version0 = null;
      d.getVersionDone = null;
      d.$ = this;
    }
    this.garbage = false;
    if (this.isIE && !this.ActiveXEnabled) {
      if (this.plugin != this.java) {
        return -2;
      }
    }
    return 1;
  },
  isMinVersion: function (g: any, e: any, c: any, b: any) {
    return -3;
  },
  getVersion: function (e: any, b?: any, a?: any) {
    const d = PluginDetect;
    const c = d.init(e);
    if (c < 0) {
      return null;
    }
    const f = d.plugin;
    if (f.getVersionDone != 1) {
      f.getVersion(b, a);
      if (f.getVersionDone === null) {
        f.getVersionDone = 1;
      }
    }
    d.cleanup();
    return f.version || f.version0;
  },
  getInfo: function (f: any, c: any, b: any) {
    let a = {};
    const e = PluginDetect;
    const d = e.init(f);
    if (d < 0) {
      return a;
    }
    const g = e.plugin;
    if (typeof g.getInfo != "undefined") {
      if (g.getVersionDone === null) {
        e.getVersion(f, c, b);
      }
      a = g.getInfo();
    }
    return a;
  },
  cleanup: function () {
    if (this.garbage && typeof window.CollectGarbage != "undefined") {
      window.CollectGarbage();
    }
  },
  isActiveXObject: function (b: any) {
    let a;
    const f = "/";
    const c =
      '<object width="1" height="1" style="display:none" ' +
      this.plugin.getCodeBaseVersion(b) +
      ">" +
      this.plugin.HTML +
      "<" +
      f +
      "object>";
    if (this.head.firstChild) {
      this.head.insertBefore(
        document.createElement("object"),
        this.head.firstChild
      );
    } else {
      this.head.appendChild(document.createElement("object"));
    }
    this.head.firstChild.outerHTML = c;
    try {
      this.head.firstChild.classid = this.plugin.classID;
    } catch (g) {}
    a = false;
    try {
      if (this.head.firstChild.object) {
        a = true;
      }
    } catch (g) {}
    try {
      if (a && this.head.firstChild.readyState < 4) {
        this.garbage = true;
      }
    } catch (g) {}
    this.head.removeChild(this.head.firstChild);
    return a;
  },
  codebaseSearch: function (c: any) {
    if (!this.ActiveXEnabled) {
      return null;
    }
    if (typeof c != "undefined") {
      return this.isActiveXObject(c);
    }
    const j = [0, 0, 0, 0];
    let g;
    let f;
    const b = this.plugin.digits,
      i = (k: any, m: any) => {
        const l =
          (k == 0 ? m : j[0]) +
          "," +
          (k == 1 ? m : j[1]) +
          "," +
          (k == 2 ? m : j[2]) +
          "," +
          (k == 3 ? m : j[3]);
        return this.isActiveXObject(l);
      };
    let h,
      d,
      a = false;
    for (g = 0; g < b.length; g++) {
      h = b[g] * 2;
      j[g] = 0;
      for (f = 0; f < 20; f++) {
        if (h == 1 && g > 0 && a) {
          break;
        }
        if (h - j[g] > 1) {
          d = Math.round((h + j[g]) / 2);
          if (i(g, d)) {
            j[g] = d;
            a = true;
          } else {
            h = d;
          }
        } else {
          if (h - j[g] == 1) {
            h--;
            if (!a && i(g, h)) {
              a = true;
            }
            break;
          } else {
            if (!a && i(g, h)) {
              a = true;
            }
            break;
          }
        }
      }
      if (!a) {
        return null;
      }
    }
    return j.join(",");
  },
  dummy1: 0,
};
PluginDetect.onDetectionDone = function (g: any, e: any, d: any, a: any) {
  return -1;
};
PluginDetect.onWindowLoaded = function (c: any) {
  const b = PluginDetect;
  const a = window;
  if (b.EventWinLoad === true) {
  } else {
    b.winLoaded = false;
    b.EventWinLoad = true;
    if (typeof a.addEventListener != "undefined") {
      a.addEventListener("load", b.runFuncs, false);
    } else {
      if (typeof a.attachEvent != "undefined") {
        a.attachEvent("onload", b.runFuncs);
      } else {
        if (typeof a.onload == "function") {
          b.funcs[b.funcs.length] = a.onload;
        }
        a.onload = b.runFuncs;
      }
    }
  }
  if (typeof c == "function") {
    b.funcs[b.funcs.length] = c;
  }
};
PluginDetect.funcs = [0];
PluginDetect.runFuncs = function () {
  const b = PluginDetect;
  b.winLoaded = true;
  for (let a = 0; a < b.funcs.length; a++) {
    if (typeof b.funcs[a] == "function") {
      b.funcs[a](b);
      b.funcs[a] = null;
    }
  }
};
PluginDetect.quicktime = {
  mimeType: [
    "video/quicktime",
    "application/x-quicktimeplayer",
    "image/x-macpaint",
    "image/x-quicktime",
  ],
  progID: "QuickTimeCheckObject.QuickTimeCheck.1",
  progID0: "QuickTime.QuickTime",
  classID: "clsid:02BF25D5-8C17-4B23-BC80-D3488ABDDC6B",
  minIEver: 7,
  HTML:
    '<param name="src" value="A14999.mov" /><param name="controller" value="false" />',
  getCodeBaseVersion: function (a: any) {
    return 'codebase="#version=' + a + '"';
  },
  digits: [8, 64, 16, 0],
  clipTo3digits: function (f: any) {
    if (f === null || typeof f == "undefined") {
      return null;
    }
    let d;
    const g = this.$;
    const e = f.split(",");
    if (g.compareNums(f, "7,60,0,0") < 0 && g.compareNums(f, "7,50,0,0") >= 0) {
      d = e[0] + "," + e[1].charAt(0) + "," + e[1].charAt(1) + "," + e[2];
    } else {
      d = e[0] + "," + e[1] + "," + e[2] + "," + e[3];
    }
    const h = d.split(",");
    return h[0] + "," + h[1] + "," + h[2] + ",0";
  },
  getVersion: function () {
    let a = null;
    let d;
    const b = this.$;
    let e = true;
    if (!b.isIE) {
      if (navigator.platform && /linux/i.test(navigator.platform)) {
        e = false;
      }
      if (e) {
        d = b.findNavPlugin(["QuickTime", "(Plug-in|Plugin)"]);
        if (d && d.name && b.hasMimeType(this.mimeType)) {
          a = b.getNum(d.name);
        }
      }
      this.installed = a ? 1 : -1;
    } else {
      let c;
      if (b.IEver >= this.minIEver && b.getAXO(this.progID0, 1)) {
        a = b.codebaseSearch();
      } else {
        c = b.getAXO(this.progID);
        if (c && c.QuickTimeVersion) {
          a = c.QuickTimeVersion.toString(16);
          a = a.charAt(0) + "." + a.charAt(1) + "." + a.charAt(2);
        }
      }
      this.installed = a ? 1 : b.getAXO(this.progID0, 1) ? 0 : -1;
    }
    this.version = this.clipTo3digits(b.formatNum(a));
  },
};
PluginDetect.java = {
  mimeType: "application/x-java-applet",
  classID: "clsid:8AD9C840-044E-11D1-B3E9-00805F499D93",
  DTKclassID: "clsid:CAFEEFAC-DEC7-0000-0000-ABCDEFFEDCBA",
  DTKmimeType: "application/npruntime-scriptable-plugin;DeploymentToolkit",
  JavaVersions: [
    [1, 9, 2, 25],
    [1, 8, 2, 25],
    [1, 7, 2, 25],
    [1, 6, 2, 25],
    [1, 5, 2, 25],
    [1, 4, 2, 25],
    [1, 3, 1, 25],
  ],
  searchJavaPluginAXO: function () {
    let h = null;
    const c = this.$;
    let g = [];
    const j = [1, 5, 0, 14],
      i = [1, 6, 0, 2],
      f = [1, 3, 1, 0],
      e = [1, 4, 2, 0],
      d = [1, 5, 0, 7];
    let b = false;
    if (!c.ActiveXEnabled) {
      return null;
    }
    b = true;
    if (c.IEver >= this.minIEver) {
      g = this.searchJavaAXO(i, i, b);
      if (g.length > 0 && b) {
        g = this.searchJavaAXO(j, j, b);
      }
    } else {
      if (b) {
        g = this.searchJavaAXO(d, d, true);
      }
      if (g.length == 0) {
        g = this.searchJavaAXO(f, e, false);
      }
    }
    if (g.length > 0) {
      h = g[0];
    }
    this.JavaPlugin_versions = [].concat(g);
    return h;
  },
  searchJavaAXO: function (l: any, i: any, m: any) {
    let n, f;
    const h = this.$;
    let p, k, a, e, g, j, b;
    const q = [];
    if (h.compareNums(l.join(","), i.join(",")) > 0) {
      i = l;
    }
    i = h.formatNum(i.join(","));
    let o;
    const d = "1,4,2,0";
    const c =
      "JavaPlugin." +
      l[0] +
      "" +
      l[1] +
      "" +
      l[2] +
      "" +
      (l[3] > 0 ? "_" + (l[3] < 10 ? "0" : "") + l[3] : "");
    for (n = 0; n < this.JavaVersions.length; n++) {
      f = this.JavaVersions[n];
      p = "JavaPlugin." + f[0] + "" + f[1];
      g = f[0] + "." + f[1] + ".";
      for (a = f[2]; a >= 0; a--) {
        b = "JavaWebStart.isInstalled." + g + a + ".0";
        if (
          h.compareNums(f[0] + "," + f[1] + "," + a + ",0", i) >= 0 &&
          !h.getAXO(b, 1)
        ) {
          continue;
        }
        o =
          h.compareNums(f[0] + "," + f[1] + "," + a + ",0", d) < 0
            ? true
            : false;
        for (e = f[3]; e >= 0; e--) {
          k = a + "_" + (e < 10 ? "0" + e : e);
          j = p + k;
          if (h.getAXO(j, 1) && (o || h.getAXO(b, 1))) {
            q[q.length] = g + k;
            if (!m) {
              return q;
            }
          }
          if (j == c) {
            return q;
          }
        }
        if (h.getAXO(p + a, 1) && (o || h.getAXO(b, 1))) {
          q[q.length] = g + a;
          if (!m) {
            return q;
          }
        }
        if (p + a == c) {
          return q;
        }
      }
    }
    return q;
  },
  minIEver: 7,
  getFromMimeType: function (a: any) {
    let h, f;
    const c = this.$;
    const j = new RegExp(a);
    let d;
    let k;
    const i: Record<any, any> = {};
    let e = 0,
      b,
      g = [""];
    for (h = 0; h < navigator.mimeTypes.length; h++) {
      k = navigator.mimeTypes[h];
      if (j.test(k.type) && k.enabledPlugin) {
        k = k.type.substring(k.type.indexOf("=") + 1, k.type.length);
        d = "a" + c.formatNum(k);
        if (typeof i[d] == "undefined") {
          i[d] = k;
          e++;
        }
      }
    }
    for (f = 0; f < e; f++) {
      b = "0,0,0,0";
      for (h in i) {
        if (i[h]) {
          d = h.substring(1, h.length);
          if (c.compareNums(d, b) > 0) {
            b = d;
          }
        }
      }
      g[f] = i["a" + b];
      i["a" + b] = null;
    }
    if (!/windows|macintosh/i.test(navigator.userAgent)) {
      g = [g[0]];
    }
    return g;
  },
  queryJavaHandler: function () {
    const b = PluginDetect.java,
      a = window.java;
    b.hasRun = true;
    try {
      if (typeof a.lang != "undefined" && typeof a.lang.System != "undefined") {
        b.value = [
          a.lang.System.getProperty("java.version") + " ",
          a.lang.System.getProperty("java.vendor") + " ",
        ];
      }
    } catch (c) {}
  },
  queryJava: function () {
    const d = this.$,
      b = navigator.userAgent;
    if (
      typeof window.java != "undefined" &&
      navigator.javaEnabled() &&
      !this.hasRun
    ) {
      if (d.isGecko) {
        if (d.hasMimeType("application/x-java-vm")) {
          try {
            const g = document.createElement("div"),
              a = document.createEvent("HTMLEvents");
            a.initEvent("focus", false, true);
            g.addEventListener("focus", this.queryJavaHandler, false);
            g.dispatchEvent(a);
          } catch (f) {}
          if (!this.hasRun) {
            this.queryJavaHandler();
          }
        }
      } else {
        if (/opera.9\.(0|1)/i.test(b) && /mac/i.test(b)) {
        } else {
          if (!this.hasRun) {
            this.queryJavaHandler();
          }
        }
      }
    }
    return this.value;
  },
  forceVerifyTag: [],
  jar: [],
  VENDORS: ["Sun Microsystems Inc.", "Apple Computer, Inc."],
  init: function () {
    const b = this.$;
    if (typeof this.app != "undefined") {
      this.delJavaApplets(b);
    }
    this.hasRun = false;
    this.value = [null, null];
    this.useTag = [2, 2, 2];
    this.app = [0, 0, 0, 0, 0, 0];
    this.appi = 3;
    this.queryDTKresult = null;
    this.OTF = 0;
    this.BridgeResult = [
      [null, null],
      [null, null],
      [null, null],
    ];
    this.JavaActive = [0, 0, 0];
    this.All_versions = [];
    this.DeployTK_versions = [];
    this.MimeType_versions = [];
    this.JavaPlugin_versions = [];
    this.funcs = [];
    const c = this.NOTF;
    if (c) {
      c.$ = b;
      if (c.javaInterval) {
        clearInterval(c.javaInterval);
      }
      c.EventJavaReady = null;
      c.javaInterval = null;
      c.count = 0;
      c.intervalLength = 250;
      c.countMax = 40;
    }
    this.lateDetection = b.winLoaded;
    if (!this.lateDetection) {
      b.onWindowLoaded(this.delJavaApplets);
    }
  },
  getVersion: function (f: any, l: any) {
    let h;
    const g = this.$;
    let j = null,
      n = null,
      e = null;
    const c = navigator.javaEnabled();
    if (this.getVersionDone === null) {
      this.init();
    }
    let k;
    if (typeof l != "undefined" && l.constructor == Array) {
      for (k = 0; k < this.useTag.length; k++) {
        if (typeof l[k] == "number") {
          this.useTag[k] = l[k];
        }
      }
    }
    for (k = 0; k < this.forceVerifyTag.length; k++) {
      this.useTag[k] = this.forceVerifyTag[k];
    }
    if (typeof f != "undefined") {
      this.jar[this.jar.length] = f;
    }
    if (this.getVersionDone == 0) {
      if (!this.version || this.useAnyTag()) {
        h = this.queryExternalApplet(f);
        if (h[0]) {
          e = h[0];
          n = h[1];
        }
      }
      this.EndGetVersion(e, n);
      return;
    }
    const i = this.queryDeploymentToolKit();
    if (typeof i == "string" && i.length > 0) {
      j = i;
      n = this.VENDORS[0];
    }
    if (!g.isIE) {
      let q, m, b;
      const a = g.hasMimeType(this.mimeType);
      const o = a && c ? true : false;
      if (this.MimeType_versions.length == 0 && a) {
        h = this.getFromMimeType("application/x-java-applet.*jpi-version.*=");
        if (h[0] != "") {
          if (!j) {
            j = h[0];
          }
          this.MimeType_versions = h;
        }
      }
      if (!j && a) {
        h = "Java[^\\d]*Plug-in";
        b = g.findNavPlugin(h);
        if (b) {
          h = new RegExp(h, "i");
          q = h.test(b.description) ? g.getNum(b.description) : null;
          m = h.test(b.name) ? g.getNum(b.name) : null;
          if (q && m) {
            j = g.compareNums(g.formatNum(q), g.formatNum(m)) >= 0 ? q : m;
          } else {
            j = q || m;
          }
        }
      }
      if (!j && a && /macintosh.*safari/i.test(navigator.userAgent)) {
        b = g.findNavPlugin("Java.*\\d.*Plug-in.*Cocoa", false);
        if (b) {
          q = g.getNum(b.description);
          if (q) {
            j = q;
          }
        }
      }
      if (j) {
        this.version0 = j;
        if (c) {
          e = j;
        }
      }
      if (!e || this.useAnyTag()) {
        b = this.queryExternalApplet(f);
        if (b[0]) {
          e = b[0];
          n = b[1];
        }
      }
      if (!e) {
        b = this.queryJava();
        if (b[0]) {
          this.version0 = b[0];
          e = b[0];
          n = b[1];
          if (this.installed == -0.5) {
            this.installed = 0.5;
          }
        }
      }
      if (
        this.installed === null &&
        !e &&
        o &&
        !/macintosh.*ppc/i.test(navigator.userAgent)
      ) {
        h = this.getFromMimeType("application/x-java-applet.*version.*=");
        if (h[0] != "") {
          e = h[0];
        }
      }
      if (!e && o) {
        if (/macintosh.*safari/i.test(navigator.userAgent)) {
          if (this.installed === null) {
            this.installed = 0;
          } else {
            if (this.installed == -0.5) {
              this.installed = 0.5;
            }
          }
        }
      }
    } else {
      if (!j && i != -1) {
        j = this.searchJavaPluginAXO();
        if (j) {
          n = this.VENDORS[0];
        }
      }
      if (!j) {
        this.JavaFix();
      }
      if (j) {
        this.version0 = j;
        if (c && g.ActiveXEnabled) {
          e = j;
        }
      }
      if (!e || this.useAnyTag()) {
        h = this.queryExternalApplet(f);
        if (h[0]) {
          e = h[0];
          n = h[1];
        }
      }
    }
    if (this.installed === null) {
      this.installed = e ? 1 : j ? -0.2 : -1;
    }
    this.EndGetVersion(e, n);
  },
  EndGetVersion: function (b: any, d: any) {
    const c = this.$;
    if (this.version0) {
      this.version0 = c.formatNum(c.getNum(this.version0));
    }
    if (b) {
      this.version = c.formatNum(c.getNum(b));
      this.vendor = typeof d == "string" ? d : "";
    }
    if (this.getVersionDone != 1) {
      this.getVersionDone = 0;
    }
  },
  queryDeploymentToolKit: function () {
    const g = this.$;
    let b,
      h = null,
      a = null;
    if (
      (g.isGecko && g.compareNums(g.GeckoRV, g.formatNum("1.6")) <= 0) ||
      g.isSafari ||
      (g.isIE && !g.ActiveXEnabled)
    ) {
      this.queryDTKresult = 0;
    }
    if (this.queryDTKresult !== null) {
      return this.queryDTKresult;
    }
    if (g.isIE && g.IEver >= 6) {
      this.app[0] = g.instantiate("object", [], []);
      h = g.getObject(this.app[0]);
    } else {
      if (!g.isIE && g.hasMimeType(this.DTKmimeType)) {
        this.app[0] = g.instantiate("object", ["type", this.DTKmimeType], []);
        h = g.getObject(this.app[0]);
      }
    }
    if (h) {
      if (g.isIE && g.IEver >= 6) {
        try {
          h.classid = this.DTKclassID;
        } catch (i) {}
      }
      try {
        let c;
        const f = h.jvms;
        if (f) {
          a = f.getLength();
          if (typeof a == "number") {
            for (b = 0; b < a; b++) {
              c = f.get(a - 1 - b);
              if (c) {
                c = c.version;
                if (g.getNum(c)) {
                  this.DeployTK_versions[b] = c;
                }
              }
            }
          }
        }
      } catch (i) {}
    }
    g.hideObject(h);
    this.queryDTKresult =
      this.DeployTK_versions.length > 0
        ? this.DeployTK_versions[0]
        : a == 0
        ? -1
        : 0;
    return this.queryDTKresult;
  },
  queryExternalApplet: function (d: any) {
    const e = this.$,
      h = this.BridgeResult,
      b = this.app,
      g = this.appi,
      a = "&nbsp;&nbsp;&nbsp;&nbsp;";
    if (typeof d != "string" || !/\.jar\s*$/.test(d)) {
      return [null, null];
    }
    if (this.OTF < 1) {
      this.OTF = 1;
    }
    if (!e.isIE) {
      if (
        (e.isGecko || e.isChrome) &&
        !e.hasMimeType(this.mimeType) &&
        !this.queryJava()[0]
      ) {
        return [null, null];
      }
    }
    if (this.OTF < 2) {
      this.OTF = 2;
    }
    if (!b[g] && this.canUseObjectTag() && this.canUseThisTag(0)) {
      b[1] = e.instantiate("object", [], [], a);
      b[g] = e.isIE
        ? e.instantiate(
            "object",
            ["archive", d, "code", "A.class", "type", this.mimeType],
            [
              "archive",
              d,
              "code",
              "A.class",
              "mayscript",
              "true",
              "scriptable",
              "true",
            ],
            a
          )
        : e.instantiate(
            "object",
            ["archive", d, "classid", "java:A.class", "type", this.mimeType],
            ["archive", d, "mayscript", "true", "scriptable", "true"],
            a
          );
      h[0] = [0, 0];
      this.query1Applet(g);
    }
    if (!b[g + 1] && this.canUseAppletTag() && this.canUseThisTag(1)) {
      b[g + 1] = e.instantiate(
        "applet",
        ["archive", d, "code", "A.class", "alt", a, "mayscript", "true"],
        ["mayscript", "true"],
        a
      );
      h[1] = [0, 0];
      this.query1Applet(g + 1);
    }
    if (
      e.isIE &&
      !b[g + 2] &&
      this.canUseObjectTag() &&
      this.canUseThisTag(2)
    ) {
      b[g + 2] = e.instantiate(
        "object",
        ["classid", this.classID],
        [
          "archive",
          d,
          "code",
          "A.class",
          "mayscript",
          "true",
          "scriptable",
          "true",
        ],
        a
      );
      h[2] = [0, 0];
      this.query1Applet(g + 2);
    }
    let j,
      f = 0;
    for (j = 0; j < h.length; j++) {
      if (b[g + j] || this.canUseThisTag(j)) {
        f++;
      } else {
        break;
      }
    }
    if (f == h.length) {
      this.getVersionDone = 1;
      if (this.forceVerifyTag.length > 0) {
        this.getVersionDone = 0;
      }
    }
    return this.getBR();
  },
  canUseAppletTag: function () {
    return !this.$.isIE || navigator.javaEnabled() ? true : false;
  },
  canUseObjectTag: function () {
    return !this.$.isIE || this.$.ActiveXEnabled ? true : false;
  },
  useAnyTag: function () {
    let a;
    for (a = 0; a < this.useTag.length; a++) {
      if (this.canUseThisTag(a)) {
        return true;
      }
    }
    return false;
  },
  canUseThisTag: function (c: any) {
    const b = this.$;
    if (this.useTag[c] == 3) {
      return true;
    }
    if (
      !this.version0 ||
      !navigator.javaEnabled() ||
      (b.isIE && !b.ActiveXEnabled)
    ) {
      if (this.useTag[c] == 2) {
        return true;
      }
      if (this.useTag[c] == 1 && !this.getBR()[0]) {
        return true;
      }
    }
    return false;
  },
  getBR: function () {
    const b = this.BridgeResult;
    let a;
    for (a = 0; a < b.length; a++) {
      if (b[a][0]) {
        return [b[a][0], b[a][1]];
      }
    }
    return [b[0][0], b[0][1]];
  },
  delJavaApplets: function (b: any) {
    const c = b.java.app;
    let a;
    for (a = c.length - 1; a >= 0; a--) {
      b.uninstantiate(c[a]);
    }
  },
  query1Applet: function (g: any) {
    let f;
    const d = this.$;
    let a = null;
    let h = null;
    const b = d.getObject(this.app[g], true);
    try {
      if (b) {
        a = b.getVersion() + " ";
        h = b.getVendor() + " ";
        if (d.num(a)) {
          this.BridgeResult[g - this.appi] = [a, h];
          d.hideObject(this.app[g]);
        }
        if (d.isIE && a && b.readyState != 4) {
          d.garbage = true;
          d.uninstantiate(this.app[g]);
        }
      }
    } catch (f) {}
  },
  NOTF: {
    isJavaActive: () => null,
  },
  append: function (e: any, d: any) {
    for (let c = 0; c < d.length; c++) {
      e[e.length] = d[c];
    }
  },
  getInfo: function () {
    const d = this.$;
    let h;
    const l = this.installed;
    const m: Record<any, any> = {
      All_versions: [],
      DeployTK_versions: [],
      MimeType_versions: [],
      DeploymentToolkitPlugin: this.queryDTKresult == 0 ? false : true,
      vendor: typeof this.vendor == "string" ? this.vendor : "",
      OTF: this.OTF < 3 ? 0 : this.OTF == 3 ? 1 : 2,
    };
    const g: any[] = [null, null, null];
    for (h = 0; h < this.BridgeResult.length; h++) {
      g[h] = this.BridgeResult[h][0]
        ? 1
        : this.JavaActive[h] == 1
        ? 0
        : this.useTag[h] >= 1 &&
          this.OTF >= 1 &&
          this.OTF != 3 &&
          !(h == 2 && !d.isIE) &&
          (this.BridgeResult[h][0] !== null ||
            (h == 1 && !this.canUseAppletTag()) ||
            (h != 1 && !this.canUseObjectTag()) ||
            l == -0.2 ||
            l == -1)
        ? -1
        : null;
    }
    m.objectTag = g[0];
    m.appletTag = g[1];
    m.objectTagActiveX = g[2];
    const c = m.All_versions,
      k = m.DeployTK_versions,
      f = m.MimeType_versions,
      b = this.JavaPlugin_versions;
    this.append(k, this.DeployTK_versions);
    this.append(f, this.MimeType_versions);
    this.append(
      c,
      k.length > 0
        ? k
        : f.length > 0
        ? f
        : b.length > 0
        ? b
        : typeof this.version == "string"
        ? [this.version]
        : []
    );
    for (h = 0; h < c.length; h++) {
      c[h] = d.formatNum(d.getNum(c[h]));
    }
    let i,
      e = null;
    if (!d.isIE) {
      i =
        f.length > 0
          ? d.hasMimeType(this.mimeType + ";jpi-version=" + f[0])
          : d.hasMimeType(this.mimeType);
      if (i) {
        e = i.enabledPlugin;
      }
    }
    m.name = e ? e.name : "";
    m.description = e ? e.description : "";
    let j = null;
    if ((l == 0 || l == 1) && m.vendor == "") {
      if (/macintosh/i.test(navigator.userAgent)) {
        j = this.VENDORS[1];
      } else {
        if (!d.isIE && /windows/i.test(navigator.userAgent)) {
          j = this.VENDORS[0];
        } else {
          if (/linux/i.test(navigator.userAgent)) {
            j = this.VENDORS[0];
          }
        }
      }
      if (j) {
        m.vendor = j;
      }
    }
    return m;
  },
  JavaFix: () => null,
};
PluginDetect.devalvr = {
  mimeType: "application/x-devalvrx",
  progID: "DevalVRXCtrl.DevalVRXCtrl.1",
  classID: "clsid:5D2CF9D0-113A-476B-986F-288B54571614",
  getVersion: function () {
    let a = null,
      g;
    const c = this.$;
    if (!c.isIE) {
      g = c.findNavPlugin("DevalVR");
      if (g && g.name && c.hasMimeType(this.mimeType)) {
        a = g.description.split(" ")[3];
      }
      this.installed = a ? 1 : -1;
    } else {
      let b, d;
      const h = c.getAXO(this.progID, 1);
      if (h) {
        b = c.instantiate("object", ["classid", this.classID], ["src", ""]);
        d = c.getObject(b);
        if (d) {
          try {
            if (d.pluginversion) {
              a = "00000000" + d.pluginversion.toString(16);
              a = a.substr(a.length - 8, 8);
              a =
                parseInt(a.substr(0, 2), 16) +
                "," +
                parseInt(a.substr(2, 2), 16) +
                "," +
                parseInt(a.substr(4, 2), 16) +
                "," +
                parseInt(a.substr(6, 2), 16);
            }
          } catch (f) {}
        }
        c.uninstantiate(b);
      }
      this.installed = a ? 1 : h ? 0 : -1;
    }
    this.version = c.formatNum(a);
  },
};
PluginDetect.flash = {
  mimeType: ["application/x-shockwave-flash", "application/futuresplash"],
  progID: "ShockwaveFlash.ShockwaveFlash",
  classID: "clsid:D27CDB6E-AE6D-11CF-96B8-444553540000",
  getVersion: function () {
    const c = function (i: any) {
      if (!i) {
        return null;
      }
      const e = /[\d][\d\,\.\s]*[rRdD]{0,1}[\d\,]*/.exec(i);
      return e ? e[0].replace(/[rRdD\.]/g, ",").replace(/\s/g, "") : null;
    };
    let j;
    const g = this.$;
    let f,
      b = null,
      a = null,
      d = null;
    if (!g.isIE) {
      j = g.findNavPlugin("Flash");
      if (j && j.description && g.hasMimeType(this.mimeType)) {
        b = c(j.description);
      }
    } else {
      for (f = 15; f > 2; f--) {
        a = g.getAXO(this.progID + "." + f);
        if (a) {
          d = f.toString();
          break;
        }
      }
      if (d == "6") {
        try {
          a.AllowScriptAccess = "always";
        } catch (h) {
          return "6,0,21,0";
        }
      }
      try {
        b = c(a.GetVariable("$version"));
      } catch (h) {}
      if (!b && d) {
        b = d;
      }
    }
    this.installed = b ? 1 : -1;
    this.version = g.formatNum(b);
    return true;
  },
};
PluginDetect.shockwave = {
  mimeType: "application/x-director",
  progID: "SWCtl.SWCtl",
  classID: "clsid:166B1BCA-3F9C-11CF-8075-444553540000",
  getVersion: function () {
    let a = null,
      b = null,
      d;
    const c = this.$;
    if (!c.isIE) {
      d = c.findNavPlugin("Shockwave for Director");
      if (d && d.description && c.hasMimeType(this.mimeType)) {
        a = c.getNum(d.description);
      }
    } else {
      try {
        b = c.getAXO(this.progID).ShockwaveVersion("");
      } catch (f) {}
      if (typeof b == "string" && b.length > 0) {
        a = c.getNum(b);
      } else {
        if (c.getAXO(this.progID + ".8", 1)) {
          a = "8";
        } else {
          if (c.getAXO(this.progID + ".7", 1)) {
            a = "7";
          } else {
            if (c.getAXO(this.progID + ".1", 1)) {
              a = "6";
            }
          }
        }
      }
    }
    this.installed = a ? 1 : -1;
    this.version = c.formatNum(a);
  },
};
PluginDetect.div = null;
PluginDetect.pluginSize = 1;
PluginDetect.DOMbody = null;
PluginDetect.uninstantiate = function (a: any) {
  if (!a) {
    return;
  }
  try {
    if (a[0] && a[0].firstChild) {
      a[0].removeChild(a[0].firstChild);
    }
    if (a[0] && this.div) {
      this.div.removeChild(a[0]);
    }
    if (this.div && this.div.childNodes.length == 0) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
      if (this.DOMbody && this.DOMbody.parentNode) {
        this.DOMbody.parentNode.removeChild(this.DOMbody);
      }
      this.DOMbody = null;
    }
    a[0] = null;
  } catch (c) {}
};
PluginDetect.getObject = function (b: any, a: any) {
  let d = null;
  try {
    if (b && b[0] && b[0].firstChild) {
      d = b[0].firstChild;
    }
  } catch (f) {}
  try {
    if (
      a &&
      d &&
      typeof d.focus != "undefined" &&
      typeof document.hasFocus != "undefined" &&
      !document.hasFocus()
    ) {
      d.focus();
    }
  } catch (f) {}
  return d;
};
PluginDetect.getContainer = function (a: any) {
  let b = null;
  if (a && a[0]) {
    b = a[0];
  }
  return b;
};
PluginDetect.hideObject = function (a: any) {
  const b = this.getObject(a);
  if (b && b.style) {
    b.style.height = "0";
  }
};
PluginDetect.instantiate = function (h: any, b: any, c: any, a: any) {
  const j = (d: any) => {
    const e = d.style;
    if (!e) {
      return;
    }
    e.border = "0px";
    e.padding = "0px";
    e.margin = "0px";
    e.fontSize = this.pluginSize + 3 + "px";
    e.height = this.pluginSize + 3 + "px";
    e.visibility = "visible";
    if (d.tagName && d.tagName.toLowerCase() == "div") {
      e.width = "100%";
      e.display = "block";
    } else {
      if (d.tagName && d.tagName.toLowerCase() == "span") {
        e.width = this.pluginSize + "px";
        e.display = "inline";
      }
    }
  };
  const l = document;
  let p;
  let i = l.getElementsByTagName("body")[0] || l.body;
  const o = l.createElement("span");
  let n;
  let f;
  const m = "/";
  if (typeof a == "undefined") {
    a = "";
  }
  p =
    "<" +
    h +
    ' width="' +
    this.pluginSize +
    '" height="' +
    this.pluginSize +
    '" ';
  for (n = 0; n < b.length; n = n + 2) {
    p += b[n] + '="' + b[n + 1] + '" ';
  }
  p += ">";
  for (n = 0; n < c.length; n = n + 2) {
    p += '<param name="' + c[n] + '" value="' + c[n + 1] + '" />';
  }
  p += a + "<" + m + h + ">";
  if (!this.div) {
    this.div = l.createElement("div");
    f = l.getElementById("plugindetect");
    if (f) {
      j(f);
      f.appendChild(this.div);
    } else {
      if (i) {
        try {
          if (i.firstChild && typeof i.insertBefore != "undefined") {
            i.insertBefore(this.div, i.firstChild);
          } else {
            i.appendChild(this.div);
          }
        } catch (k) {}
      } else {
        try {
          l.write('<div id="bkjew9343240">o<' + m + "div>");
          i = l.getElementsByTagName("body")[0] || l.body;
          i.appendChild(this.div);
          i.removeChild(l.getElementById("bkjew9343240") as HTMLElement);
        } catch (k) {
          try {
            this.DOMbody = l.createElement("body");
            l.getElementsByTagName("html")[0].appendChild(this.DOMbody);
            this.DOMbody.appendChild(this.div);
          } catch (k) {}
        }
      }
    }
    j(this.div);
  }
  if (this.div && this.div.parentNode && this.div.parentNode.parentNode) {
    this.div.appendChild(o);
    try {
      o.innerHTML = p;
    } catch (k) {}
    j(o);
    return [o];
  }
  return [null];
};
PluginDetect.windowsmediaplayer = {
  mimeType: ["application/x-mplayer2", "application/asx"],
  progID: "wmplayer.ocx",
  classID: "clsid:6BF52A52-394A-11D3-B153-00C04F79FAA6",
  getVersion: function () {
    let a = null;
    const e = this.$;
    let b = null;
    this.installed = -1;
    if (!e.isIE) {
      if (e.hasMimeType(this.mimeType)) {
        if (
          e.findNavPlugin(["Windows", "Media", "(Plug-in|Plugin)"], false) ||
          e.findNavPlugin(["Flip4Mac", "Windows", "Media"], false)
        ) {
          this.installed = 0;
        }
        const d = e.isGecko && e.compareNums(e.GeckoRV, e.formatNum("1.8")) < 0;
        if (
          !d &&
          e.findNavPlugin(["Windows", "Media", "Firefox Plugin"], false)
        ) {
          const c = e.instantiate("object", ["type", this.mimeType[0]], []),
            f = e.getObject(c);
          if (f) {
            a = f.versionInfo;
          }
          e.uninstantiate(c);
        }
      }
    } else {
      b = e.getAXO(this.progID);
      if (b) {
        a = b.versionInfo;
      }
    }
    if (a) {
      this.installed = 1;
    }
    this.version = e.formatNum(a);
  },
};
PluginDetect.silverlight = {
  mimeType: "application/x-silverlight",
  progID: "AgControl.AgControl",
  digits: [9, 20, 9, 12, 31],
  getVersion: function () {
    const c = this.$;
    let g = null,
      b: any = null,
      f = false;
    if (!c.isIE) {
      const e = c.findNavPlugin("Silverlight Plug-in", false),
        h = c.isGecko && c.compareNums(c.GeckoRV, c.formatNum("1.6")) <= 0;
      if (e && c.hasMimeType(this.mimeType)) {
        g = c.formatNum(e.description);
        if (g) {
          const p = g.split(",");
          if (parseInt(p[2], 10) >= 30226 && parseInt(p[0], 10) < 2) {
            p[0] = "2";
          }
          g = p.join(",");
        }
        if (c.isGecko && !h) {
          f = true;
        }
        if (!f && !h && g) {
          const a = c.instantiate("object", ["type", this.mimeType], []);
          b = c.getObject(a);
          if (b) {
            if (typeof b.IsVersionSupported != "undefined") {
              f = true;
            }
            if (!f) {
              b.data = "data:" + this.mimeType + ",";
              if (typeof b.IsVersionSupported != "undefined") {
                f = true;
              }
            }
          }
          c.uninstantiate(a);
        }
      }
    } else {
      b = c.getAXO(this.progID);
      const p = [1, 0, 1, 1, 1];
      let l, k, o;
      const i = function (d: any) {
          return (d < 10 ? "0" : "") + d.toString();
        },
        m = function (q: any, d: any, s: any, t: any, r: any) {
          return q + "." + d + "." + s + i(t) + i(r) + ".0";
        },
        n = function (d: any, s: any) {
          const r = m(
            d == 0 ? s : p[0],
            d == 1 ? s : p[1],
            d == 2 ? s : p[2],
            d == 3 ? s : p[3],
            d == 4 ? s : p[4]
          );
          try {
            return b.IsVersionSupported(r);
          } catch (q) {}
          return false;
        };
      if (b && typeof b.IsVersionSupported != "undefined") {
        for (l = 0; l < this.digits.length; l++) {
          o = p[l];
          for (k = o + (l == 0 ? 0 : 1); k <= this.digits[l]; k++) {
            if (n(l, k)) {
              f = true;
              p[l] = k;
            } else {
              break;
            }
          }
          if (!f) {
            break;
          }
        }
        if (f) {
          g = m(p[0], p[1], p[2], p[3], p[4]);
        }
      }
    }
    this.installed = f ? 1 : -1;
    this.version = c.formatNum(g);
  },
};
PluginDetect.vlc = {
  mimeType: "application/x-vlc-plugin",
  progID: "VideoLAN.VLCPlugin",
  compareNums: function (d: any, c: any) {
    const j = d.split(","),
      h = c.split(",");
    let g, b, a, f, e, i;
    for (g = 0; g < Math.min(j.length, h.length); g++) {
      i = /([\d]+)([a-z]?)/.test(j[g]);
      b = parseInt(RegExp.$1, 10);
      f = g == 2 && RegExp.$2.length > 0 ? RegExp.$2.charCodeAt(0) : -1;
      i = /([\d]+)([a-z]?)/.test(h[g]);
      a = parseInt(RegExp.$1, 10);
      e = g == 2 && RegExp.$2.length > 0 ? RegExp.$2.charCodeAt(0) : -1;
      if (b != a) {
        return b > a ? 1 : -1;
      }
      if (g == 2 && f != e) {
        return f > e ? 1 : -1;
      }
    }
    return 0;
  },
  getVersion: function () {
    const b = this.$;
    let d;
    let a = null;
    if (!b.isIE) {
      if (b.hasMimeType(this.mimeType)) {
        d = b.findNavPlugin(["VLC", "(Plug-in|Plugin)"], false);
        if (d && d.description) {
          a = b.getNum(d.description, "[\\d][\\d\\.]*[a-z]*");
        }
      }
      this.installed = a ? 1 : -1;
    } else {
      d = b.getAXO(this.progID);
      if (d) {
        try {
          a = b.getNum(d.VersionInfo, "[\\d][\\d\\.]*[a-z]*");
        } catch (c) {}
      }
      this.installed = d ? 1 : -1;
    }
    this.version = b.formatNum(a);
  },
};
PluginDetect.initScript();

export default PluginDetect;
