import { atomFamily, selectorFamily } from "recoil";

import { LRS } from "../../../../constants/general";
import { sendRequest } from "../../../../core/api/request";
import type { ClassTopicDataType } from "../types";
import { requestClassItemData } from "./class_item";

type Params = {
  classId: number;
  topicId: number;
};

const requestClassTopicData = async ({
  classId,
  topicId,
}: Params): Promise<ClassTopicDataType> => {
  const classItemData = await requestClassItemData(classId);

  const usersIds =
    classItemData.response.pupils.map((v) => v.id as number) ?? [];
  if (usersIds.length === 0) {
    return {
      cursor: null,
      response: {
        classItem: classItemData.response,
        results: {
          analytics: {
            resultByDuration: [],
            resultByPercents: [],
            resultByTopicIds: null,
          },
          averagesByTopicIdsUserIds: {},
          resultsByActivityIdUserId: {},
          topics: {},
        },
      },
      status: "SUCCESS",
    };
  }

  const resultsData = await sendRequest({
    bodyData: {
      topicsIds: [topicId],
      usersIds,
    },
    method: "POST",
    url: `${LRS.endpoint}results/users/detailed/`,
  })
    .then(({ cursor, data: successData }) => ({
      cursor,
      response: {
        classItem: classItemData.response,
        results: successData,
      },
      status: "SUCCESS",
    }))
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (resultsData.status === "ERROR") {
    throw resultsData;
  }
  return resultsData;
};

const classTopicDataRequestId = atomFamily({
  key: "classTopicDataRequestId",
  default: 0,
});

const classTopicData = atomFamily<ClassTopicDataType | null, Params>({
  key: "classTopicData",
  default: null,
});

export const classTopicDataQuery = selectorFamily<ClassTopicDataType, Params>({
  key: "classTopicDataQuery",
  get: (params) => async ({ get }) => {
    get(classTopicDataRequestId(params)); // Add request ID as a dependency
    const newClassTopicData = get(classTopicData(params));
    if (newClassTopicData !== null) {
      return newClassTopicData;
    }
    return await requestClassTopicData(params);
  },
  set: (params) => ({ set }, newValue) => {
    if (newValue === null) {
      set(classTopicDataRequestId(params), (requestId) => requestId + 1);
    }
    set(classTopicData(params), newValue);
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
