import type { FC } from "react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { useOnChangeDataItem } from "../../../../../hooks/on_change_data_item";
import { useOnSubmitClick } from "../../../../../hooks/on_submit_click";
import { useResetFormStatus } from "../../../../../hooks/reset_form_status";
import {
  classEditorRequest,
  useClassEditorSubmit,
} from "../../state/class_editor";
import type { ClassEditor, ClassItemDetailedType } from "../../types";
import { ClassEditorView } from "../class_editor_view/class_editor_view";
import { ClassEditorSuccess } from "../class_editor_success/class_editor_success";

const defaultErrorMessage = (
  <>
    Ошибка :( Класс не добавлен. Проверьте настройки и&nbsp;попробуйте добавить
    ещё раз.
  </>
);
const defaultSuccessMessage = <>Настройки класса сохранены</>;

type Props = {
  item?: ClassItemDetailedType;
};

export const ClassEditorContainer: FC<Props> = ({ item }: Props) => {
  const [request, setRequest] = useRecoilState(classEditorRequest);
  const inputClass = useRef(null);
  const inputTitle = useRef(null);
  const defaultFormData = useMemo(
    () =>
      ({
        classId: {
          error: false,
          errorChecker: (value) => parseInt(value, 10) === 0,
          ref: inputClass,
          value: String(item?.strClassId ?? "0"),
        },
        pupilsIds: {
          error: false,
          value: item?.pupils.map((v) => v.id) ?? [],
        },
        title: {
          error: false,
          errorChecker: (value) => value.trim() === "",
          ref: inputTitle,
          value: item?.title ?? "",
        },
        type: {
          value: item?.type ?? "ITEM",
        },
      } as ClassEditor.State),
    [item]
  );
  const [classEditorData, setClassEditorData] = useState<ClassEditor.State>(
    defaultFormData
  );
  const handleSend = useCallback(() => {
    if (item === undefined) {
      setClassEditorData(defaultFormData);
    }
  }, [defaultFormData, item]);
  const classEditorSubmit = useClassEditorSubmit({
    id: item?.id,
    onSuccess: handleSend,
  });
  const handleChangeDataItem = useOnChangeDataItem(setClassEditorData);
  const handleSubmit = useOnSubmitClick(
    classEditorData,
    request.status,
    setClassEditorData,
    classEditorSubmit
  );
  useResetFormStatus(setRequest);

  const handleClick = () =>
    setRequest({
      status: "DEFAULT",
    });

  if (request.status === "SUCCESS" && item === undefined) {
    return <ClassEditorSuccess onClick={handleClick} />;
  }

  return (
    <ClassEditorView
      buttonTitle={item ? "Сохранить" : "Добавить"}
      classRef={inputClass}
      data={classEditorData}
      errorMessage={request.response?.message ?? defaultErrorMessage}
      onChangeDataItem={handleChangeDataItem}
      onSubmit={handleSubmit}
      showError={request.status === "ERROR"}
      showLoader={request.status === "REQUEST"}
      showSuccess={request.status === "SUCCESS" && item !== undefined}
      successMessage={defaultSuccessMessage}
      titleRef={inputTitle}
    />
  );
};
