import type { FC } from "react";
import React from "react";

export const SignUpSuccessView: FC = () => (
  <>
    <h1>Спасибо за регистрацию!</h1>
    <p>
      Если на указанную электронную почту не пришло письмо, то&nbsp;попробуйте
      поискать его в спаме.
    </p>
  </>
);
