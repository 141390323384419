import type { FC } from "react";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { LRS } from "../../../constants/general";
import { getJwtToken } from "../../../core/jwt_token";
import { currentUserDataQuery, topicDataQuery } from "../../../state";
import { Title } from "../../basic/title/title";

const ruuid = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // tslint:disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // tslint:disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

const defaultIframeHeight = 100;

// https://aicc.github.io/CMI-5_Spec_Current/best_practices/
// The Fetch URL should be unique for each session.
// The Fetch URL must only return an auth token on the first call. (Subsequent calls must return an error – i.e. it should be a “one time use” URL)
// The Fetch URL should not reuse auth tokens.
// The Fetch URL should return a 4xx HTTP error if an HTTP method other than POST is used.
// Since the Fetch URL can only be called once, the auth token should be stored in non-volatile storage (see best practice “Persist AU Session State”).
// A url to retrieve an access token for your XAPI server.

// The root endpoint for your XAPI server.
const endpoint = encodeURIComponent(LRS.endpoint);

type Props = {
  isAuthorized: boolean;
} & RouteComponentProps<{
  contentId: string;
  topicId: string;
}>;

const CourseContentContainer: FC<any> = (props: Props) => {
  const jwtToken = getJwtToken();
  const fetch = useMemo(
    () => encodeURIComponent(`${LRS.fetchURL}${jwtToken}`),
    [jwtToken]
  );

  const contentId = parseInt(props.match.params.contentId, 10);
  const topicId = parseInt(props.match.params.topicId, 10);
  const { content, data } = useRecoilValue(topicDataQuery({ topicId }));
  const contentItem = content.find((v) => v.id === contentId);
  const { response: userData } = useRecoilValue(currentUserDataQuery);
  // The Actor property will be defined by the LMS.
  // The Actor property for all "cmi5 defined" statements MUST be of objectType "Agent"
  // and MUST contain an "account" as defined in the xAPI specification.
  // Account for which results will be applied (passed as a json XAPI actor object).
  const actor = useMemo(
    () =>
      encodeURIComponent(
        JSON.stringify({
          // See more https://www.devlinpeck.com/content/send-custom-xapi-statements
          // For mbox case cmi5.js lib throw error if account field is empty
          account: {
            homePage: `${window.location.origin}`,
            name: `${userData?.email}`,
          },
          mbox: `mailto:${userData?.email}`,
          name: `${userData?.lastName} ${userData?.firstName} ${userData?.middleName}`,
          objectType: "Agent",
          // openid: `https://rus-na5.ru/users/${userData?.email}`,
        })
      ),
    [userData]
  );
  // IRI/id for the XAPI object this assignable unit represents (callbacks to 'passed', 'failed' etc. will use this activity id).
  const activityId = useMemo(
    () => encodeURIComponent(contentItem?.activityId ?? ""),
    [contentItem?.activityId]
  );
  // Basically an XAPI session id.
  const registration = useMemo(() => {
    const sessionId = ruuid();
    return encodeURIComponent(sessionId);
  }, []);

  const time = useMemo(() => new Date().getTime(), []);

  const [heightStyle, setHeightStyle] = useState({
    height: defaultIframeHeight,
  });
  const [fillSpace, setFillSpace] = useState(false);
  const fillSpaceStyle = useMemo(() => {
    const headerHeight = "96px";
    const titleHeightWithMarginTop = "64px";
    const titleMarginBottom = "16px";
    const thumbnailsAndFile = "210px";
    const additionalFile = "88px";
    return {
      height: `calc(100vh - ${headerHeight} - ${titleHeightWithMarginTop} - ${titleMarginBottom} + ${thumbnailsAndFile} + ${additionalFile})`,
    };
  }, []);
  const iframeRef = useRef(null);
  useEffect(() => {
    // Using HTML5 postMessage allows you to send data messages between two windows/frames across domains.
    const handleMessage = (e: any) => {
      const messageData = e.data ? JSON.parse(e.data) : null;
      setHeightStyle({
        height: messageData?.documentHeight || defaultIframeHeight,
      });
      if (messageData?.fillSpace != null) {
        setFillSpace(messageData.fillSpace);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  if (!contentItem || !data) {
    return (
      <div className="course-content__title">
        <Title backUrl="/education/" title="Учебный материал не найден" />
      </div>
    );
  }

  const symbol = contentItem.url.indexOf("?") === -1 ? "?" : "&";
  const src = `${contentItem.url}${symbol}fetch=${fetch}&endpoint=${endpoint}&activityId=${activityId}&registration=${registration}&actor=${actor}&time=${time}`;

  return (
    <div className="course-content">
      <div className="course-content__title">
        <Title backUrl={`/topic/${topicId}/`} title={data?.title} />
      </div>
      <iframe
        allow="fullscreen *"
        className="course-content__iframe"
        frameBorder="0"
        ref={iframeRef}
        scrolling="no"
        src={src}
        style={fillSpace ? fillSpaceStyle : heightStyle}
        title="cmi5"
        width="100%"
      ></iframe>
    </div>
  );
};

export default memo(CourseContentContainer);
