import type { FC, KeyboardEvent } from "react";
import React, { useCallback } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";

import { errorColors } from "../../../constants/general";
import contractOfOfferDoc from "../../../documents/contract_of_offer.pdf";
import privacyPolicyDoc from "../../../documents/privacy_policy.pdf";
import { SignUpForm } from "../../../types/main.d";

const errorStyle = { color: errorColors.main };

type Props = {
  data: SignUpForm.State;
  emailRef: any;
  errorMessage?: string;
  onChangeDataItem: (fieldName: SignUpForm.FieldName, value: any) => void;
  onSubmit: () => void;
  passwordRef: any;
  showError: boolean;
  showLoader: boolean;
};

export const SignUpFormView: FC<Props> = ({
  data,
  emailRef,
  errorMessage,
  onChangeDataItem,
  onSubmit,
  passwordRef,
  showError,
  showLoader,
}: Props) => {
  const handleChangeValue = (
    fieldName: SignUpForm.FieldName,
    eventFieldName = "value"
  ) => (event: any) =>
    onChangeDataItem(fieldName, event.target[eventFieldName]);
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    },
    [onSubmit]
  );

  return (
    <div className="sign-up-form">
      <h1>Регистрация</h1>
      <div className="sign-up-form__row">
        <TextField
          autoFocus={true}
          className="sign-up-form__field"
          color="secondary"
          disabled={showLoader}
          id="lastName"
          label="Фамилия"
          onChange={handleChangeValue("lastName")}
          onKeyPress={handleKeyPress}
          variant="outlined"
          value={data.lastName.value}
        />
      </div>
      <div className="sign-up-form__row">
        <TextField
          className="sign-up-form__field"
          color="secondary"
          disabled={showLoader}
          id="firstName"
          label="Имя"
          onChange={handleChangeValue("firstName")}
          onKeyPress={handleKeyPress}
          variant="outlined"
          value={data.firstName.value}
        />
      </div>
      <div className="sign-up-form__row">
        <TextField
          className="sign-up-form__field"
          color="secondary"
          disabled={showLoader}
          id="middleName"
          label="Отчество"
          onChange={handleChangeValue("middleName")}
          onKeyPress={handleKeyPress}
          variant="outlined"
          value={data.middleName.value}
        />
      </div>
      <div className="sign-up-form__row">
        <TextField
          className="sign-up-form__field"
          color="secondary"
          disabled={showLoader}
          error={data.email.error}
          id="outlined-basic"
          inputRef={emailRef}
          label="Email"
          onChange={handleChangeValue("email")}
          onKeyPress={handleKeyPress}
          variant="outlined"
          value={data.email.value}
        />
      </div>
      <div className="sign-up-form__row">
        <TextField
          className="sign-up-form__field sign-up-form__field--width--small"
          color="secondary"
          disabled={showLoader}
          error={data.password.error}
          helperText={data.password.helperText}
          id="password"
          inputRef={passwordRef}
          label="Пароль"
          onChange={handleChangeValue("password")}
          onKeyPress={handleKeyPress}
          type="password"
          variant="outlined"
          value={data.password.value}
        />
      </div>
      <div
        className="sign-up-form__row"
        style={data.status.error ? errorStyle : undefined}
      >
        <FormControl component="fieldset" disabled={showLoader}>
          <RadioGroup
            name="status"
            onChange={handleChangeValue("status")}
            row={true}
            value={data.status.value}
          >
            <FormControlLabel
              value={"PUPIL" as SignUpForm.Status}
              control={<Radio />}
              label="Ученик"
            />
            <FormControlLabel
              value={"TEACHER" as SignUpForm.Status}
              control={<Radio />}
              label="Преподаватель"
            />
          </RadioGroup>
        </FormControl>
      </div>
      {data.status.value === "PUPIL" && (
        <div className="sign-up-form__row">
          <FormControl
            className="sign-up-form__field"
            disabled={showLoader}
            variant="outlined"
          >
            <InputLabel id="class-label">Параллель</InputLabel>
            <Select
              id="class"
              label="Параллель"
              labelId="class-label"
              native={true}
              onChange={handleChangeValue("class")}
              value={data.class.value}
            >
              <option value={0}>— не указана —</option>
              <option value={1}>1 класс</option>
              <option value={2}>2 класс</option>
              <option value={3}>3 класс</option>
              <option value={4}>4 класс</option>
              <option value={5}>5 класс</option>
              <option value={6}>6 класс</option>
              <option value={7}>7 класс</option>
              <option value={8}>8 класс</option>
              <option value={9}>9 класс</option>
              <option value={10}>10 класс</option>
              <option value={11}>11 класс</option>
            </Select>
          </FormControl>
        </div>
      )}
      <div className="sign-up-form__row">
        <FormControlLabel
          control={
            <Checkbox
              checked={data.isAgreedTerms.value}
              name="isAgreedTerms"
              onChange={handleChangeValue("isAgreedTerms", "checked")}
            />
          }
          disabled={showLoader}
          label={
            <>
              Я принимаю условия{" "}
              <a
                href={contractOfOfferDoc}
                rel="noreferrer"
                target="_blank"
                download="Договор оферты.pdf"
              >
                соглашения
              </a>{" "}
              и&nbsp;даю своё согласие на&nbsp;
              <a
                href={privacyPolicyDoc}
                rel="noreferrer"
                target="_blank"
                download="Политика конфиденциальности.pdf"
              >
                передачу и&nbsp;обработку персональных данных и&nbsp;cookies
              </a>
            </>
          }
          style={data.isAgreedTerms.error ? errorStyle : undefined}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={data.isAgreedSubscription.value}
              name="isAgreedSubscription"
              onChange={handleChangeValue("isAgreedSubscription", "checked")}
            />
          }
          disabled={showLoader}
          label="Я даю своё согласие на&nbsp;получение информационной рассылки"
        />
      </div>
      <div className="sign-up-form__row  sign-up-form__row--flex">
        <div className="sign-up-form__submit">
          <Button
            color="primary"
            disabled={showLoader}
            onClick={onSubmit}
            size="large"
            variant="contained"
          >
            Зарегистрироваться
          </Button>
          {showLoader && (
            <CircularProgress
              size={24}
              className="sign-up-form__submit-progress"
            />
          )}
        </div>
      </div>
      {showError && (
        <div className="sign-up-form__row" style={errorStyle}>
          {errorMessage ||
            "Ошибка :( Проверьте введённые данные и попробуйте зарегистрироваться ещё раз."}
        </div>
      )}
    </div>
  );
};
