import type { FC } from "react";
import React from "react";
import type { ButtonProps } from "@material-ui/core";
import { Button, CircularProgress } from "@material-ui/core";

import "./loading_button.scss";

type Props = {
    children?: JSX.Element;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
    size?: ButtonProps["size"];
    text?: string;
    variant: ButtonProps["variant"];
};

export const LoadingButton: FC<Props> = ({
    children,
    disabled,
    loading,
    onClick,
    size = "medium",
    text,
    variant,
}: Props) => (
    <div className="loading-button">
        <Button color="primary" disabled={disabled || loading} onClick={onClick} size={size} variant={variant}>
            {children ?? text}
        </Button>
        {loading && <CircularProgress size={24} className="loading-button__progress" />}
    </div>
);
