import type { FC } from "react";
import React from "react";
import { Link } from "react-router-dom";

import "./title.scss";

type Props = {
    backUrl?: string;
    rightContent?: JSX.Element | null;
    title: string;
};

export const Title: FC<Props> = ({ backUrl, rightContent, title }: Props) => (
    <div>
        {backUrl !== undefined && (
            <Link to={backUrl} className="title__back">
                &larr; Назад
            </Link>
        )}
        {rightContent && <div className="title__right">{rightContent}</div>}
        <h1>{title}</h1>
    </div>
);
