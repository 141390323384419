import type { FC } from "react";
import React, { memo } from "react";
import { useRecoilState } from "recoil";
import plural from "plural-ru";

import { indicatorsDataQuery } from "../../state/indicators";

import "./indicators.scss";

type Props = {
    userId?: number;
};

const IndicatorsFC: FC<Props> = ({ userId = 0 }: Props) => {
    const recoilState = indicatorsDataQuery(userId);
    const [{ response: indicatorsData }] = useRecoilState(recoilState);

    if (!indicatorsData) {
        return null;
    }

    return (
        <div className="indicators">
            {indicatorsData.classesWithoutInterruptionDays !== undefined && (
                <div className="indicators__item">
                    <div className="indicators__item-title">Ударный режим</div>
                    <div className="indicators__item-description">Количество дней занятий без&nbsp;перерыва</div>
                    <div className="indicators__item-value">
                        <span>{indicatorsData.classesWithoutInterruptionDays}</span>{" "}
                        {plural(indicatorsData.classesWithoutInterruptionDays, "день", "дня", "дней")}
                    </div>
                </div>
            )}
            {indicatorsData.totalScore !== undefined && (
                <div className="indicators__item">
                    <div className="indicators__item-title">Очки опыта</div>
                    <div className="indicators__item-description">Общая сумма всех набранных баллов</div>
                    <div className="indicators__item-value">
                        <span>{indicatorsData.totalScore}</span>{" "}
                        {plural(indicatorsData.totalScore, "балл", "балла", "баллов")}
                    </div>
                </div>
            )}
            {indicatorsData.weeklyRatingTop3Count !== undefined && (
                <div className="indicators__item">
                    <div className="indicators__item-title">Количество топ-3</div>
                    <div className="indicators__item-description">
                        Попадания в тройку лидеров по&nbsp;итогам недельного рейтинга
                    </div>
                    <div className="indicators__item-value">
                        <span>{indicatorsData.weeklyRatingTop3Count}</span>{" "}
                        {plural(indicatorsData.weeklyRatingTop3Count, "раз", "раза", "раз")}
                    </div>
                </div>
            )}
        </div>
    );
};

export const Indicators = memo(IndicatorsFC);
