import PluginDetect from "./plugin_detect";
import XJS from "./core";

declare let ActiveXObject: any;
declare let window: any;

function iloc(placeholder: string) {
  if (placeholder === "Unknown") {
    return "Unknown";
  }
  return "";
}

const CanvasFingerprintText = "Стань грамотным! Стать грамотным может каждый!";

/*
 * To change this template, choose Tools | Templates
 * and open the template in the editor.
 */
/* Класс необходимый для получения Device Fingerprint на клиенте */
XJS.DFP = function () {
  this._ScreenSettings = "";
  this._Separator = "|";
  this._Oses = [
    "winnt",
    "win32",
    "win98",
    "windows",
    "linux",
    "bsd",
    "mac os",
    "darwin",
    "minix",
    "aix",
    "irix",
    "hpux",
    "qnx",
    "sunos",
    "solaris",
    "hurd",
    "symbian",
    "nintendo",
    "psp",
    "playstation",
    "nitro",
    "netware",
    "beos",
    "plan",
    "freedos",
    "reactos",
    "junos",
    "inferno",
    "android",
    "openmoko",
    "mobilinux",
    "palm",
    "javafx",
    "centos",
  ];
  this._Mobiles = ["sonyericsson", "nokia", "motorola"];
};

let dfp = XJS.DFP.prototype;

dfp.getUserAgent = function () {
  if (!this._UserAgent)
    this._UserAgent = window.navigator.userAgent.toLowerCase();
  return this._UserAgent;
};

dfp.getPlatform = function () {
  return window.navigator.platform;
};

dfp.getScreenSettings = function () {
  if (!this._ScreenSettings) {
    if (window.self.screen)
      this._ScreenSettings +=
        window.screen.colorDepth +
        this._Separator +
        window.screen.width +
        this._Separator +
        window.screen.height +
        this._Separator +
        window.screen.availHeight;
  }
  return this._ScreenSettings;
};

dfp.getTimeZone = function () {
  return new Date().getTimezoneOffset();
};

dfp.getPlugins = function () {
  // fetch and serialize plugins
  let plugins = "";
  // in Mozilla and in fact most non-IE browsers, this is easy
  if (window.navigator.plugins) {
    const np = window.navigator.plugins;
    const plist = [];
    // sorting navigator.plugins is a right royal pain
    // but it seems to be necessary because their order
    // is non-constant in some browsers
    for (let i = 0; i < np.length; i++) {
      plist[i] = np[i].name + "; ";
      plist[i] += np[i].description + "; ";
      plist[i] += np[i].filename + ";";
      for (let n = 0; n < np[i].length; n++) {
        plist[i] +=
          " (" +
          np[i][n].description +
          "; " +
          np[i][n].type +
          "; " +
          np[i][n].suffixes +
          ")";
      }
      plist[i] += ". ";
    }
    plist.sort();
    for (let i = 0; i < np.length; i++)
      plugins += "Plugin " + i + ": " + plist[i];
  }
  // in IE, things are much harder; we use PluginDetect to get less
  // information (only the plugins listed below & their version numbers)
  if (plugins === "") {
    const pp = [];
    pp[0] = "Java";
    pp[1] = "QuickTime";
    pp[2] = "DevalVR";
    pp[3] = "Shockwave";
    pp[4] = "Flash";
    pp[5] = "WindowsMediaplayer";
    pp[6] = "Silverlight";
    pp[7] = "VLC";
    let version;
    for (const p in pp) {
      version = PluginDetect.getVersion(pp[p]);
      if (version) plugins += pp[p] + " " + version + "; ";
    }
    plugins += this._ieAcrobatVersion();
  }
  return plugins;
};

dfp._ieAcrobatVersion = function () {
  // estimate the version of Acrobat on IE using horrible horrible hacks
  let oAcro, oAcro4, oAcro7;
  if (window.ActiveXObject) {
    for (let x = 2; x < 10; x++) {
      try {
        oAcro = eval("new ActiveXObject('PDF.PdfCtrl." + x + "');");
        if (oAcro) return "Adobe Acrobat version" + x + ".?";
      } catch (ex) {}
    }
    try {
      oAcro4 = new ActiveXObject("PDF.PdfCtrl.1");
      if (oAcro4) return "Adobe Acrobat version 4.?";
    } catch (ex) {}
    try {
      oAcro7 = new ActiveXObject("AcroPDF.PDF.1");
      if (oAcro7) return "Adobe Acrobat version 7.?";
    } catch (ex) {}
    return "";
  }
};

dfp.isCookiesEnabled = function () {
  return window.navigator.cookieEnabled ? 1 : 0;
};

dfp.isFlashEnabled = function () {
  let _isFlashEnabled = 0;
  // Проверка для всех браузеров, кроме IE
  if (
    typeof navigator.plugins != "undefined" &&
    typeof (navigator.plugins as any)["Shockwave Flash"] == "object"
  ) {
    _isFlashEnabled = 1;
  } else if (typeof window.ActiveXObject != "undefined") {
    // Проверка для IE
    try {
      if (new ActiveXObject("ShockwaveFlash.ShockwaveFlash")) {
        _isFlashEnabled = 1;
      }
    } catch (e) {}
  }
  return _isFlashEnabled;
};

dfp.isJavaEnabled = function () {
  return window.navigator.javaEnabled() ? 1 : 0;
};

dfp._getChunkFromUserAgent = function (a: any, b: any) {
  let c = "";
  for (const os in b) {
    const d = new RegExp(b[os], "i");
    if (d.test(a.toLowerCase())) {
      const e = a.split(";");
      for (const chid in e) {
        e[chid] = e[chid].replace(/^\s+/, "");
        e[chid] = e[chid].replace(/\s+$/, "");
        if (d.test(e[chid].toLowerCase())) {
          c = e[chid];
          break;
        }
      }
      break;
    }
  }
  return c;
};

dfp._DetectOsFromHeader = function (ua: any) {
  let result = "";
  let windows = 0;
  let macintosh = 0;

  // Quick Windows or Mac detection
  if (/^.*?(\(|\( )Macintosh;/i.test(ua.toLowerCase())) {
    macintosh = 1;
    ua = ua.replace(/^.*?\(Macintosh;/i, "");
  }
  if (/^.*?(\(|\( )Windows;/i.test(ua.toLowerCase())) {
    windows = 1;
    ua = ua.replace(/^.*?\(Windows;/i, "");
  }

  // Detect OS
  result = this._getChunkFromUserAgent(ua, this._Oses);

  result = result.replace(/^(Mozilla|Opera|[\d.]+).*?\(/, "");
  if (!/\(/.test(result)) result = result.replace(/\)$/, "");
  result = !result && macintosh === 1 ? "Macintosh" : result;
  result = !result && windows === 1 ? "Windows" : result;

  // Add information about mobile telephone too :)
  let mobile_detected = 0,
    in_result = 0;
  for (const mid in this._Mobiles) {
    const re = new RegExp(this._Mobiles[mid], "i");
    if (re.test(ua.toLowerCase())) mobile_detected = 1;
    if (re.test(result.toLowerCase())) in_result = 1;
  }
  if (mobile_detected && (result === "" || !in_result)) {
    const mobile = this._getChunkFromUserAgent(ua, this._Mobiles);
    if (mobile) result += result ? ", " + mobile : mobile;
  }

  // skip parenthesis
  if (/^(.*)\).*?(\[.*\]|KHTML|NetFront)/.test(result)) result = RegExp.$1;
  result = result.replace(/[\)\(]/g, "");

  // curl
  if (/curl\/.* \((.*?)\)/.test(ua.toLowerCase())) result = RegExp.$1;

  return result;
};

dfp.getOsFull = function () {
  let os_full = "";
  const os_container = [];

  if (typeof window.navigator["platform"] !== "undefined")
    os_container.push(window.navigator["platform"]);
  if (typeof window.navigator["oscpu"] !== "undefined")
    os_container.push(window.navigator["oscpu"]);
  if (typeof window.navigator["userAgent"] !== "undefined")
    try {
      os_container.push(
        this._DetectOsFromHeader(window.navigator["userAgent"])
      );
    } catch (e) {}
  if (typeof window.navigator["appVersion"] !== "undefined")
    try {
      os_container.push(
        this._DetectOsFromHeader(window.navigator["appVersion"])
      );
    } catch (e) {}

  os_container.sort();
  for (let i = 1; i < os_container.length; i++) {
    if (os_container[i - 1] === os_container[i] || os_container[i] === "") {
      os_container.splice(i, 1);
      i -= 1;
    }
  }

  os_full = os_container.join(" | ");
  if (os_full === "") os_full = iloc("Unknown");
  os_full = os_full.replace(/(^\s?\|| \|\s?$)/, "");

  return os_full;
};

dfp._checkFont = function (name: any) {
  let body, test, bolInstalled, strTemplate, ab;

  try {
    body = document.body;
    test = document.createElement("div");
    bolInstalled = false;
    strTemplate =
      "<b style=\"display:inline !important; width:auto !important; font:normal 10px/1 'X',sans-serif !important\">ww</b>" +
      "<b style=\"display:inline !important; width:auto !important; font:normal 10px/1 'X',monospace !important\">ww</b>";
    name = name.replace(/['"<>]/g, "");
    if (name) {
      test.innerHTML = strTemplate.replace(/X/g, name);
      test.style.cssText =
        "position: absolute; visibility: hidden; display: block !important";
      body.insertBefore(test, body.firstChild);
      ab = test.getElementsByTagName("b");
      bolInstalled = ab[0].offsetWidth === ab[1].offsetWidth;
      body.removeChild(test);
    }
    return bolInstalled;
  } catch (err) {
    return err;
  }
};

dfp.getFonts = function () {
  let arrFonts, strTmp, i, strOut;

  try {
    arrFonts = [
      "Abadi MT Condensed Light",
      "Adobe Fangsong Std",
      "Adobe Hebrew",
      "Adobe Ming Std",
      "Agency FB",
      "Aharoni",
      "Andalus",
      "Angsana New",
      "AngsanaUPC",
      "Aparajita",
      "Arab",
      "Arabic Transparent",
      "Arabic Typesetting",
      "Arial Baltic",
      "Arial Black",
      "Arial CE",
      "Arial CYR",
      "Arial Greek",
      "Arial TUR",
      "Arial",
      "Batang",
      "BatangChe",
      "Bauhaus 93",
      "Bell MT",
      "Bitstream Vera Serif",
      "Bodoni MT",
      "Bookman Old Style",
      "Braggadocio",
      "Broadway",
      "Browallia New",
      "BrowalliaUPC",
      "Calibri Light",
      "Calibri",
      "Californian FB",
      "Cambria Math",
      "Cambria",
      "Candara",
      "Castellar",
      "Casual",
      "Centaur",
      "Century Gothic",
      "Chalkduster",
      "Colonna MT",
      "Comic Sans MS",
      "Consolas",
      "Constantia",
      "Copperplate Gothic Light",
      "Corbel",
      "Cordia New",
      "CordiaUPC",
      "Courier New Baltic",
      "Courier New CE",
      "Courier New CYR",
      "Courier New Greek",
      "Courier New TUR",
      "Courier New",
      "DFKai-SB",
      "DaunPenh",
      "David",
      "DejaVu LGC Sans Mono",
      "Desdemona",
      "DilleniaUPC",
      "DokChampa",
      "Dotum",
      "DotumChe",
      "Ebrima",
      "Engravers MT",
      "Eras Bold ITC",
      "Estrangelo Edessa",
      "EucrosiaUPC",
      "Euphemia",
      "Eurostile",
      "FangSong",
      "Forte",
      "FrankRuehl",
      "Franklin Gothic Heavy",
      "Franklin Gothic Medium",
      "FreesiaUPC",
      "French Script MT",
      "Gabriola",
      "Gautami",
      "Georgia",
      "Gigi",
      "Gisha",
      "Goudy Old Style",
      "Gulim",
      "GulimChe",
      "GungSeo",
      "Gungsuh",
      "GungsuhChe",
      "Haettenschweiler",
      "Harrington",
      "Hei S",
      "HeiT",
      "Heisei Kaku Gothic",
      "Hiragino Sans GB",
      "Impact",
      "Informal Roman",
      "IrisUPC",
      "Iskoola Pota",
      "JasmineUPC",
      "KacstOne",
      "KaiTi",
      "Kalinga",
      "Kartika",
      "Khmer UI",
      "Kino MT",
      "KodchiangUPC",
      "Kokila",
      "Kozuka Gothic Pr6N",
      "Lao UI",
      "Latha",
      "Leelawadee",
      "Levenim MT",
      "LilyUPC",
      "Lohit Gujarati",
      "Loma",
      "Lucida Bright",
      "Lucida Console",
      "Lucida Fax",
      "Lucida Sans Unicode",
      "MS Gothic",
      "MS Mincho",
      "MS PGothic",
      "MS PMincho",
      "MS Reference Sans Serif",
      "MS UI Gothic",
      "MV Boli",
      "Magneto",
      "Malgun Gothic",
      "Mangal",
      "Marlett",
      "Matura MT Script Capitals",
      "Meiryo UI",
      "Meiryo",
      "Menlo",
      "Microsoft Himalaya",
      "Microsoft JhengHei",
      "Microsoft New Tai Lue",
      "Microsoft PhagsPa",
      "Microsoft Sans Serif",
      "Microsoft Tai Le",
      "Microsoft Uighur",
      "Microsoft YaHei",
      "Microsoft Yi Baiti",
      "MingLiU",
      "MingLiU-ExtB",
      "MingLiU_HKSCS",
      "MingLiU_HKSCS-ExtB",
      "Miriam Fixed",
      "Miriam",
      "Mongolian Baiti",
      "MoolBoran",
      "NSimSun",
      "Narkisim",
      "News Gothic MT",
      "Niagara Solid",
      "Nyala",
      "PMingLiU",
      "PMingLiU-ExtB",
      "Palace Script MT",
      "Palatino Linotype",
      "Papyrus",
      "Perpetua",
      "Plantagenet Cherokee",
      "Playbill",
      "Prelude Bold",
      "Prelude Condensed Bold",
      "Prelude Condensed Medium",
      "Prelude Medium",
      "PreludeCompressedWGL Black",
      "PreludeCompressedWGL Bold",
      "PreludeCompressedWGL Light",
      "PreludeCompressedWGL Medium",
      "PreludeCondensedWGL Black",
      "PreludeCondensedWGL Bold",
      "PreludeCondensedWGL Light",
      "PreludeCondensedWGL Medium",
      "PreludeWGL Black",
      "PreludeWGL Bold",
      "PreludeWGL Light",
      "PreludeWGL Medium",
      "Raavi",
      "Rachana",
      "Rockwell",
      "Rod",
      "Sakkal Majalla",
      "Sawasdee",
      "Script MT Bold",
      "Segoe Print",
      "Segoe Script",
      "Segoe UI Light",
      "Segoe UI Semibold",
      "Segoe UI Symbol",
      "Segoe UI",
      "Shonar Bangla",
      "Showcard Gothic",
      "Shruti",
      "SimHei",
      "SimSun",
      "SimSun-ExtB",
      "Simplified Arabic Fixed",
      "Simplified Arabic",
      "Snap ITC",
      "Sylfaen",
      "Symbol",
      "Tahoma",
      "Times New Roman Baltic",
      "Times New Roman CE",
      "Times New Roman CYR",
      "Times New Roman Greek",
      "Times New Roman TUR",
      "Times New Roman",
      "TlwgMono",
      "Traditional Arabic",
      "Trebuchet MS",
      "Tunga",
      "Tw Cen MT Condensed Extra Bold",
      "Ubuntu",
      "Umpush",
      "Univers",
      "Utopia",
      "Utsaah",
      "Vani",
      "Verdana",
      "Vijaya",
      "Vladimir Script",
      "Vrinda",
      "Webdings",
      "Wide Latin",
      "Wingdings",
    ];
    strTmp = "";

    for (i = 0; i < 230; i = i + 1) {
      if (this._checkFont(arrFonts[i]) === true) {
        strTmp += arrFonts[i] + this._Separator;
      }
    }
    if (strTmp.length > 1) {
      strTmp = strTmp.substring(0, strTmp.length - 1);
    }
    strOut = strTmp;
    return strOut;
  } catch (err) {
    return err;
  }
};

dfp.getCanvasFingerprint = function () {
  let canvas, ctx;

  try {
    canvas = document.createElement("canvas");
    ctx = canvas.getContext("2d");
    if (ctx === null) {
      return "";
    }
    ctx.textBaseline = "top";
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = "alphabetic";
    ctx.fillStyle = "#f60";
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = "#069";
    ctx.fillText(CanvasFingerprintText, 2, 15);
    ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
    ctx.fillText(CanvasFingerprintText, 4, 17);
    return canvas.toDataURL();
  } catch (err) {
    return "";
  }
};

dfp.webGLDetect = function () {
  if (!!window.WebGLRenderingContext) {
    const canvas = document.createElement("canvas"),
      names = ["webgl", "experimental-webgl", "moz-webgl"];

    for (const i in names) {
      try {
        const gl = canvas.getContext(names[i]);
        if (gl && typeof (gl as any).getParameter == "function") {
          /* WebGL is enabled */
          return names[i];
        }
      } catch (e) {}
    }

    /* WebGL is supported, but disabled */
    return false;
  }

  /* WebGL not supported*/
  return false;
};

dfp.getWebGL = function () {
  let context_name, gl, gl_data;

  try {
    context_name = this.webGLDetect();
    if (context_name) {
      gl = document.createElement("canvas").getContext(context_name) as any;
      gl_data = [];
      gl_data.push("WebGL Version: " + gl.getParameter(gl.VERSION));
      gl_data.push(
        "Shading Language Version: " +
          gl.getParameter(gl.SHADING_LANGUAGE_VERSION)
      );
      gl_data.push("WebGL Vendor: " + gl.getParameter(gl.VENDOR));
      gl_data.push("WebGL Renderer: " + gl.getParameter(gl.RENDERER));
      return gl_data.join(" | ");
    } else if (!!window.WebGLRenderingContext) {
      return "WebGL is supported, but disabled";
    } else {
      return "WebGL is not available";
    }
  } catch (err) {
    return "error";
  }
};

dfp.hasLocalStorage = function () {
  try {
    return !!window.localStorage ? 1 : 0;
  } catch (e) {
    return 1; // SecurityError when referencing it means it exists
  }
};

dfp.hasSessionStorage = function () {
  try {
    return !!window.sessionStorage ? 1 : 0;
  } catch (e) {
    return 1; // SecurityError when referencing it means it exists
  }
};

dfp.isIndexedDB = function () {
  return !!window.indexedDB ? 1 : 0;
};

dfp.getSuperCookie = function () {
  return (
    this.hasLocalStorage() +
    "|" +
    this.hasSessionStorage() +
    "|" +
    this.isIndexedDB()
  );
};

dfp.getDFP = function () {
  let _dfp = "";
  _dfp += this.getUserAgent() + "<br/>";
  _dfp += this.getPlatform() + "<br/>";
  _dfp += this.getTimeZone() + "<br/>";
  _dfp += this.getScreenSettings() + "<br/>";
  _dfp += this.getPlugins() + "<br/>";
  _dfp += this.isCookiesEnabled() + "<br/>";
  _dfp += this.isFlashEnabled() + "<br/>";
  _dfp += this.getOsFull() + "<br/>";
  _dfp += this.getFonts() + "<br/>";

  return _dfp;
};

dfp.getParams = function () {
  const params = {
    cookiesEnabled: this.isCookiesEnabled(),
    flashEnabled: this.isFlashEnabled(),
    javaEnabled: this.isJavaEnabled(),
    osFullVersion: this.getOsFull(),
    screenSize: this.getScreenSettings(),
    timeZone: this.getTimeZone(),
    hashCodePlugins: this.getPlugins(),
    hashFonts: this.getFonts(),
    userAgent: this.getUserAgent(),
    hashCanvas: this.getCanvasFingerprint(),
    hashWebGL: this.getWebGL(),
    hashSuperCookie: this.getSuperCookie(),
  };

  return params;
};

dfp = null;

if (!XJS._dfp) {
  XJS._dfp = new XJS.DFP();
}

XJS.getD = function () {
  return XJS._dfp;
};

// if (typeof dfpCallback == "function") {
//   dfpCallback();
// }

//dfp.post = function()
//{
//    $.post(this._Page, {ua: this.getUserAgent(), tz: this.getTimeZone(), ss: this.getScreenSettings(),
//            fe: this.isFlashEnabled(), je: this.isJavaEnabled(), p: this.getPlugins(), ce: this.isCookiesEnabled(), fos: this.getOsFull(), f: this.getFonts()},
//        this._callback);
//};
//
//dfp._callback = function(data)
//{
//    var kh = data.split("||");
//    XJS._dfp._K = kh[0];
//    XJS._dfp._H = kh[1];
//};

//dfp.getK = function()
//{
//    return this._K;
//}
//
//dfp.getH = function()
//{
//    return this._H;
//}

export const fingerprint = XJS;
