import React from "react";
import type { FC } from "react";
import { LogInForm } from "../../data_blocks/log_in_form";

import "./styles.scss";

export const LogInPageView: FC = () => (
  <div className="log-in-page">
    <LogInForm />
  </div>
);
