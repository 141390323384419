import type { FC } from "react";
import React from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { PaymentsList } from "../payments_list/payments_list";

export const PaymentsSuspense: FC = () => (
  <ErrorBoundary>
    <React.Suspense fallback={<CircularProgress />}>
      <PaymentsList />
    </React.Suspense>
  </ErrorBoundary>
);
