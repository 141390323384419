import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";

import { UserResultsSuspense } from "./components/user_results_suspense/user_results_suspense";

type Props = RouteComponentProps<{
    userId: string;
}>;

export const UsersShowPage: FC<Props> = (props: Props) => {
    const userId = parseInt(props.match.params.userId, 10);

    return <UserResultsSuspense userId={userId} />;
};
