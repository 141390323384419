import type { FC } from "react";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, CircularProgress, TextField } from "@material-ui/core";

import { errorColors } from "../../../../../constants/general";
import type { RecoverForm } from "../../../../../types/main";
import "./recover_form_view.scss";

type Props = {
  data: RecoverForm.State;
  emailRef: any;
  errorMessage?: string;
  onChangeDataItem: (fieldName: RecoverForm.FieldName, value: any) => void;
  onSubmit: () => void;
  showError: boolean;
  showLoader: boolean;
};

export const RecoverFormView: FC<Props> = ({
  data,
  emailRef,
  errorMessage,
  onChangeDataItem,
  onSubmit,
  showError,
  showLoader,
}: Props) => {
  const handleChangeValue = useCallback(
    (fieldName: RecoverForm.FieldName, eventFieldName = "value") => (
      event: any
    ) => onChangeDataItem(fieldName, event.target[eventFieldName]),
    [onChangeDataItem]
  );
  const handleSubmitForm = useCallback((event?: any) => onSubmit(), [onSubmit]);
  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        handleSubmitForm();
      }
    },
    [handleSubmitForm]
  );

  return (
    <div className="recover-form">
      <h1>Восстановление пароля</h1>
      <div className="recover-form__row">
        <TextField
          autoFocus={true}
          className="recover-form__field"
          color="secondary"
          disabled={showLoader}
          error={data.email.error}
          id="outlined-basic"
          inputRef={emailRef}
          label="Email"
          onChange={handleChangeValue("email")}
          onKeyPress={handleKeyPress}
          variant="outlined"
          value={data.email.value}
        />
      </div>
      <div className="recover-form__row recover-form__row--flex">
        <div className="recover-form__submit">
          <Button
            color="primary"
            disabled={showLoader}
            onClick={handleSubmitForm}
            size="large"
            variant="contained"
          >
            Отправить новый пароль
          </Button>
          {showLoader && (
            <CircularProgress
              size={24}
              className="recover-form__submit-progress"
            />
          )}
        </div>
        <div>
          <Link to="/login/" className="recover-form__log-in">
            Войти
          </Link>
        </div>
      </div>
      {showError && (
        <div className="recover-form__row" style={{ color: errorColors.main }}>
          {errorMessage || (
            <>
              Ошибка :( Проверьте введённый email и&nbsp;попробуйте восстановить
              пароль ещё раз.
            </>
          )}
        </div>
      )}
    </div>
  );
};
