import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";

import { Title } from "../../basic/title/title";
import { ClassDataSuspense } from "./components/class_data_suspense/class_data_suspense";

type Props = RouteComponentProps<{
  classId: string;
}>;

export const ClassesEditPage: FC<Props> = (props: Props) => {
  const classId = parseInt(props.match.params.classId, 10);

  return (
    <>
      <Title backUrl="/classes/" title="Редактирование класса" />
      <ClassDataSuspense classId={classId} />
    </>
  );
};
