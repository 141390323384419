import type { FC } from "react";
import React from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../basic/error_boundary";
import { DfpUserConfirmContainer } from "./container";

export const DfpUserConfirm: FC = () => (
  <ErrorBoundary>
    <React.Suspense fallback={<CircularProgress />}>
      <DfpUserConfirmContainer />
    </React.Suspense>
  </ErrorBoundary>
);
