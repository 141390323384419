import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../basic/error_boundary";
import { PayFormContainer } from "./container";

export const PayFormSuspense: FC = () => (
  <ErrorBoundary>
    <Suspense fallback={<CircularProgress />}>
      <PayFormContainer />
    </Suspense>
  </ErrorBoundary>
);
