import type { FC } from "react";
import React, { memo, useMemo } from "react";

import { useMatchingChart } from "../../../../../hooks/amcharts/use_matching_chart";
import type { UserMatchingAnalytics } from "../../types";

import "./user_matching_charts.scss";

const DAYS_NAMES = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

const getAnalytics = (
    data: UserMatchingAnalytics["resultByDays"],
    initialValue: Record<
        string,
        {
            label: string;
            score1?: number;
            score2?: number;
        }
    >,
    fieldName: "score1" | "score2"
) =>
    data.reduce(
        (acc, v) => ({
            ...acc,
            [v.date]: acc[v.date]
                ? {
                      ...acc[v.date],
                      [fieldName]: Math.floor(v.score),
                  }
                : {
                      label: DAYS_NAMES[new Date(v.date).getDay()],
                      [fieldName]: Math.floor(v.score),
                  },
        }),
        initialValue
    );

type Props = {
    userAnalytics1: UserMatchingAnalytics;
    userAnalytics2: UserMatchingAnalytics;
    userTitle1: string;
    userTitle2: string;
};

const UserMatchingChartsFC: FC<Props> = ({ userAnalytics1, userAnalytics2, userTitle1, userTitle2 }: Props) => {
    const chartData = useMemo(() => {
        const analytics = getAnalytics(
            userAnalytics2.resultByDays,
            getAnalytics(userAnalytics1.resultByDays, {}, "score1"),
            "score2"
        );
        return Object.keys(analytics).map((k) => analytics[k]);
    }, [userAnalytics1]);

    const series = useMemo(
        () => [
            {
                balloonText: `${userTitle1}<br>очков опыта за день`,
                title: userTitle1,
                valueField: "score1",
            },
            {
                balloonText: `${userTitle2}<br>очков опыта за день`,
                title: userTitle2,
                valueField: "score2",
            },
        ],
        [userTitle1, userTitle2]
    );

    useMatchingChart({
        data: chartData,
        id: "chartdiv",
        isMakeChart: chartData.length > 0,
        series,
    });

    if (chartData.length === 0) {
        return (
            <div className="user-matching-charts__message">
                <p>Данных для графика пока нет.</p>
            </div>
        );
    }

    return <div className="chartdiv" id="chartdiv" />;
};

export const UserMatchingCharts = memo(UserMatchingChartsFC);
