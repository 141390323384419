import type { FC } from "react";
import React, {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { currentUserDataQuery } from "../../../../../state";
import ErrorBoundary from "../../../../basic/error_boundary";
import { Title } from "../../../../basic/title/title";
import { classTopicDataQuery } from "../../state/class_topic";
import { ClassResultsCharts } from "../class_results_charts/class_results_charts";
import { ClassTopicQuestions } from "../class_topic_questions/class_topic_questions";

import "./class_topic_container.scss";

type Props = {
  classId: number;
  topicId: number;
};

const ClassTopicContainerFC: FC<Props> = ({ classId, topicId }: Props) => {
  const recoilState = classTopicDataQuery({
    classId,
    topicId,
  });
  const [classResultsData, setClassResultsData] = useRecoilState(recoilState);
  useEffect(() => () => setClassResultsData(null as any), [
    setClassResultsData,
  ]);

  const [view, setView] = useState<"table" | "charts" | "questions">("table");
  const handleView = useCallback((_, value) => setView(value), []);

  const { classItem, results } = classResultsData.response;
  const usersIds = useMemo(
    () => classItem.pupils.map((v) => v.id as number) ?? [],
    [classItem]
  );

  const [{ response: userData }] = useRecoilState(currentUserDataQuery);

  if (userData?.status !== "TEACHER") {
    return <h2>Доступ закрыт</h2>;
  }

  if (classItem.pupils.length === 0) {
    return (
      <>
        <Title
          backUrl={`/classes/show/${classId}/`}
          title={classItem.totalTitle ?? "Результаты класса по теме"}
        />
        <p>
          В классе нет учеников. Добавьте их, чтобы увидеть результаты
          прохождения курса.
        </p>
      </>
    );
  }

  return (
    <>
      <Title
        backUrl={`/classes/show/${classId}/`}
        title={classItem.totalTitle ?? "Результаты класса по теме"}
      />
      <h3>{results.topics[topicId]?.title}</h3>
      <div className="class-topic__tabs">
        <ToggleButtonGroup
          exclusive
          onChange={handleView}
          size="small"
          value={view}
        >
          <ToggleButton value="table">Таблица</ToggleButton>
          <ToggleButton value="charts">Графики</ToggleButton>
          <ToggleButton value="questions">Вопросы</ToggleButton>
        </ToggleButtonGroup>
      </div>
      {view === "table" && (
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width={12} />
              <TableCell>ФИО</TableCell>
              {Object.keys(results.resultsByActivityIdUserId).map((k) => (
                <TableCell key={`${k}`}>
                  {results.resultsByActivityIdUserId[k].title}
                </TableCell>
              ))}
              <TableCell>Общий рез.,&nbsp;%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classItem.pupils.map((p, index) => (
              <TableRow key={`user_id_${p.id}`}>
                <TableCell>{index + 1}.</TableCell>
                <TableCell>
                  <Link to={`/users/show/${p.id}/`}>
                    {p.lastName} {p.firstName}
                  </Link>
                </TableCell>
                {Object.keys(results.resultsByActivityIdUserId).map((k) => {
                  const average =
                    results.resultsByActivityIdUserId[k].averages[p.id];
                  return (
                    <TableCell key={`user_id_${p.id}_content_id_${k}`}>
                      {average != null ? (
                        <Link to={`/users/topic/${p.id}/${topicId}/`}>
                          {Math.floor(average * 100)}
                        </Link>
                      ) : (
                        <>&ndash;</>
                      )}
                    </TableCell>
                  );
                })}
                <TableCell>
                  {Math.floor(
                    ((Object.keys(results.resultsByActivityIdUserId).map(
                      (k: any) =>
                        results.resultsByActivityIdUserId[k].averages[p.id]
                    ) as (number | null | undefined)[]).filter(
                      (v) => v != null
                    ) as number[]).reduce(
                      (acc, cur, i) => (acc * i + cur) / (i + 1),
                      0
                    ) * 100
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {view === "charts" && <ClassResultsCharts results={results} />}
      {view === "questions" && (
        <ErrorBoundary>
          <Suspense fallback={<CircularProgress />}>
            <ClassTopicQuestions topicId={topicId} usersIds={usersIds} />
          </Suspense>
        </ErrorBoundary>
      )}
    </>
  );
};

export const ClassTopicContainer = memo(ClassTopicContainerFC);
