import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";

import { ClassTopicSuspense } from "./components/class_topic_suspense/class_topic_suspense";

type Props = RouteComponentProps<{
  classId: string;
  topicId: string;
}>;

export const ClassesTopicPage: FC<Props> = (props: Props) => {
  const classId = parseInt(props.match.params.classId, 10);
  const topicId = parseInt(props.match.params.topicId, 10);

  return <ClassTopicSuspense classId={classId} topicId={topicId} />;
};
