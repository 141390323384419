import type { FC } from "react";
import React, { memo, useEffect } from "react";
import { useRecoilState } from "recoil";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

import { ExerciseBody } from "../../../../basic/exercise_body/exercise_body";
import { classQuestionsDataQuery } from "../../state/class_questions";

import "./class_topic_questions.scss";

type Props = {
    topicId: number;
    usersIds: number[];
};

const ClassTopicQuestionsFC: FC<Props> = ({ topicId, usersIds }: Props) => {
    const recoilState = classQuestionsDataQuery({
        topicId,
        usersIds,
    });
    const [classQuestionsData, setClassQuestionsData] = useRecoilState(recoilState);
    useEffect(() => () => setClassQuestionsData(null as any), [setClassQuestionsData]);

    const questionsStatistics = classQuestionsData.response;

    return (
        <>
            <h3>Вопросы</h3>
            <div className="class-topic-questions__result">
                {questionsStatistics.length === 0 && <p>Статистики решений вопросов пока нет.</p>}
                {questionsStatistics.map(
                    (v, i) =>
                        Boolean(v.answers?.length) && (
                            <Accordion key={`question_result_${v.id}`}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <ExerciseBody ordinalNumber={i + 1} text={v.text} topic={null} />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        <FormGroup>
                                            {v.answers.map((a) => {
                                                const numberOfSelects =
                                                    v.numberOfSelectsByAnswerIds[a.id.toString()] ?? 0;
                                                return (
                                                    <div
                                                        className="class-topic-questions__answer"
                                                        key={`question_result_answer_${a.id}`}
                                                    >
                                                        <div className="class-topic-questions__answer-label">
                                                            {v.attempts > 0 ? (
                                                                <>
                                                                    {Math.ceil((numberOfSelects / v.attempts) * 100)} %
                                                                    ({numberOfSelects} из {v.attempts})
                                                                </>
                                                            ) : (
                                                                <>нет статистики</>
                                                            )}
                                                        </div>
                                                        <FormControlLabel
                                                            className={
                                                                a.correct
                                                                    ? "class-topic-questions__answer-success"
                                                                    : "class-topic-questions__answer-error"
                                                            }
                                                            control={
                                                                <Checkbox
                                                                    checked={a.correct > 0}
                                                                    disabled
                                                                    name={a.id.toString()}
                                                                />
                                                            }
                                                            label={a.text}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </FormGroup>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                )}
            </div>
        </>
    );
};

export const ClassTopicQuestions = memo(ClassTopicQuestionsFC);
