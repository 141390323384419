import type { FC, PropsWithChildren } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../basic/error_boundary";
import { ActivateDeviceContainer } from "./container";

type Props = {
  dfpUser: string;
  isAuthorized: boolean;
};

export const ActivateDevice: FC<Props> = (props: PropsWithChildren<Props>) => (
  <ErrorBoundary>
    <Suspense fallback={<CircularProgress />}>
      <ActivateDeviceContainer
        dfpUser={props.dfpUser}
        isAuthorized={props.isAuthorized}
      />
    </Suspense>
  </ErrorBoundary>
);
