import { sendRequest } from "../../../../core/api/request";
import type { UserDataType } from "../types";

export const requestUserData = async (
  userId: number
): Promise<UserDataType> => {
  const data = await sendRequest({
    bodyData: {
      apiVersion: 2,
      id: userId,
    },
    method: "POST",
    url: "/users/list/",
  })
    .then(({ cursor, data: successData }) => ({
      cursor,
      response: successData,
      status: "SUCCESS",
    }))
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (data.status === "ERROR") {
    throw data;
  }
  return data;
};
