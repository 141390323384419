import type { FC } from "react";
import React from "react";

import { Contacts } from "../../../../common/contacts/contacts";
import type { ChildrenProps, ContactsParams } from "../../../../common/contacts/types";

const params: ContactsParams = { type: "CONTACTED" };

export const ContactsContacted: FC = () => (
    <Contacts params={params}>
        {({ contacts }: ChildrenProps) => (contacts.length ? <>{contacts}</> : <>Контаков пока нет</>)}
    </Contacts>
);
