import React, { FunctionComponent, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { useRecoilState } from "recoil";

import { useLogInFormSubmit } from "../../../actions/data";
import { emailRegExp } from "../../../constants/general";
import { useOnChangeDataItem } from "../../../hooks/on_change_data_item";
import { useOnSubmitClick } from "../../../hooks/on_submit_click";
import { useResetFormStatus } from "../../../hooks/reset_form_status";
import { logInFormRequest } from "../../../state";
import { LogInForm } from "../../../types/main.d";
import LogInFormView from "./log_in_form_view";

const LogInFormContainer: FunctionComponent = () => {
  const [request, setRequest] = useRecoilState(logInFormRequest);
  const logInFormSubmit = useLogInFormSubmit();
  const inputEmail = useRef(null);
  const inputPassword = useRef(null);
  const [logInData, setLogInData] = useState<LogInForm.State>({
    email: {
      error: false,
      errorChecker: (value) => !emailRegExp.test(value),
      ref: inputEmail,
      value: "",
    },
    password: {
      error: false,
      errorChecker: (value) => value === "",
      ref: inputPassword,
      value: "",
    },
  });
  const handleChangeDataItem = useOnChangeDataItem(setLogInData);
  const handleSubmit = useOnSubmitClick(
    logInData,
    request.status,
    setLogInData,
    logInFormSubmit
  );
  useResetFormStatus(setRequest);

  return (
    <>
      {request.status === "SUCCESS" ? (
        <Redirect to="/" />
      ) : (
        <LogInFormView
          data={logInData}
          emailRef={inputEmail}
          errorMessage={request.response?.message}
          onChangeDataItem={handleChangeDataItem}
          onSubmit={handleSubmit}
          passwordRef={inputPassword}
          showError={request.status === "ERROR"}
          showLoader={request.status === "REQUEST"}
        />
      )}
    </>
  );
};

export default LogInFormContainer;
