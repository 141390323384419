import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { FormContainer } from "../form_container/form_container";

export const FormSuspense: FC = () => (
  <ErrorBoundary>
    <Suspense fallback={<CircularProgress />}>
      <FormContainer />
    </Suspense>
  </ErrorBoundary>
);
