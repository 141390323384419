import type { FC } from "react";
import React, { memo } from "react";
import { useRecoilState } from "recoil";

import { currentUserDataQuery } from "../../../../../state";
import { ClassEditorContainer } from "../class_editor_container/class_editor_container";

const FormContainerFC: FC = () => {
  const [{ response: userData }] = useRecoilState(currentUserDataQuery);

  if (userData?.status !== "TEACHER") {
    return <>Доступ закрыт</>;
  }

  return <ClassEditorContainer />;
};

export const FormContainer = memo(FormContainerFC);
