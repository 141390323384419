import type { FC } from "react";
import React from "react";

import { Title } from "../../basic/title/title";
import { FormSuspense } from "./components/form_suspense/form_suspense";

export const ClassesAddPage: FC = () => (
  <>
    <Title backUrl="/classes/" title="Новый класс" />
    <FormSuspense />
  </>
);
