import type { FC } from "react";
import React, { memo } from "react";
import { useRecoilValue } from "recoil";
import { format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { paymentsDataQuery } from "../../../../../state";

const monthNameGenitive = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

const PaymentsListFC: FC = () => {
  const { response: rows } = useRecoilValue(paymentsDataQuery);

  return rows ? (
    <div className="payments">
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Дата и время</TableCell>
            <TableCell>№</TableCell>
            <TableCell>Тип операции</TableCell>
            <TableCell>Сумма</TableCell>
            <TableCell>Описание</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => {
            const year = row.datePaid?.getFullYear();
            const month = row.datePaid?.getMonth();
            const day = row.datePaid?.getDate();
            return (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.datePaid && (
                    <>
                      {day} {monthNameGenitive[month ?? 0]} {year}
                      {format(row.datePaid, " в HH:mm")}
                    </>
                  )}
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.operationTitle}</TableCell>
                <TableCell>
                  {row.minus ? "-" : "+"}
                  <strong>{row.finance}</strong> {row.financeCurrency}
                  {row.operationType !== "INTERNAL" && (
                    <>
                      <br />(<strong>{row.amount}</strong>&nbsp;₽)
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <div dangerouslySetInnerHTML={{ __html: row.description }} />
                  {row.userComment && (
                    <div
                      dangerouslySetInnerHTML={{ __html: row.userComment }}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  ) : null;
};

export const PaymentsList = memo(PaymentsListFC);
