import { atomFamily, selectorFamily } from "recoil";
import { LRS } from "../../../../constants/general";
import { sendRequest } from "../../../../core/api/request";

type IndicatorsData = {
  response: {
    classesWithoutInterruptionDays?: number;
    totalScore?: number;
    weeklyRatingTop3Count?: number;
  } | null;
  status: string;
};

const requestIndicatorsData = async (
  userId: number
): Promise<IndicatorsData> => {
  const data = await sendRequest({
    method: "GET",
    url: userId === 0 ? `${LRS.endpoint}results/indicators/` : `${LRS.endpoint}results/indicators/${userId}/`,
  })
    .then(({ data: successData }) => ({
      response: successData,
      status: "SUCCESS",
    }))
    .catch((errorData) => ({
      response: errorData,
      status: "ERROR",
    }));
  if (data.status === "ERROR") {
    return data;
  }
  return data;
}

const indicatorsDataRequestId = atomFamily({
  key: "indicatorsDataRequestId",
  default: 0,
});

const indicatorsDataAtom = atomFamily<IndicatorsData | null, number>({
  key: "indicatorsDataAtom",
  default: null,
});

export const indicatorsDataQuery = selectorFamily<IndicatorsData, number>({
  key: "indicatorsDataQuery",
  get: (userId) => async ({ get }) => {
    get(indicatorsDataRequestId(userId)); // Add request ID as a dependency
    const newIndicatorsData = get(indicatorsDataAtom(userId));
    if (newIndicatorsData != null) {
      return newIndicatorsData;
    }
    return await requestIndicatorsData(userId);
  },
  set: (userId) => ({ set }, newValue) => {
    if (newValue === null) {
      set(indicatorsDataRequestId(userId), (requestId) => requestId + 1);
    }
    set(indicatorsDataAtom(userId), newValue);
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
