import type { ChangeEvent, FC } from "react";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";

import { FormControl, InputLabel, Select } from "@material-ui/core";

import { EXTENSION_ANSWERS, EXTENSION_QUESTIONS } from "../../../../../constants/cmi5";
import { currentUserDataQuery } from "../../../../../state";
import { Title } from "../../../../basic/title/title";
import { userTopicDataQuery } from "../../state/user_topic";
import { UserTopicTest } from "../user_topic_test/user_topic_test";

import "./user_topic_container.scss";

type Props = {
    mineResults?: boolean;
    topicId: number;
    userId: number;
};

const UserTopicContainerFC: FC<Props> = ({ mineResults, topicId, userId }: Props) => {
    const recoilState = userTopicDataQuery({ topicId, userId });
    const [userTopicData, setUserTopicData] = useRecoilState(recoilState);
    const { results, user } = userTopicData.response;
    const [{ response: userData }] = useRecoilState(currentUserDataQuery);
    useEffect(() => () => setUserTopicData(null as any), [setUserTopicData]);

    const [activityId, setActivityId] = useState("0");
    const [attemptId, setAttemptId] = useState(0);

    const handleChangeActivityId = useCallback((event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setActivityId(event.target.value as string);
        setAttemptId(0);
    }, []);
    const handleChangeAttemptId = useCallback(
        (
            event: ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>
        ) => setAttemptId(parseInt(event.target.value as string, 10)),
        []
    );

    const activityIds = useMemo(() => Object.keys(results.resultsByActivityIdUserId), [
        results.resultsByActivityIdUserId,
    ]);

    useEffect(() => {
        if (activityId === "0" && activityIds.length) {
            setActivityId(activityIds[0]);
        }
        // Confirm this dependency
    }, [activityIds]);

    const activityIdUserIdKey = `${activityId}_${userId}`;
    useEffect(() => {
        if (attemptId === 0 && results.attemptsByActivityIdUserId[activityIdUserIdKey]) {
            setAttemptId(results.attemptsByActivityIdUserId[activityIdUserIdKey][0].id);
        }
        // Confirm this dependency
    }, [activityIdUserIdKey, attemptId]);

    const attemptItem = useMemo(
        () => results.attemptsByActivityIdUserId[activityIdUserIdKey]?.find((v) => v.id === attemptId),
        [activityIdUserIdKey, attemptId, results.attemptsByActivityIdUserId]
    );

    const isMine = userData?.id === userId;
    if (userData?.status !== "TEACHER" && !isMine) {
        return <>Доступ закрыт</>;
    }

    const title = `${user.lastName ?? ""} ${user.firstName ?? ""} ${user.middleName ?? ""}`;

    return (
        <>
            {mineResults ? (
                <Title backUrl="/results/" title={results.topics[topicId]?.title ?? "Мои результаты по теме"} />
            ) : (
                <>
                    <Title
                        backUrl={`/users/show/${userId}/`}
                        title={title.trim() === "" ? "Результаты ученика по теме" : title}
                    />
                    <h3>{results.topics[topicId]?.title}</h3>
                </>
            )}
            <div className="user-topic__row">
                <FormControl className="user-topic__select" variant="outlined">
                    <InputLabel id="activity-id-label">1. Выберите тест</InputLabel>
                    <Select
                        disabled={!activityIds.length}
                        id="activityId"
                        label="1. Выберите тест"
                        labelId="activity-id-label"
                        native={true}
                        onChange={handleChangeActivityId}
                        value={activityId}
                    >
                        {!activityIds.length && (
                            <option key={`activity`} value="0">
                                — тестов нет —
                            </option>
                        )}
                        {activityIds.map((k) => {
                            const averageByActivity = results.resultsByActivityIdUserId[k].averages[userId];
                            return (
                                <option key={k} value={k}>
                                    {results.resultsByActivityIdUserId[k].title} (
                                    {averageByActivity != null
                                        ? Math.floor(averageByActivity * 100) + " %"
                                        : "нет решений"}
                                    )
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className="user-topic__row">
                <FormControl className="user-topic__select" variant="outlined">
                    <InputLabel id="activity-id-label">2. Выберите попытку</InputLabel>
                    <Select
                        disabled={!results.attemptsByActivityIdUserId[activityIdUserIdKey]?.length}
                        id="attemptId"
                        label="2. Выберите попытку"
                        labelId="activity-id-label"
                        native={true}
                        onChange={handleChangeAttemptId}
                        value={attemptId}
                    >
                        {!results.attemptsByActivityIdUserId[activityIdUserIdKey]?.length && (
                            <option key={`attempt`} value={0}>
                                — решений теста нет —
                            </option>
                        )}
                        {results.attemptsByActivityIdUserId[activityIdUserIdKey]?.map((v) => (
                            <option key={`attempt_${v.id}`} value={v.id}>
                                {v.label}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {attemptItem?.extensions &&
            attemptItem.extensions[EXTENSION_ANSWERS] &&
            attemptItem.extensions[EXTENSION_QUESTIONS] ? (
                <UserTopicTest
                    answers={attemptItem.extensions[EXTENSION_ANSWERS]}
                    questions={attemptItem.extensions[EXTENSION_QUESTIONS]}
                    resultScoreScaled={attemptItem.resultScoreScaled}
                />
            ) : (
                attemptItem && <>Подробностей решения теста нет.</>
            )}
        </>
    );
};

export const UserTopicContainer = memo(UserTopicContainerFC);
