declare let document: any;
declare let window: any;

/*
 * To change this template, choose Tools | Templates
 * and open the template in the editor.
 */

/* Класс с набором системных функций */

const XJS: any = {};
XJS.IsIE = navigator.userAgent.indexOf(" MSIE ") > -1;
XJS.IsFF = navigator.userAgent.indexOf(" Firefox/") > -1;
XJS.IsChrome = false;
XJS.IsSafari = false;
if (navigator.userAgent.indexOf("Chrome") > -1) XJS.IsChrome = true;
else if (navigator.userAgent.indexOf(" Safari/") > -1) XJS.IsSafari = true;
XJS.IsOpera = navigator.userAgent.indexOf("Opera/") > -1;
XJS.IsiPad = navigator.userAgent.indexOf("iPad") > -1;
XJS.IsiPhone = navigator.userAgent.indexOf("iPhone") > -1;
XJS.isTouch = "ontouchstart" in document;

if (XJS.IsIE) {
  if (window.atob) XJS.IEVer = 10;
  else if (document.getElementsByClassName) XJS.IEVer = 9;
  else if (document.querySelector) XJS.IEVer = 8;
  else XJS.IEVer = 7;

  XJS.IsSVG = XJS.IEVer > 8;
} else XJS.IsSVG = true;

if (!window.XJS) window.XJS = XJS;

export default XJS;
