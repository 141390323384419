import { atom, selector } from "recoil";

import { sendRequest } from "../../../../core/api/request";
import type { ClassesListType } from "../types";

const requestClassesData = async () => {
  const data = await sendRequest({
    bodyData: {
      isArchive: false,
      noFolders: true,
    },
    method: "POST",
    url: "/classes/list/",
  })
    .then(({ cursor, data: successData }) => ({
      cursor,
      response: successData,
      status: "SUCCESS",
    }))
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (data.status === "ERROR") {
    throw data;
  }
  return data;
};

const classesDataRequestId = atom({
  key: "classesDataRequestId",
  default: 0,
});

const classesData = atom<ClassesListType | null>({
  key: "classesData",
  default: null,
});

export const classesDataQuery = selector<ClassesListType>({
  key: "classesDataQuery",
  get: async ({ get }) => {
    get(classesDataRequestId); // Add request ID as a dependency
    const newContactsData = get(classesData);
    if (newContactsData !== null) {
      return newContactsData;
    }
    return await requestClassesData();
  },
  set: ({ set }, newValue) => {
    if (newValue === null) {
      set(classesDataRequestId, (requestId) => requestId + 1);
    }
    set(classesData, newValue);
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
