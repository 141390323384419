import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";
import { useFormRequest } from "../../../../actions/data";
import { uploadFileRequest } from "../../../../core/api/request";
import { authorizationToken } from "../../../../state";
import type { Form } from "../../../../types/main.d";

export const avatarFormRequest = atom<Form.Request>({
  key: "avatarFormRequest",
  default: {
    status: "DEFAULT",
  },
});

export const useAvatarDeleteFormSubmit = () => {
  const setFormRequest = useSetRecoilState(avatarFormRequest);
  return useFormRequest({
    method: "DELETE",
    setFormRequest,
    url: "/profile/avatar/",
  });
};

export const useAvatarFormSubmit = () => {
  const setAuthorizationToken = useSetRecoilState(authorizationToken);
  const setFormRequest = useSetRecoilState(avatarFormRequest);
  return useCallback(
    (file: File, data: Record<string, any> = {}) =>
      uploadFileRequest({
        item: {
          data,
          file,
        },
        setAuthorizationToken,
        setFormRequest,
        url: "/profile/avatar/",
      }),
    [setAuthorizationToken, setFormRequest]
  );
};
