import type { FC } from "react";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";

import { UserTopicSuspense } from "./components/user_topic_suspense/user_topic_suspense";

type Props = RouteComponentProps<{
  topicId: string;
  userId: string;
}>;

export const UsersTopicPage: FC<Props> = (props: Props) => {
  const topicId = parseInt(props.match.params.topicId, 10);
  const userId = parseInt(props.match.params.userId, 10);

  return <UserTopicSuspense topicId={topicId} userId={userId} />;
};
