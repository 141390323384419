import type { FC, MouseEvent } from "react";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import type { RouteComponentProps } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { format } from "date-fns";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";

import { LogInSignUpView } from "./log_in_sign_up_view";
import { removeJwtToken } from "../../../core/jwt_token";
import { authorizationToken, currentUserDataQuery } from "../../../state";

// @TODO Temporary for main page banner
const popperStyle = { zIndex: 10 };

const UserPanelContainerFC: FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const anchorRef = useRef(null);
    const [isOpened, setIsOpened] = useState(false);
    const setAuthorizationToken = useSetRecoilState(authorizationToken);
    const { response: userData, status: userDataStatus } = useRecoilValue(currentUserDataQuery);

    useEffect(() => {
        if (userDataStatus === "ERROR") {
            removeJwtToken(setAuthorizationToken);
        }
    }, [setAuthorizationToken, userDataStatus]);

    const handleClose = useCallback(
        (event: MouseEvent<EventTarget>) => {
            if (anchorRef && anchorRef.current && (anchorRef as any).current.contains(event.target as HTMLElement)) {
                return;
            }
            setIsOpened(false);
        },
        [anchorRef, setIsOpened]
    );

    const handleToggle = useCallback(() => {
        setIsOpened(!isOpened);
    }, [isOpened, setIsOpened]);

    // @TODO Use into custom MenuItem
    const openUrl = (url: string) => () => {
        setIsOpened(!isOpened);
        props.history.push(url);
    };

    const logOut = useCallback(() => removeJwtToken(setAuthorizationToken), [setAuthorizationToken]);

    if (!userData) {
        return <LogInSignUpView />;
    }

    return (
        <>
            <img
                alt=""
                className="user-panel__avatar"
                onClick={handleToggle}
                ref={anchorRef}
                src={userData.avatarMini}
            />
            <Popper
                anchorEl={anchorRef.current}
                disablePortal={true}
                open={isOpened}
                placement="bottom-end"
                style={popperStyle}
                transition={true}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList dense={true}>
                                    <MenuItem onClick={openUrl("/profile/")}>{userData.email}</MenuItem>
                                    <MenuItem onClick={openUrl("/password/")}>Изменить пароль</MenuItem>
                                    <MenuItem onClick={openUrl("/payments/")}>Список оплат</MenuItem>
                                    {userData.subscriptionData ? (
                                        <MenuItem>
                                            Подписка активна
                                            {userData.subscriptionData.dateExpiration && (
                                                <>
                                                    <br />
                                                    до {format(userData.subscriptionData.dateExpiration, "dd.MM.yyyy")}
                                                </>
                                            )}
                                        </MenuItem>
                                    ) : (
                                        <MenuItem onClick={openUrl("/pay/")}>Оформить подписку</MenuItem>
                                    )}
                                    <MenuItem onClick={openUrl("/results/")}>Результаты обучения</MenuItem>
                                    {userData.status === "PUPIL" && (
                                        <MenuItem onClick={openUrl("/weekly-rating/")}>Недельный рейтинг</MenuItem>
                                    )}
                                    <MenuItem onClick={openUrl("/teachers/")}>Преподаватели</MenuItem>
                                    <MenuItem onClick={openUrl("/contacts/")}>Мои контакты</MenuItem>
                                    {userData.status === "TEACHER" && (
                                        <MenuItem onClick={openUrl("/classes/")}>Классы</MenuItem>
                                    )}
                                    <MenuItem onClick={logOut}>Выйти</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export const UserPanelContainer = withRouter(memo(UserPanelContainerFC));
