import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { Indicators } from "../indicators/indicators";

type Props = {
    userId?: number;
};

export const IndicatorsSuspense: FC<Props> = ({ userId }: Props) => (
    <ErrorBoundary>
        <Suspense fallback={<CircularProgress />}>
            <Indicators userId={userId} />
        </Suspense>
    </ErrorBoundary>
);
