import type { FC } from "react";
import React, { useCallback, useState } from "react";
import { useRecoilState } from "recoil";

import { Button } from "@material-ui/core";

import { errorColors } from "../../../../../constants/general";
import { useResetFormStatus } from "../../../../../hooks/reset_form_status";
import { ProfileFormType } from "../../../../../types/main.d";
import { ButtonLink } from "../../../../basic/button_link";
import { ProfileForm } from "../../../../common/profile_form/profile_form";
import { activateUpdateUserFormRequest, useActivateUpdateUserFormSubmit } from "../../state/activate_update_user";
import { AffiliateContact } from "../affiliate_contact/affiliate_contact";

const h3Style = { fontWeight: 400 };

type Props = {
    affiliateUserData?: {
        avatar: string;
        firstName: string | null;
        id: number;
        lastName: string | null;
        middleName: string | null;
        relationStatus: "NO" | "PENDING" | "REQUESTED" | "CONTACTED" | "DECLINED";
    };
    code: string;
    login: string;
    userData: {
        avatar: string;
        class: number;
        firstName: string | null;
        lastName: string | null;
        middleName: string | null;
        status: "PARENT" | "PUPIL" | "TEACHER" | null;
    };
};

export const UserProfile: FC<Props> = ({ affiliateUserData, code, login, userData }: Props) => {
    const maxStepsCount = affiliateUserData ? 2 : 1;
    const [step, setStep] = useState(1);
    const setNextStep = useCallback(() => setStep((v) => v + 1), []);
    const setPreviousStep = useCallback(() => setStep((v) => v - 1), []);

    const [newSelectedAvatarFile, setNewSelectedAvatarFile] = useState<File>();

    const [request, setRequest] = useRecoilState(activateUpdateUserFormRequest);
    const activateUpdateUserFormSubmit = useActivateUpdateUserFormSubmit();

    const showClassField = userData?.status === "PUPIL";
    const [profileData, setProfileData] = useState<ProfileFormType.State>({
        lastName: {
            value: userData?.lastName ?? "",
        },
        firstName: {
            value: userData?.firstName ?? "",
        },
        middleName: {
            value: userData?.middleName ?? "",
        },
        class: {
            value: userData?.class ?? 0,
        },
    });
    const handleChangeDataItem = useCallback(
        (fieldName: ProfileFormType.FieldName, value: any) =>
            setProfileData((oldProfileData) => ({
                ...oldProfileData,
                [fieldName]: {
                    ...oldProfileData[fieldName],
                    value,
                },
            })),
        []
    );
    const handleSubmit = useCallback(
        (affiliateContactId?: number, affiliateContactAction?: "CONFIRM" | "DECLINE") => {
            let firstErrorField: any;
            (Object.keys(profileData) as ProfileFormType.FieldName[]).forEach((k) => {
                if (profileData[k].error && !firstErrorField) {
                    firstErrorField = profileData[k] as any;
                }
            });

            setTimeout(() => {
                if (firstErrorField && firstErrorField.ref) {
                    firstErrorField.ref.current.focus();
                }
            }, 0);
            if (!firstErrorField && request.status !== "REQUEST") {
                activateUpdateUserFormSubmit(newSelectedAvatarFile, {
                    activateAffiliateEmail: true,
                    code,
                    firstName: profileData.firstName.value,
                    lastName: profileData.lastName.value,
                    login,
                    middleName: profileData.middleName.value,
                    ...(affiliateContactAction ? { affiliateContactAction } : {}),
                    ...(affiliateContactId ? { affiliateContactId } : {}),
                    ...(showClassField ? { class: profileData.class.value } : {}),
                });
            }
        },
        [activateUpdateUserFormSubmit, code, login, newSelectedAvatarFile, profileData, request.status, showClassField]
    );

    const handleAvatarDelete = useCallback(() => {
        if (request.status !== "REQUEST") {
            setNewSelectedAvatarFile(undefined);
        }
    }, [request.status]);

    const handleAvatarSubmit = useCallback(
        (file: File) => {
            if (request.status !== "REQUEST") {
                setNewSelectedAvatarFile(file);
            }
        },
        [request.status]
    );

    const handleConfirmClick = useCallback(() => handleSubmit(affiliateUserData?.id, "CONFIRM"), [
        affiliateUserData?.id,
        handleSubmit,
    ]);
    const handleDeclineClick = useCallback(() => handleSubmit(affiliateUserData?.id, "DECLINE"), [
        affiliateUserData?.id,
        handleSubmit,
    ]);

    useResetFormStatus(setRequest);

    if (step === maxStepsCount && request.status === "SUCCESS") {
        return (
            <>
                <h3 style={h3Style}>Спасибо! Ваш аккаунт активирован!</h3>
                <ButtonLink href="/login/" size="large">
                    Войти
                </ButtonLink>
            </>
        );
    }

    if (step === 2 && affiliateUserData) {
        return (
            <>
                <h3 style={h3Style}>2. Добавьте контакт</h3>
                <p>
                    {affiliateUserData.lastName} {affiliateUserData.firstName} {affiliateUserData.middleName} приглашает
                    Вас подключиться к&nbsp;«Стань&nbsp;грамотным!» — образовательному сайту по&nbsp;русскому языку.
                    Добавьте этот контакт к&nbsp;себе, чтобы продолжиить совместную работу здесь.
                </p>
                <AffiliateContact
                    avatar={affiliateUserData.avatar}
                    firstName={affiliateUserData.firstName}
                    lastName={affiliateUserData.lastName}
                    loading={request.status === "REQUEST"}
                    middleName={affiliateUserData.middleName}
                    onConfirmClick={handleConfirmClick}
                    onDeclineClick={handleDeclineClick}
                />
                <p>* Принимая или отклоняя контакт, Вы также сохраняете данные аккаунта и активируете его.</p>
                {request.status === "ERROR" && (
                    <div style={{ marginTop: "40px" }}>
                        <p style={{ color: errorColors.main }}>
                            Ошибка :( Проверьте свои данные и&nbsp;попробуйте приянть/отклонить контакт ещё раз.
                        </p>
                        <Button color="primary" onClick={setPreviousStep} size="large" variant="contained">
                            &larr; Проверить свои данные
                        </Button>
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            <h3 style={h3Style}>{maxStepsCount > 1 ? "1." : ""} Проверьте свои данные</h3>
            <ProfileForm
                avatarUrl={userData?.avatar ?? ""}
                data={profileData}
                onAvatarDelete={handleAvatarDelete}
                onAvatarSubmit={handleAvatarSubmit}
                onChangeDataItem={handleChangeDataItem}
                onSubmit={step === maxStepsCount ? handleSubmit : setNextStep}
                showAvatarLoader={false}
                showClassField={showClassField}
                showDeleteButton={newSelectedAvatarFile !== undefined}
                showError={maxStepsCount === 1 && request.status === "ERROR"}
                showLoader={request.status === "REQUEST"}
                showNewSelectedAvatar={true}
                showSuccess={request.status === "SUCCESS"}
                submitButtonTitle={maxStepsCount > 1 ? "Далее" : "Сохранить и активировать"}
            />
        </>
    );
};
