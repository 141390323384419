import { atomFamily, selectorFamily } from "recoil";

import { LRS } from "../../../../constants/general";
import { sendRequest } from "../../../../core/api/request";
import type { UserMatchingDataType } from "../types";
import { requestUserData } from "./user";

const requestUserMatchingData = async (
  userId: number
): Promise<UserMatchingDataType> => {
  const userData = userId ? await requestUserData(userId) : null;

  const resultsData = await sendRequest({
    method: "GET",
    url: userId === 0 ? `${LRS.endpoint}results/matching/` : `${LRS.endpoint}results/matching/${userId}/`,
  })
    .then(({ cursor, data: successData }) => ({
      cursor,
      response: {
        analytics: successData,
        user: userData?.response ?? null,
      },
      status: "SUCCESS",
    }))
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (resultsData.status === "ERROR") {
    throw resultsData;
  }
  return resultsData;
};

const userMatchingDataRequestId = atomFamily({
  key: "userMatchingDataRequestId",
  default: 0,
});

const userMatchingData = atomFamily<UserMatchingDataType | null, number>({
  key: "userMatchingData",
  default: null,
});

export const userMatchingDataQuery = selectorFamily<UserMatchingDataType, number>(
  {
    key: "userMatchingDataQuery",
    get: (userId) => async ({ get }) => {
      get(userMatchingDataRequestId(userId)); // Add request ID as a dependency
      const newUserMatchingData = get(userMatchingData(userId));
      if (newUserMatchingData !== null) {
        return newUserMatchingData;
      }
      return await requestUserMatchingData(userId);
    },
    set: (userId) => ({ set }, newValue) => {
      if (newValue === null) {
        set(userMatchingDataRequestId(userId), (requestId) => requestId + 1);
      }
      set(userMatchingData(userId), newValue);
    },
    cachePolicy_UNSTABLE: { eviction: "most-recent" },
  }
);
