import type { FC, PropsWithChildren, ReactElement } from "react";
import React from "react";

import { Footer } from "../footer";
import { Header } from "../header";

import "./styles.scss";

type Props = {
    isAuthorized: boolean;
};

export const MainLayout: FC<Props> = (props: PropsWithChildren<Props>) => {
    const element = props.children as ReactElement | undefined;

    return (
        <div className="main-layout">
            <Header isAuthorized={props.isAuthorized} />
            <div className="main-layout__content">{element ? <element.type {...element?.props} /> : null}</div>
            <Footer />
        </div>
    );
};
