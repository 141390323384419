import { atom, selector } from "recoil";
import { sendRequest } from "../../../../core/api/request";

type WeeklyRatingData = {
  response: PersonType[];
  status: string;
};

type PersonType = {
  avatar: string;
  avatarMini: string;
  firstName: string | null;
  id: number;
  lastName: string | null;
  middleName: string | null;
  totalScore: number;
};

const weeklyRatingDataAtom = atom<WeeklyRatingData | null>({
  key: "weeklyRatingDataAtom",
  default: null,
});

export const weeklyRatingDataQuery = selector<WeeklyRatingData>({
  key: "weeklyRatingDataQuery",
  get: async ({ get }) => {
    const newWeeklyRatingData = get(weeklyRatingDataAtom);
    if (newWeeklyRatingData != null) {
      return newWeeklyRatingData;
    }
    const data = await sendRequest({
      method: "GET",
      url: "/ratings/weekly/",
    })
      .then(({ data: successData }) => ({
        response: successData,
        status: "SUCCESS",
      }))
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    if (data.status === "ERROR") {
      return data;
    }
    return data;
  },
  set: ({ set }, newValue) => set(weeklyRatingDataAtom, newValue),
});
