import { useCallback } from "react";
import type { SetterOrUpdater } from "recoil";
import { useSetRecoilState } from "recoil";
import { sendRequest } from "../core/api/request";
import type { RequestMethod } from "../core/api/types";
import {
  authorizationToken,
  logInFormRequest,
  passwordFormRequest,
  payFormRequest,
  recoverFormRequest,
  signUpFormRequest,
} from "../state";
import type { BodyData, Form } from "../types/main.d";

export const useFormRequest = ({
  method = "POST",
  setFormRequest,
  url,
}: {
  method?: RequestMethod;
  setFormRequest: SetterOrUpdater<Form.Request>;
  url: string;
}) => {
  const setAuthorizationToken = useSetRecoilState(authorizationToken);
  return useCallback(
    (bodyData: BodyData) => {
      setFormRequest({
        status: "REQUEST",
      });
      return sendRequest({
        bodyData,
        method,
        setAuthorizationToken,
        url,
      })
        .then(({ data: successData }) => {
          setFormRequest({
            response: successData,
            status: "SUCCESS",
          });
          return Promise.resolve(successData);
        })
        .catch((errorData) => {
          setFormRequest({
            response: errorData,
            status: "ERROR",
          });
          return Promise.reject(errorData);
        });
    },
    [method, setAuthorizationToken, setFormRequest, url]
  );
};

export const useSignUpFormSubmit = () => {
  const setFormRequest = useSetRecoilState(signUpFormRequest);
  return useFormRequest({
    setFormRequest,
    url: "/auth/signup/",
  });
};

export const useLogInFormSubmit = () => {
  const setFormRequest = useSetRecoilState(logInFormRequest);
  return useFormRequest({
    setFormRequest,
    url: "/auth/login/",
  });
};

export const useRecoverFormSubmit = () => {
  const setFormRequest = useSetRecoilState(recoverFormRequest);
  return useFormRequest({
    setFormRequest,
    url: "/auth/password/",
  });
};

export const usePasswordFormSubmit = () => {
  const setFormRequest = useSetRecoilState(passwordFormRequest);
  return useFormRequest({
    setFormRequest,
    url: "/profile/password/",
  });
};

export const usePayFormSubmit = () => {
  const setFormRequest = useSetRecoilState(payFormRequest);
  return useFormRequest({
    setFormRequest,
    url: "/payment/pay/",
  });
};
