import type { FC } from "react";
import React from "react";

import { ButtonLink } from "../../../components/basic/button_link";
import banner from "../../../images/banner.png";

import "./styles.scss";

type Props = {
  isAuthorized: boolean;
};

export const MainPageView: FC<any> = (props: Props) => (
  <div className="main-page">
    <div className="main-page__banner">
      <div className="main-page__banner-container">
        <div className="main-page__banner-text">
          <h1>Стань грамотным!</h1>
          <p>
            Стать грамотным может каждый!
            <br />
            Поставьте цель и тренируйтесь на нашей платформе. Здесь коротко,
            логично и понятно изложена вся теория. Разнообразно и интересно
            выстроены упражнения.
            <br />
            Не останавливайтесь &mdash; и вы придёте к успеху!
          </p>
          <div>
            <ButtonLink
              href={props.isAuthorized ? "/education/" : "/signup/"}
              size="large"
            >
              Начать обучение
            </ButtonLink>
          </div>
        </div>
        <div className="main-page__banner-image">
          <img src={banner} alt="" />
        </div>
      </div>
    </div>
  </div>
);
