import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";

import { useFormRequest } from "../../../../actions/data";
import type { BodyData, Form } from "../../../../types/main.d";

export const inviteFormRequest = atom<Form.Request>({
  key: "inviteFormRequest",
  default: {
    status: "DEFAULT",
  },
});

export const useInviteFormSubmit = (onSuccess: (successData: any) => void) => {
  const setFormRequest = useSetRecoilState(inviteFormRequest);
  const inviteFormSubmit = useFormRequest({
    setFormRequest,
    url: "/users/invite/",
  });
  return useCallback(
    (bodyData: BodyData) => inviteFormSubmit(bodyData).then(onSuccess),
    [inviteFormSubmit, onSuccess]
  );
};
