import type { FC } from "react";
import React, { memo, useCallback } from "react";
import type { SetterOrUpdater } from "recoil";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";

import { TableCell, TableRow } from "@material-ui/core";
import { Class as ClassIcon, Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";

import { useOnSubmitClick } from "../../../../../hooks/on_submit_click";
import { useResetFormStatus } from "../../../../../hooks/reset_form_status";
import { ButtonLink } from "../../../../basic/button_link";
import { LoadingButton } from "../../../../basic/loading_button/loading_button";
import { classDeleteFormRequest, useClassDeleteFormSubmit } from "../../state/class_delete";
import type { ClassItemType, ClassesListType } from "../../types";

import "./class_item_row.scss";

type Props = {
    item: ClassItemType;
    setClassesData: SetterOrUpdater<ClassesListType>;
};

const ClassItemRowFC: FC<Props> = ({ item, setClassesData }: Props) => {
    const classDeleteFormSubmit = useClassDeleteFormSubmit(item.id, setClassesData);
    const recoilState = classDeleteFormRequest(item.id);

    const [request, setRequest] = useRecoilState(recoilState);
    const handleSubmit = useOnSubmitClick({}, request.status, undefined, classDeleteFormSubmit);
    useResetFormStatus(setRequest);

    const handleDelete = useCallback(() => {
        if (window.confirm(`Удалить «${item.totalTitle}»?`)) {
            handleSubmit();
        }
    }, [handleSubmit, item.totalTitle]);

    const loading = request.status === "REQUEST";

    return (
        <TableRow className={loading ? "class-item-row class-item-row--show-buttons" : "class-item-row"}>
            <TableCell className="class-item-row__icon">
                <ClassIcon />
            </TableCell>
            <TableCell>
                <Link to={`/classes/show/${item.id}/`} className="class-item-row__title">
                    {item.totalTitle}
                </Link>
            </TableCell>
            <TableCell>
                <span className="class-item-row__count">{item.pupilsCount}</span>
            </TableCell>
            <TableCell className="class-item-row__buttons-cell">
                <div className="class-item-row__buttons">
                    <ButtonLink href={`/classes/edit/${item.id}/`} size="small">
                        <>
                            <EditIcon /> <span className="class-item-row__button-title">Редактировать</span>
                        </>
                    </ButtonLink>
                    <LoadingButton loading={loading} onClick={handleDelete} size="small" variant="outlined">
                        <>
                            <DeleteIcon /> <span className="class-item-row__button-title">Удалить</span>
                        </>
                    </LoadingButton>
                </div>
            </TableCell>
        </TableRow>
    );
};

export const ClassItemRow = memo(ClassItemRowFC);
