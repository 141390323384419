import { useCallback } from "react";
import type { SetterOrUpdater } from "recoil";
import { atomFamily, useSetRecoilState } from "recoil";

import { useFormRequest } from "../../../../actions/data";
import type { BodyData, Form } from "../../../../types/main.d";
import type { ClassesListType } from "../types";

export const classDeleteFormRequest = atomFamily<Form.Request, number>({
  key: "classDeleteFormRequest",
  default: {
    status: "DEFAULT",
  },
});

export const useClassDeleteFormSubmit = (
  classId: number,
  setClassesData: SetterOrUpdater<ClassesListType>
) => {
  const setFormRequest = useSetRecoilState(classDeleteFormRequest(classId));
  const classDeleteFormSubmit = useFormRequest({
    method: "DELETE",
    setFormRequest,
    url: `/classes/item/${classId}/`,
  });
  return useCallback(
    (bodyData: BodyData) =>
      classDeleteFormSubmit({ ...bodyData, id: classId }).then((successData) =>
        setClassesData((currVal) => ({
          ...currVal,
          response: currVal.response.filter((v) => v.id !== classId),
        }))
      ),
    [classDeleteFormSubmit, setClassesData, classId]
  );
};
