import { atomFamily, selectorFamily } from "recoil";

import { sendRequest } from "../../../../core/api/request";
import type { ContactsListType, ContactsParams } from "../types";

export const requestContactsData = async ({ type }: ContactsParams) => {
  const data = await sendRequest({
    bodyData: {
      apiVersion: 2,
      ...(type === "CONTACTED_PUPILS"
        ? {
            relationsType: "CONTACTED",
            status: [6],
          }
        : {
            relationsType: type,
          }),
    },
    method: "POST",
    url: "/users/list/",
  })
    .then(({ cursor, data: successData }) => ({
      cursor,
      response: successData,
      status: "SUCCESS",
    }))
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (data.status === "ERROR") {
    throw data;
  }
  return data;
};

const contactsDataRequestId = atomFamily({
  key: "contactsDataRequestId",
  default: 0,
});

const contactsData = atomFamily<ContactsListType | null, ContactsParams>({
  key: "contactsData",
  default: null,
});

export const contactsDataQuery = selectorFamily<
  ContactsListType,
  ContactsParams
>({
  key: "contactsDataQuery",
  get: (params) => async ({ get }) => {
    get(contactsDataRequestId(params)); // Add request ID as a dependency
    const newContactsData = get(contactsData(params));
    if (newContactsData !== null) {
      return newContactsData;
    }
    return await requestContactsData(params);
  },
  set: (params) => ({ set }, newValue) => {
    if (newValue === null) {
      set(contactsDataRequestId(params), (requestId) => requestId + 1);
    }
    set(contactsData(params), newValue);
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});
