import { useCallback } from "react";

import type { FormFieldValue } from "../types/main.d";

export const useOnChangeDataItem = (setFormData: any) =>
  useCallback(
    (fieldName: string, value: any) =>
      setFormData((oldFormData: { [index: string]: FormFieldValue<any> }) => ({
        ...oldFormData,
        [fieldName]: {
          ...oldFormData[fieldName],
          error: oldFormData[fieldName].errorChecker
            ? (oldFormData[fieldName] as any).errorChecker(value)
            : oldFormData[fieldName].error,
          value,
        },
      })),
    [setFormData]
  );
