import type { FC } from "react";
import React from "react";
import { PaymentsSuspense } from "./components/payments_suspense/payments_suspense";

export const PaymentsPage: FC = () => (
  <div className="payments-page">
    <h1>Список оплат</h1>
    <PaymentsSuspense />
  </div>
);
