import { atomFamily, selectorFamily } from "recoil";

import { LRS } from "../../../../constants/general";
import { sendRequest } from "../../../../core/api/request";
import { requestCourseData } from "../../../../state/selectors";
import type { UserResultsDataType } from "../types";
import { requestUserData } from "./user";

const requestUserResultsData = async (
  userId: number
): Promise<UserResultsDataType> => {
  const userData = await requestUserData(userId);
  const courseData = await requestCourseData(true);

  const resultsData = await sendRequest({
    bodyData: {
      topicsIds:
        courseData.response?.sections.reduce(
          (acc, v) => [...acc, ...v.topics.map((t) => t.id)],
          [] as number[]
        ) ?? [],
      usersIds: [userId],
    },
    method: "POST",
    url: `${LRS.endpoint}results/users/detailed/`,
  })
    .then(({ cursor, data: successData }) => ({
      cursor,
      response: {
        course: courseData.response,
        results: successData,
        user: userData.response,
      },
      status: "SUCCESS",
    }))
    .catch((errorData) => ({
      cursor: null,
      response: errorData,
      status: "ERROR",
    }));
  if (resultsData.status === "ERROR") {
    throw resultsData;
  }
  return resultsData;
};

const userResultsDataRequestId = atomFamily({
  key: "userResultsDataRequestId",
  default: 0,
});

const userResultsData = atomFamily<UserResultsDataType | null, number>({
  key: "userResultsData",
  default: null,
});

export const userResultsDataQuery = selectorFamily<UserResultsDataType, number>(
  {
    key: "userResultsDataQuery",
    get: (userId) => async ({ get }) => {
      get(userResultsDataRequestId(userId)); // Add request ID as a dependency
      const newUserResultsData = get(userResultsData(userId));
      if (newUserResultsData !== null) {
        return newUserResultsData;
      }
      return await requestUserResultsData(userId);
    },
    set: (userId) => ({ set }, newValue) => {
      if (newValue === null) {
        set(userResultsDataRequestId(userId), (requestId) => requestId + 1);
      }
      set(userResultsData(userId), newValue);
    },
    cachePolicy_UNSTABLE: { eviction: "most-recent" },
  }
);
