import { atom } from "recoil";
import { useSetRecoilState } from "recoil";
import { useFormRequest } from "../../../../actions/data";
import type { Form } from "../../../../types/main.d";

export const profileFormRequest = atom<Form.Request>({
  key: "profileFormRequest",
  default: {
    status: "DEFAULT",
  },
});

export const useProfileFormSubmit = () => {
  const setFormRequest = useSetRecoilState(profileFormRequest);
  return useFormRequest({
    setFormRequest,
    url: "/profile/data/",
  });
};
