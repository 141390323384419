import type { FC } from "react";
import React, { useCallback, useMemo } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";

type Props = {
  payUrl: string;
  showPayButton: boolean;
  text: string;
  title: string;
  url: string;
} & RouteComponentProps;

const SectionRowView: FC<any> = (props: Props) => {
  const handlePayClick = useCallback(() => {
    props.history.push(props.payUrl);
  }, [props.history, props.payUrl]);

  const payButton = useMemo(
    () =>
      props.showPayButton ? (
        <Button
          className="course__section-pay"
          color="primary"
          onClick={handlePayClick}
          size="large"
          variant="contained"
        >
          Оформить подписку
        </Button>
      ) : null,
    [handlePayClick, props.showPayButton]
  );

  return (
    <div className="course__section">
      <div className="course__section-container">
        {payButton}
        <h2>
          {props.showPayButton ? (
            props.title
          ) : (
            <Link to={props.url}>{props.title}</Link>
          )}
        </h2>
        <div dangerouslySetInnerHTML={{ __html: props.text }} />
      </div>
    </div>
  );
};

export default withRouter(SectionRowView);
