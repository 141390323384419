import { useLayoutEffect } from "react";

declare let AmCharts: any;

const LINE_COLOR = ["#a2ce1a", "#ff3236"];

type Props = {
    data: any;
    id: string;
    isMakeChart: boolean;
    series: Array<{
        balloonText: string;
        title: string;
        valueField: string;
    }>;
    title?: string;
};

export const useMatchingChart = ({ data, id, isMakeChart, series, title }: Props) => {
    useLayoutEffect(() => {
        if (isMakeChart) {
            AmCharts.makeChart(id, {
                categoryAxis: {
                    gridPosition: "start",
                    axisAlpha: 0,
                    tickLength: 0,
                },
                categoryField: "label",
                chartCursor: {
                    oneBalloonOnly: true,
                    enabled: true,
                    cursorAlpha: 0.1,
                    cursorColor: "#ff8713",
                    fullWidth: true,
                    valueBalloonsEnabled: false,
                    zoomable: false,
                },
                color: "#212731",
                dataProvider: data,
                fontFamily: "Gilroy, -apple-system, sans-serif",
                fontSize: 14,
                graphs: series.map((s, i) => ({
                    valueAxis: "axis",
                    balloonText: `${s.balloonText}<br><span style='font-size:20px;'>[[value]]</span>`,
                    bullet: "round",
                    dashLengthField: "dashLengthLine",
                    lineThickness: 3,
                    bulletSize: 7,
                    bulletBorderAlpha: 1,
                    bulletColor: "#FFFFFF",
                    useLineColorForBulletBorder: true,
                    bulletBorderThickness: 3,
                    fillAlphas: 0,
                    lineAlpha: 1,
                    title: s.title,
                    valueField: s.valueField,
                    lineColor: LINE_COLOR[i],
                })),
                legend: {
                    useGraphSettings: true,
                },
                startDuration: 0,
                theme: "none",
                titles: title
                    ? [
                          {
                              size: 20,
                              text: title,
                          },
                      ]
                    : [],
                type: "serial",
                valueAxes: [
                    {
                        axisAlpha: 1,
                        id: "axis",
                        minimum: 0,
                        position: "left",
                    },
                ],
            });
        }
    }, [data, id, isMakeChart, series, title]);
};
