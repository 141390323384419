import { selectorFamily } from "recoil";
import { sendRequest } from "../../../../core/api/request";

export const activateUserQuery = selectorFamily({
  key: "activateUserQuery",
  get: (props: { code: string; login: string }) => async () => {
    const data = await sendRequest({
      bodyData: props,
      method: "POST",
      url: "/auth/activateuser/",
    })
      .then(({ data: successData }) => ({
        response: successData,
        status: "SUCCESS",
      }))
      .catch((errorData) => ({
        response: errorData,
        status: "ERROR",
      }));
    if (data.status === "ERROR") {
      throw data;
    }
    return data;
  },
});
