import type { FC } from "react";
import React from "react";

import { Contacts } from "../../../../common/contacts/contacts";
import type { ChildrenProps, ContactsParams } from "../../../../common/contacts/types";

const params: ContactsParams = { type: "REQUESTED" };

export const ContactsRequested: FC = () => (
    <Contacts params={params}>
        {({ contacts }: ChildrenProps) =>
            contacts.length ? (
                <>
                    <h1>Заявки</h1>
                    {contacts}
                </>
            ) : null
        }
    </Contacts>
);
