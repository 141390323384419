import type { FC } from "react";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useRecoilRefresher_UNSTABLE, useRecoilState } from "recoil";

import { contactsDataQuery } from "../../state/contacts";
import { ContactCheckbox } from "../contact_checkbox/contact_checkbox";
import { ContactItem } from "../contact_item/contact_item";
import type { ContactsContainerProps } from "../../types";

const ContactsContainerFC: FC<ContactsContainerProps> = ({
    children,
    onChange,
    params,
    values,
}: ContactsContainerProps) => {
    const recoilState = contactsDataQuery(params);
    const [contactsData, setContactsData] = useRecoilState(recoilState);
    const refreshContactsData = useRecoilRefresher_UNSTABLE(recoilState);
    useEffect(() => () => setContactsData(null as any), [setContactsData]);

    const handleChange = useCallback(
        (id: number, isChecked: boolean) => {
            onChange?.(isChecked ? [...(values ?? []), id] : (values ?? []).filter((v) => v !== id));
        },
        [onChange, values]
    );

    const contacts = useMemo(
        () =>
            contactsData.response?.map((contact) =>
                onChange ? (
                    <ContactCheckbox
                        checked={values && values.indexOf(contact.id) !== -1}
                        contact={contact}
                        key={`contact_${contact.id}`}
                        name={`contact_${contact.id}`}
                        onChange={handleChange}
                    />
                ) : (
                    <ContactItem contact={contact} key={`contact_${contact.id}`} setContactsData={setContactsData} />
                )
            ),
        [contactsData.response, handleChange, onChange, setContactsData, values]
    );

    return children({
        contacts,
        refreshContactsData,
    });
};

export const ContactsContainer = memo(ContactsContainerFC);
