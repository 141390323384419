import type { FC } from "react";
import React, { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";

import ErrorBoundary from "../../../../basic/error_boundary";
import { ClassesContainer } from "../classes_container/classes_container";

export const ClassesSuspense: FC = () => (
  <ErrorBoundary>
    <Suspense fallback={<CircularProgress />}>
      <ClassesContainer />
    </Suspense>
  </ErrorBoundary>
);
