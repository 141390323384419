import React, { useCallback } from "react";
import type { FC } from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";

import { errorColors, successColors } from "../../../constants/general";
import type { PasswordForm } from "../../../types/main.d";
import "./styles.scss";

const errorColorStyle = { color: errorColors.main };
const successColorStyle = { color: successColors.main };

type Props = {
  data: PasswordForm.State;
  errorMessage?: string;
  newPasswordRef: any;
  newPasswordRepeatRef: any;
  onChangeDataItem: (fieldName: PasswordForm.FieldName, value: any) => void;
  onSubmit: () => void;
  passwordRef: any;
  showError: boolean;
  showLoader: boolean;
  showSuccess: boolean;
};

const PasswordFormView: FC<Props> = ({
  data,
  errorMessage,
  newPasswordRef,
  newPasswordRepeatRef,
  onChangeDataItem,
  onSubmit,
  passwordRef,
  showError,
  showLoader,
  showSuccess,
}: Props) => {
  const handleChangeValue = useCallback(
    (fieldName: PasswordForm.FieldName, eventFieldName = "value") => (
      event: any
    ) => onChangeDataItem(fieldName, event.target[eventFieldName]),
    [onChangeDataItem]
  );
  const handleSubmitForm = useCallback((event?: any) => onSubmit(), [onSubmit]);
  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        handleSubmitForm();
      }
    },
    [handleSubmitForm]
  );

  return (
    <div className="password-form">
      <h1>Изменение пароля</h1>
      <div className="password-form__row">
        <TextField
          className="password-form__field"
          color="secondary"
          disabled={showLoader}
          error={data.password.error}
          helperText={data.password.helperText}
          id="password"
          inputRef={passwordRef}
          label="Старый пароль"
          onChange={handleChangeValue("password")}
          onKeyPress={handleKeyPress}
          type="password"
          variant="outlined"
          value={data.password.value}
        />
      </div>
      <div className="password-form__row">
        <TextField
          className="password-form__field"
          color="secondary"
          disabled={showLoader}
          error={data.newPassword.error}
          helperText={data.newPassword.helperText}
          id="newPassword"
          inputRef={newPasswordRef}
          label="Новый пароль"
          onChange={handleChangeValue("newPassword")}
          onKeyPress={handleKeyPress}
          type="password"
          variant="outlined"
          value={data.newPassword.value}
        />
      </div>
      <div className="password-form__row">
        <TextField
          className="password-form__field"
          color="secondary"
          disabled={showLoader}
          error={data.newPasswordRepeat.error}
          helperText={data.newPasswordRepeat.helperText}
          id="newPasswordRepeat"
          inputRef={newPasswordRepeatRef}
          label="Повтроите новый пароль"
          onChange={handleChangeValue("newPasswordRepeat")}
          onKeyPress={handleKeyPress}
          type="password"
          variant="outlined"
          value={data.newPasswordRepeat.value}
        />
      </div>
      <div className="password-form__row password-form__row--flex">
        <div className="password-form__submit">
          <Button
            color="primary"
            disabled={showLoader}
            onClick={handleSubmitForm}
            size="large"
            variant="contained"
          >
            Сохранить
          </Button>
          {showLoader && (
            <CircularProgress
              size={24}
              className="password-form__submit-progress"
            />
          )}
        </div>
      </div>
      {showError && (
        <div className="password-form__row" style={errorColorStyle}>
          {errorMessage || (
            <>
              Ошибка :( Проверьте введённые данные и&nbsp;попробуйте изменить
              пароль ещё раз.
            </>
          )}
        </div>
      )}
      {showSuccess && (
        <div className="password-form__row" style={successColorStyle}>
          Пароль изменён :)
        </div>
      )}
    </div>
  );
};

export default PasswordFormView;
